"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaggageSpanProcessorFactory = void 0;
const baggage_span_processor_1 = require("../spanprocessor/baggage-span-processor");
const span_processor_factory_1 = require("./span-processor-factory");
/**
 * BaggageSpanProcessorFactory - a factory for creating a {@link BaggageSpanProcessor}
 */
class BaggageSpanProcessorFactory extends span_processor_factory_1.SpanProcessorFactory {
  wrappedSpanProcessorFactory;
  appPrefix;
  constructor(wrappedSpanProcessorFactory, appPrefix) {
    super();
    this.wrappedSpanProcessorFactory = wrappedSpanProcessorFactory;
    this.appPrefix = appPrefix;
  }
  create(spanExporter) {
    const wrappedSpanProcessor = this.wrappedSpanProcessorFactory.create(spanExporter);
    return new baggage_span_processor_1.BaggageSpanProcessor(wrappedSpanProcessor, this.appPrefix);
  }
}
exports.BaggageSpanProcessorFactory = BaggageSpanProcessorFactory;
