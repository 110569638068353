"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthenticationDetailStatusEnum = exports.AuthenticationDetailAuthenticationMethodEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var AuthenticationDetailAuthenticationMethodEnum;
(function (AuthenticationDetailAuthenticationMethodEnum) {
  AuthenticationDetailAuthenticationMethodEnum["MICR"] = "MICR";
  AuthenticationDetailAuthenticationMethodEnum["YODLEE"] = "YODLEE";
  AuthenticationDetailAuthenticationMethodEnum["CNVR"] = "CNVR";
  AuthenticationDetailAuthenticationMethodEnum["MAIL_VOIDED_CHECK"] = "MAIL_VOIDED_CHECK";
  AuthenticationDetailAuthenticationMethodEnum["WIRE_TEN_DAY_HOLD"] = "WIRE_TEN_DAY_HOLD";
})(AuthenticationDetailAuthenticationMethodEnum = exports.AuthenticationDetailAuthenticationMethodEnum || (exports.AuthenticationDetailAuthenticationMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AuthenticationDetailStatusEnum;
(function (AuthenticationDetailStatusEnum) {
  AuthenticationDetailStatusEnum["AUTHENTICATED"] = "AUTHENTICATED";
  AuthenticationDetailStatusEnum["NOT_AUTHENTICATED"] = "NOT_AUTHENTICATED";
  AuthenticationDetailStatusEnum["LOCKED"] = "LOCKED";
  AuthenticationDetailStatusEnum["FROZEN"] = "FROZEN";
  AuthenticationDetailStatusEnum["AWAITING_MICRO_DEPOSIT_TRANSMISSION"] = "AWAITING_MICRO_DEPOSIT_TRANSMISSION";
  AuthenticationDetailStatusEnum["AWAITING_MICRO_DEPOSIT_TRANSMISSION_CONFIRMATION"] = "AWAITING_MICRO_DEPOSIT_TRANSMISSION_CONFIRMATION";
})(AuthenticationDetailStatusEnum = exports.AuthenticationDetailStatusEnum || (exports.AuthenticationDetailStatusEnum = {}));
