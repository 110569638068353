import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdobeAnalyticsService } from '@app/core/services';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { Account, FundsAvailable, JsonContent } from '@app/etfs-equities/models';
import {
  createFundsAvailablePanelToggleAction,
  createRefreshFundsAvailableAction,
  selectAccountHasPatternDayTraderRestriction,
  selectAccountIsMargin,
  selectFundsAvailable,
  selectFundsAvailableIsLoading,
  selectFundsAvailableLoadingFailed,
  selectFundsAvailablePanelIsOpen,
  selectSelectedAccount,
  TayneState,
} from '@app/etfs-equities/store';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { TimestampSize } from '@vanguard/trade-ui-components-lib-ng-17';
import { combineLatest, map, Observable, Subject, take, takeUntil, tap, withLatestFrom } from 'rxjs';

@Component({
  selector: 'twe-funds-available',
  templateUrl: './funds-available.component.html',
  styleUrls: ['./funds-available.component.scss'],
})
export class FundsAvailableComponent implements OnInit, OnDestroy {
  public static componentCount = 0;

  id: string;

  content: JsonContent = content;

  isViewingConfirm = true;

  protected readonly timestampSizeEnum = TimestampSize;

  // Store state...

  fundsAvailable$: Observable<FundsAvailable>;

  selectedAccount$: Observable<Account.Account>;

  accountIsMargin$: Observable<boolean>;

  panelIsOpen$: Observable<boolean>;

  isLoading$: Observable<boolean>;

  loadingFailed$: Observable<boolean>;

  accountIsPatternDayTrader$: Observable<boolean>;

  showShowDetailsBtn$: Observable<boolean>;

  private readonly unsubscribe$ = new Subject<void>();

  initialPanelState: boolean;

  constructor(
    private readonly store: Store<TayneState>,
    private readonly router: Router,
    public readonly adobeService: AdobeAnalyticsService
  ) {}

  ngOnInit() {
    this.fundsAvailable$ = this.store.pipe(select(selectFundsAvailable));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.accountIsMargin$ = this.store.pipe(select(selectAccountIsMargin));
    this.panelIsOpen$ = this.store.pipe(select(selectFundsAvailablePanelIsOpen));
    this.isLoading$ = this.store.pipe(select(selectFundsAvailableIsLoading));
    this.loadingFailed$ = this.store.pipe(select(selectFundsAvailableLoadingFailed));
    this.accountIsPatternDayTrader$ = this.store.pipe(select(selectAccountHasPatternDayTraderRestriction));
    this.isViewingConfirm = this.router.isActive(`${CONSTANTS.TRADE_PATH}/confirmation`, {
      paths: 'exact',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
    FundsAvailableComponent.componentCount++;
    this.id = `funds-available-id-${FundsAvailableComponent.componentCount}`;
    this.refreshBalancesOnLoad();
    this.watchForDetailsButtonVisibility();
    this.panelIsOpen$.pipe(take(1)).subscribe((isOpen) => (this.initialPanelState = isOpen));
  }

  refreshBalancesOnLoad() {
    this.selectedAccount$
      .pipe(
        take(1),
        withLatestFrom(this.fundsAvailable$, this.isLoading$),
        tap(([selectedAccount, funds, isLoading]) => {
          if (selectedAccount && !funds && !isLoading) {
            this.store.dispatch(createRefreshFundsAvailableAction());
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  refresh() {
    this.adobeService.sendAdobeTrackingOnClick('Balances:Refresh', 'icon');
    this.store.dispatch(createRefreshFundsAvailableAction());
  }

  handlePanelToggle(event: string) {
    const isOpen = event === 'open';
    const actionType = isOpen ? 'Show' : 'Hide';
    this.adobeService.sendAdobeTrackingOnClick(`${actionType} Details`, 'link');
    this.store.dispatch(createFundsAvailablePanelToggleAction(isOpen));
  }

  // A private method responsible for determining "Show Details" button visibility based on certain conditions
  private watchForDetailsButtonVisibility() {
    this.showShowDetailsBtn$ = combineLatest([
      this.accountIsMargin$,
      this.fundsAvailable$,
      this.accountIsPatternDayTrader$,
    ]).pipe(
      map(([accountIsMargin, fundsAvailable, isDayTrader]) => {
        // Show the button if:
        // - funds available data is available; AND
        //   - account is not margin; OR
        //   - account has day trader restrictions; OR
        //   - any of the following properties in `fundsAvailable` are non-zero:
        //     (`totalCreditsAndDebits`, `openLimitOrderValue`, `openBuyMarketableOrderValue`)
        return (
          !!fundsAvailable &&
          (!accountIsMargin ||
            isDayTrader ||
            fundsAvailable.totalCreditsAndDebits !== 0 ||
            fundsAvailable.openLimitOrderValue !== 0 ||
            fundsAvailable.openBuyMarketableOrderValue !== 0)
        );
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
