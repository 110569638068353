"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeOrderTransactionSourceSourceTypeEnum = exports.ChangeOrderTransactionSourceCostBasisMethodEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ChangeOrderTransactionSourceCostBasisMethodEnum;
(function (ChangeOrderTransactionSourceCostBasisMethodEnum) {
  ChangeOrderTransactionSourceCostBasisMethodEnum["FIRST_IN_FIRST_OUT"] = "FIRST_IN_FIRST_OUT";
  ChangeOrderTransactionSourceCostBasisMethodEnum["SPECIFIC_IDENTIFICATION"] = "SPECIFIC_IDENTIFICATION";
  ChangeOrderTransactionSourceCostBasisMethodEnum["HIGHEST_IN_FIRST_OUT"] = "HIGHEST_IN_FIRST_OUT";
  ChangeOrderTransactionSourceCostBasisMethodEnum["MINIMUM_TAX"] = "MINIMUM_TAX";
  ChangeOrderTransactionSourceCostBasisMethodEnum["AVERAGE_COST"] = "AVERAGE_COST";
  ChangeOrderTransactionSourceCostBasisMethodEnum["MAXIMUM_GAIN"] = "MAXIMUM_GAIN";
})(ChangeOrderTransactionSourceCostBasisMethodEnum = exports.ChangeOrderTransactionSourceCostBasisMethodEnum || (exports.ChangeOrderTransactionSourceCostBasisMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ChangeOrderTransactionSourceSourceTypeEnum;
(function (ChangeOrderTransactionSourceSourceTypeEnum) {
  ChangeOrderTransactionSourceSourceTypeEnum["BROKERAGE_HOLDING"] = "BROKERAGE_HOLDING";
  ChangeOrderTransactionSourceSourceTypeEnum["SWEEP_FUND"] = "SWEEP_FUND";
})(ChangeOrderTransactionSourceSourceTypeEnum = exports.ChangeOrderTransactionSourceSourceTypeEnum || (exports.ChangeOrderTransactionSourceSourceTypeEnum = {}));
