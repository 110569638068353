"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SamplingBypassSpanProcessorFactory = void 0;
const sampling_bypass_span_processor_1 = require("../spanprocessor/sampling-bypass-span-processor");
const span_processor_factory_1 = require("./span-processor-factory");
class SamplingBypassSpanProcessorFactory extends span_processor_factory_1.SpanProcessorFactory {
  wrappedSpanProcessorFactory;
  samplingBypassDecisionProvider;
  constructor(wrappedSpanProcessorFactory, samplingBypassDecisionProvider) {
    super();
    this.wrappedSpanProcessorFactory = wrappedSpanProcessorFactory;
    this.samplingBypassDecisionProvider = samplingBypassDecisionProvider;
  }
  create(spanExporter) {
    const wrappedSpanProcessor = this.wrappedSpanProcessorFactory.create(spanExporter);
    return new sampling_bypass_span_processor_1.SamplingBypassSpanProcessor(wrappedSpanProcessor, this.samplingBypassDecisionProvider);
  }
}
exports.SamplingBypassSpanProcessorFactory = SamplingBypassSpanProcessorFactory;
