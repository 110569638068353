"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CompositeSpanExporter = void 0;
/**
 * A span exporter that delegates to multiple span exporters.
 */
class CompositeSpanExporter {
  exporters;
  constructor(exporters) {
    this.exporters = exporters;
  }
  async export(spans, resultCallback) {
    for (const exporter of this.exporters) {
      await exporter.export(spans, resultCallback);
    }
  }
  async shutdown() {
    for (const exporter of this.exporters) {
      await exporter.shutdown();
    }
  }
}
exports.CompositeSpanExporter = CompositeSpanExporter;
