"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BusinessOriginBusinessWorkStream = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BusinessOriginBusinessWorkStream;
(function (BusinessOriginBusinessWorkStream) {
  BusinessOriginBusinessWorkStream["AUTOMATIC_INVESTMENT_PLAN"] = "AUTOMATIC_INVESTMENT_PLAN";
  BusinessOriginBusinessWorkStream["AUTOMATIC_INVESTMENT_PLAN_SETUP"] = "AUTOMATIC_INVESTMENT_PLAN_SETUP";
  BusinessOriginBusinessWorkStream["AUTOMATIC_WITHDRAWAL_PLAN"] = "AUTOMATIC_WITHDRAWAL_PLAN";
  BusinessOriginBusinessWorkStream["BROKERAGE_ORDER_CHANGE"] = "BROKERAGE_ORDER_CHANGE";
  BusinessOriginBusinessWorkStream["CMA_CASH_TRANSFER"] = "CMA_CASH_TRANSFER";
  BusinessOriginBusinessWorkStream["CORPORATE_TRUST"] = "CORPORATE_TRUST";
  BusinessOriginBusinessWorkStream["DAILY_FEES"] = "DAILY_FEES";
  BusinessOriginBusinessWorkStream["DIRECT_DEPOSIT_EXCEPTION"] = "DIRECT_DEPOSIT_EXCEPTION";
  BusinessOriginBusinessWorkStream["FINANCIAL_PLANNING_CASH_MOVEMENT"] = "FINANCIAL_PLANNING_CASH_MOVEMENT";
  BusinessOriginBusinessWorkStream["PORTFOLIO_CONSTRUCTION_ADVISOR"] = "PORTFOLIO_CONSTRUCTION_ADVISOR";
  BusinessOriginBusinessWorkStream["PORTFOLIO_CONSTRUCTION_CASH_MOVEMENT"] = "PORTFOLIO_CONSTRUCTION_CASH_MOVEMENT";
  BusinessOriginBusinessWorkStream["PORTFOLIO_CONSTRUCTION_DAILY"] = "PORTFOLIO_CONSTRUCTION_DAILY";
  BusinessOriginBusinessWorkStream["PORTFOLIO_CONSTRUCTION_NON_SETTLEMENT_FUND"] = "PORTFOLIO_CONSTRUCTION_NON_SETTLEMENT_FUND";
  BusinessOriginBusinessWorkStream["PORTFOLIO_CONSTRUCTION_NON_SPEND_FUND"] = "PORTFOLIO_CONSTRUCTION_NON_SPEND_FUND";
  BusinessOriginBusinessWorkStream["PORTFOLIO_CONSTRUCTION_NON_SWEEP_FUND"] = "PORTFOLIO_CONSTRUCTION_NON_SWEEP_FUND";
  BusinessOriginBusinessWorkStream["PORTFOLIO_CONSTRUCTION_QUARTERLY"] = "PORTFOLIO_CONSTRUCTION_QUARTERLY";
  BusinessOriginBusinessWorkStream["PORTFOLIO_CONSTRUCTION_SETTLEMENT_FUND"] = "PORTFOLIO_CONSTRUCTION_SETTLEMENT_FUND";
  BusinessOriginBusinessWorkStream["PORTFOLIO_CONSTRUCTION_SWEEP_FUND"] = "PORTFOLIO_CONSTRUCTION_SWEEP_FUND";
  BusinessOriginBusinessWorkStream["PRIVATE_EQUITY_FEE"] = "PRIVATE_EQUITY_FEE";
  BusinessOriginBusinessWorkStream["QUARTERLY_FEES"] = "QUARTERLY_FEES";
  BusinessOriginBusinessWorkStream["ROTH_CONVERSION"] = "ROTH_CONVERSION";
  BusinessOriginBusinessWorkStream["STANDARD_TRADING_OPERATIONAL_PROCEDURE"] = "STANDARD_TRADING_OPERATIONAL_PROCEDURE";
  BusinessOriginBusinessWorkStream["TAX_LOSS_HARVESTING"] = "TAX_LOSS_HARVESTING";
  BusinessOriginBusinessWorkStream["VANGUARD_PERSONALIZED_INDEXING"] = "VANGUARD_PERSONALIZED_INDEXING";
  BusinessOriginBusinessWorkStream["AUTO_INVEST_INTO_ETFS"] = "AUTO_INVEST_INTO_ETFS";
})(BusinessOriginBusinessWorkStream = exports.BusinessOriginBusinessWorkStream || (exports.BusinessOriginBusinessWorkStream = {}));
