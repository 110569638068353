"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpanNotifierSpanProcessor = void 0;
/**
 * SpanNotifierSpanProcessor supports span notification/mutation prior to span processing
 */
class SpanNotifierSpanProcessor {
  wrappedSpanProcessor;
  spanProcessorListener;
  constructor(wrappedSpanProcessor, spanProcessorListener) {
    this.wrappedSpanProcessor = wrappedSpanProcessor;
    this.spanProcessorListener = spanProcessorListener;
  }
  forceFlush() {
    return this.wrappedSpanProcessor.forceFlush();
  }
  onStart(span, parentContext) {
    const anySpan = span;
    anySpan.spanProcessorListenerStartup = Promise.resolve(this.spanProcessorListener(span)).then(() => this.wrappedSpanProcessor.onStart(span, parentContext));
  }
  onEnd(span) {
    this.wrappedSpanProcessor.onEnd(span);
  }
  shutdown() {
    return this.wrappedSpanProcessor.shutdown();
  }
}
exports.SpanNotifierSpanProcessor = SpanNotifierSpanProcessor;
