"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaxAddressStandardizedAddressStatusEnum = exports.TaxAddressDefectReasonEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var TaxAddressDefectReasonEnum;
(function (TaxAddressDefectReasonEnum) {
  TaxAddressDefectReasonEnum["ADDRESS_IS_NOT_EVALUATED"] = "ADDRESS_IS_NOT_EVALUATED";
  TaxAddressDefectReasonEnum["ADDRESS_LINE_MORE_THAN_40_CHARACTERS"] = "ADDRESS_LINE_MORE_THAN_40_CHARACTERS";
  TaxAddressDefectReasonEnum["APARTMENT_INVALID"] = "APARTMENT_INVALID";
  TaxAddressDefectReasonEnum["APARTMENT_MISSING"] = "APARTMENT_MISSING";
  TaxAddressDefectReasonEnum["APARTMENT_RANGE_NOT_FOUND"] = "APARTMENT_RANGE_NOT_FOUND";
  TaxAddressDefectReasonEnum["CANNOT_PARSE_ADDRESS"] = "CANNOT_PARSE_ADDRESS";
  TaxAddressDefectReasonEnum["CLIENT_PREFERENCE"] = "CLIENT_PREFERENCE";
  TaxAddressDefectReasonEnum["CODE1_NOT_AVAILABLE"] = "CODE1_NOT_AVAILABLE";
  TaxAddressDefectReasonEnum["DPV_TURNED_OFF"] = "DPV_TURNED_OFF";
  TaxAddressDefectReasonEnum["EXTRANEOUS_INFO_ON_ADDRESS_LINE"] = "EXTRANEOUS_INFO_ON_ADDRESS_LINE";
  TaxAddressDefectReasonEnum["HOUSE_NUMBER_INVALID"] = "HOUSE_NUMBER_INVALID";
  TaxAddressDefectReasonEnum["HOUSE_OR_RANGE_NOT_FOUND"] = "HOUSE_OR_RANGE_NOT_FOUND";
  TaxAddressDefectReasonEnum["INSUFFICIENT_ADDRESS"] = "INSUFFICIENT_ADDRESS";
  TaxAddressDefectReasonEnum["MORE_THAN_FOUR_ADDRESS_LINES"] = "MORE_THAN_FOUR_ADDRESS_LINES";
  TaxAddressDefectReasonEnum["MORE_THAN_TWO_ADDRESS_LINES"] = "MORE_THAN_TWO_ADDRESS_LINES";
  TaxAddressDefectReasonEnum["MULTIPLE_MATCHES_FOUND"] = "MULTIPLE_MATCHES_FOUND";
  TaxAddressDefectReasonEnum["NON_CONSECUTIVE_ADDRESS_LINES"] = "NON_CONSECUTIVE_ADDRESS_LINES";
  TaxAddressDefectReasonEnum["NO_DEFECT"] = "NO_DEFECT";
  TaxAddressDefectReasonEnum["POST_OFFICE_INVALID"] = "POST_OFFICE_INVALID";
  TaxAddressDefectReasonEnum["POST_OFFICE_MISSING"] = "POST_OFFICE_MISSING";
  TaxAddressDefectReasonEnum["PRIMARY_NUMBER_IS_MISSING"] = "PRIMARY_NUMBER_IS_MISSING";
  TaxAddressDefectReasonEnum["STREET_NOT_FOUND"] = "STREET_NOT_FOUND";
  TaxAddressDefectReasonEnum["ZIPCODE_NOT_FOUND"] = "ZIPCODE_NOT_FOUND";
})(TaxAddressDefectReasonEnum = exports.TaxAddressDefectReasonEnum || (exports.TaxAddressDefectReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TaxAddressStandardizedAddressStatusEnum;
(function (TaxAddressStandardizedAddressStatusEnum) {
  TaxAddressStandardizedAddressStatusEnum["ADDRESS_NOT_EVALUATED"] = "ADDRESS_NOT_EVALUATED";
  TaxAddressStandardizedAddressStatusEnum["NON_STANDARDIZED_ADDRESS"] = "NON_STANDARDIZED_ADDRESS";
  TaxAddressStandardizedAddressStatusEnum["STANDARDIZED_ADDRESS"] = "STANDARDIZED_ADDRESS";
})(TaxAddressStandardizedAddressStatusEnum = exports.TaxAddressStandardizedAddressStatusEnum || (exports.TaxAddressStandardizedAddressStatusEnum = {}));
