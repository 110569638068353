"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SamplingBypassSpanProcessor = void 0;
/**
 *
 */
class SamplingBypassSpanProcessor {
  wrappedSpanProcessor;
  samplingBypassDecisionProvider;
  constructor(wrappedSpanProcessor, samplingBypassDecisionProvider) {
    this.wrappedSpanProcessor = wrappedSpanProcessor;
    this.samplingBypassDecisionProvider = samplingBypassDecisionProvider;
  }
  forceFlush() {
    return this.wrappedSpanProcessor.forceFlush();
  }
  onStart(span, parentContext) {
    Promise.resolve(this.samplingBypassDecisionProvider()).then(bypassSampling => {
      if (bypassSampling) {
        span.setAttribute('vg.otel.sampling', 'bypass');
      }
      this.wrappedSpanProcessor.onStart(span, parentContext);
    });
  }
  onEnd(span) {
    this.wrappedSpanProcessor.onEnd(span);
  }
  shutdown() {
    return this.wrappedSpanProcessor.shutdown();
  }
}
exports.SamplingBypassSpanProcessor = SamplingBypassSpanProcessor;
