"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotifyingSpanExporter = void 0;
/**
 * A span exporter that delegates to another span exporter and notifies a listener.
 */
class NotifyingSpanExporter {
  wrappedSpanExporter;
  resultReceiver;
  constructor(wrappedSpanExporter, resultReceiver) {
    this.wrappedSpanExporter = wrappedSpanExporter;
    this.resultReceiver = resultReceiver;
  }
  export(spans, resultCallback) {
    this.wrappedSpanExporter.export(spans, result => {
      resultCallback(result);
      this.resultReceiver(spans, result);
    });
  }
  async shutdown() {
    await this.wrappedSpanExporter.shutdown();
  }
}
exports.NotifyingSpanExporter = NotifyingSpanExporter;
