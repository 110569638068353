import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingContext } from '@app/core/interceptors/loading-interceptor';
import { GatekeeperService } from '@app/core/services';
import { JsonContent } from '@app/etfs-equities/models';
import { HeadersUtil } from '@app/etfs-equities/utils/header/header.util';
import { OrderMappingUtil } from '@app/etfs-equities/utils/order-mapping/order-mapping.util';
import content from '@content/content.json';
import { GatekeeperFeatureIds } from '@core/enums/gatekeeper-features.enum';
import {
  OrderCancel,
  OrderCancelResponse,
  OrderCancelSubmissionData,
  OrderCancelValidationResponse,
} from '@etfs-equities/models/order-cancel';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { parseAxiosToHttpError } from '@shared/utilities/error/error.util';
import { OrdersApi, VgaOrdersResponseV2 } from '@vanguard/invest-api-client-typescript-axios';
import { AxiosError, AxiosResponse } from 'axios';
import { CookieService } from 'ngx-cookie-service';
import { from, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderCancelService {
  // Inform subscribers that triggered rules should be displayed.
  triggeredRules$ = new Subject<OrderCancelValidationResponse | OrderCancelResponse>();

  // Inform subscribers that a server error has occurred during validation.
  httpError$ = new Subject<void>();

  // Loading states...
  hasSubmitTriggeredRules = false;
  loadCancelOrderFailed = false;
  content: JsonContent = content;

  constructor(
    private readonly http: HttpClient,
    private readonly cookieService: CookieService,
    private readonly envService: EnvironmentService,
    private readonly gatekeeperService: GatekeeperService,
    private readonly ordersApi: OrdersApi
  ) {}

  validate(orderId): Observable<OrderCancelValidationResponse> {
    return this.http
      .get<OrderCancelValidationResponse>(
        this.envService.getApiUrlBaseOnRoute() + `api/trade-cancel/validate?orderId=${orderId}`,
        {
          headers: new HttpHeaders({
            'TWE-XSRF-TOKEN': this.cookieService.get('tweXsrfToken'),
          }),
          withCredentials: true,
          context: new HttpContext().set(LoadingContext, { showLoading: true, status: 'submitRequest' }),
        }
      )
      .pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  submit(orderId, acceptedWarningRules?: OrderCancelSubmissionData): Observable<OrderCancelResponse> {
    return this.http
      .post<OrderCancelResponse>(
        this.envService.getApiUrlBaseOnRoute() + `api/trade-cancel/submit?orderId=${orderId}`,
        acceptedWarningRules,
        {
          headers: new HttpHeaders({
            'TWE-XSRF-TOKEN': this.cookieService.get('tweXsrfToken'),
          }),
          withCredentials: true,
          context: new HttpContext().set(LoadingContext, { showLoading: true, status: 'submitRequest' }),
        }
      )
      .pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  loadCancelOrderV1(accountId: string, orderId: string, type = 'cancel'): Observable<OrderCancel> {
    const url = `${this.envService.getApiUrlBaseOnRoute()}api/order-details?accountId=${accountId}&orderId=${orderId}&type=${type}`;

    return this.http
      .get<OrderCancel[]>(url, {
        withCredentials: true,
        context: new HttpContext().set(LoadingContext, { showLoading: true }),
      })
      .pipe(map((response) => response[0]));
  }

  loadCancelOrderV2(accountId: string, orderId: string): Observable<OrderCancel> {
    return from(this.ordersApi.getOrderV2ById(orderId, [], false, true, HeadersUtil.showLoadingHeaders())).pipe(
      map((response: AxiosResponse<VgaOrdersResponseV2>) =>
        OrderMappingUtil.mapToTweOrderCancel(response.data, accountId)
      ),
      catchError((err: AxiosError) => {
        // upstream error handlers expect an HttpErrorResponse object
        return throwError(() => parseAxiosToHttpError(err));
      })
    );
  }

  loadCancelOrder(accountId: string, orderId: string): Observable<OrderCancel> {
    const isOrderDetailsV2Enabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_INTEGRATION_VGA_ORDERDETAILS_V2
    );
    return isOrderDetailsV2Enabled$.pipe(
      take(1),
      switchMap((isOrderDetailsV2Enabled) => {
        return (
          isOrderDetailsV2Enabled
            ? this.loadCancelOrderV2(accountId, orderId)
            : this.loadCancelOrderV1(accountId, orderId)
        ).pipe(
          tap(() => (this.loadCancelOrderFailed = false)),
          catchError((error: HttpErrorResponse) => {
            this.loadCancelOrderFailed = true;
            return throwError(() => error);
          })
        );
      })
    );
  }

  private handleHttpError(error: HttpErrorResponse) {
    this.httpError$.next();
    return throwError(() => error);
  }
}
