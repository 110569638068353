import { LotId, Order } from '@app/etfs-equities/models/order.model';
import { CancelOrderDuration, SecurityAccountTypes } from '@etfs-equities/enums';
import { AmountTypes, Durations, ParentOrderStatus, TransactionTypes, Types } from '@etfs-equities/enums/order.enums';
import { OrderCancel } from '@etfs-equities/models/order-cancel';
import {
  SecurityDetailsSecurityTypeEnum,
  VgaCostBasisOrderCostBasisMethodEnum,
  VgaOrdersResponseV2,
  VgaOrdersResponseV2AccountTypeEnum,
  VgaOrdersResponseV2OrderDurationEnum,
  VgaOrdersResponseV2OrderStatusDetailEnum,
  VgaOrdersResponseV2OrderStatusEnum,
  VgaOrdersResponseV2OrderTypeEnum,
  VgaOrdersResponseV2RequestedAmountTypeEnum,
  VgaOrdersResponseV2TransactionTypeEnum,
  VgaOrderStatusEnum,
} from '@vanguard/invest-api-client-typescript-axios';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-17';

interface DurationOptions {
  isEditCostBasis: boolean;
  isEveningTrading: boolean;
}

// Provides methods for mapping from the VGA Order Response V2 model to the TWE order model.
// The public methods here are intended to be used for both Order and OpenOrder TWE models.
export class OrderMappingUtil {
  static mapToTweOrder(order: VgaOrdersResponseV2, accountId: string, isEveningTrading: boolean): Order {
    const isEditCostBasis =
      order.orderStatusDetail === VgaOrdersResponseV2OrderStatusDetailEnum.EXECUTED ||
      (order.orderStatusDetail === VgaOrdersResponseV2OrderStatusDetailEnum.PARTIAL_EXECUTION &&
        +order.remainingShareQuantity === 0);

    return {
      accountNumber: order.brokerageAccountNumber,
      accountId: accountId,
      allOrNone: order.allOrNone,
      costBasisMethod:
        // JT9 will provide a costBasisMethod for orders other than SELL/BTC, so we filter them out here
        order.transactionType === VgaOrdersResponseV2TransactionTypeEnum.SELL ||
        order.transactionType === VgaOrdersResponseV2TransactionTypeEnum.BUY_TO_COVER
          ? OrderMappingUtil.mapCostBasisMethod(order.costBasisDetails?.costBasisMethod)
          : undefined,
      limitPrice: order.limitPrice,
      orderDuration: OrderMappingUtil.mapOrderDuration(order, {
        isEditCostBasis: isEditCostBasis,
        isEveningTrading: isEveningTrading,
      }),
      orderId: order.orderId,
      orderType: OrderMappingUtil.mapOrderType(order),
      shares: OrderMappingUtil.mapNumeric(order.requestedAmount),
      amountType: OrderMappingUtil.mapAmountType(order),
      stopPrice: order.stopPrice,
      ticker: order.security?.tickerSymbol,
      cusip: order.security?.cusip,
      transactionType: OrderMappingUtil.mapTransactionType(order),
      validation: false,
      validated: false,
      submitted: false,
      estimatedAmount: OrderMappingUtil.mapNumeric(order.orderValue),
      goodTillDate: order.goodTillDate,
      brokerageAccountNumber: order.brokerageAccountNumber,
      parentOrderStatus: OrderMappingUtil.mapParentOrderStatus(order),
      securityAccountType: OrderMappingUtil.mapSecurityAccountType(order),
      lotIds: OrderMappingUtil.mapLots(order),
      vgaOrderStatus: OrderMappingUtil.mapVgaOrderStatus(order),
      remainingQuantity: OrderMappingUtil.mapNumeric(order.remainingShareQuantity),
      executedPrice: OrderMappingUtil.mapNumeric(order.executedPrice),
      postingCode: OrderMappingUtil.mapBooleanToString(!!order.filesOnly),
      orderSource: order.businessOrigin?.channelType,
    };
  }

  static mapToTweOrderCancel(order: VgaOrdersResponseV2, accountId: string): OrderCancel {
    return {
      accountId: accountId,
      description: order.security?.securityDescription,
      orderId: order.orderId,
      transactionType: OrderMappingUtil.mapTransactionType(order),
      ticker: order.security?.tickerSymbol,
      shares: OrderMappingUtil.mapNumeric(order.requestedAmount),
      amountType: OrderMappingUtil.mapAmountType(order),
      limitPrice: OrderMappingUtil.mapNumeric(order.limitPrice),
      stopPrice: OrderMappingUtil.mapNumeric(order.stopPrice),
      orderType: OrderMappingUtil.mapOrderType(order),
      orderDuration: OrderMappingUtil.mapCancelOrderDuration(order),
      brokerageAccountNumber: order.brokerageAccountNumber,
      costBasisMethod:
        // JT9 will provide a costBasisMethod for orders other than SELL/BTC, so we filter them out here
        order.transactionType === VgaOrdersResponseV2TransactionTypeEnum.SELL ||
        order.transactionType === VgaOrdersResponseV2TransactionTypeEnum.BUY_TO_COVER
          ? OrderMappingUtil.mapCostBasisMethod(order.costBasisDetails?.costBasisMethod)
          : undefined,
      allOrNone: order.allOrNone,
      goodTillDate: order.goodTillDate,
      estimatedShareQuantity: OrderMappingUtil.mapNumeric(order.shareQuantity),

      securityAccountType: OrderMappingUtil.mapSecurityAccountType(order),
      estimatedDollarValue: OrderMappingUtil.mapNumeric(order.orderValue),
      executedShareQuantity: OrderMappingUtil.mapNumeric(order.executedShareQuantity),
      remainingQuantity: OrderMappingUtil.mapNumeric(order.remainingShareQuantity),
      postingCode: OrderMappingUtil.mapBooleanToString(!!order.filesOnly),
      orderSource: order.businessOrigin?.channelType,
    };
  }

  static mapCostBasisMethod(cbm: VgaCostBasisOrderCostBasisMethodEnum): CostBasisMethod {
    switch (cbm?.toString()) {
      case VgaCostBasisOrderCostBasisMethodEnum.AVGCOST.valueOf():
      case 'AVGC': // temporary workaround for the API returning a value that is not defined in the enum
        return CostBasisMethod.AVG_COST;
      case VgaCostBasisOrderCostBasisMethodEnum.FIFO.valueOf():
        return CostBasisMethod.FIFO;
      case VgaCostBasisOrderCostBasisMethodEnum.HIFO.valueOf():
        return CostBasisMethod.HIFO;
      case VgaCostBasisOrderCostBasisMethodEnum.SPEC.valueOf():
      case VgaCostBasisOrderCostBasisMethodEnum.SPECID.valueOf():
        return CostBasisMethod.SPEC_ID;
      case VgaCostBasisOrderCostBasisMethodEnum.MINT.valueOf():
        return CostBasisMethod.MIN_TAX;
      default:
        return undefined;
    }
  }

  static isMutualFund(securityType: SecurityDetailsSecurityTypeEnum): boolean {
    return (
      securityType === SecurityDetailsSecurityTypeEnum.VANGUARD_MUTUAL_FUND ||
      securityType === SecurityDetailsSecurityTypeEnum.NON_VANGUARD_MUTUAL_FUND
    );
  }

  static mapBooleanToString(b: boolean): string {
    return b ? '1' : '0';
  }

  // map all falsy values to undefined, all other strings to their numeric value
  static mapNumeric(s: string): number {
    return s ? +s : undefined;
  }

  private static mapOrderDuration(order: VgaOrdersResponseV2, options: DurationOptions): Durations {
    switch (order.orderDuration) {
      case VgaOrdersResponseV2OrderDurationEnum.DAY:
        return Durations.DAY;
      case VgaOrdersResponseV2OrderDurationEnum.GOOD_TILL_CANCEL:
        return Durations.GTC;
      case VgaOrdersResponseV2OrderDurationEnum.EVENING:
        if (options.isEditCostBasis || options.isEveningTrading) {
          return Durations.EVENING;
        }
        return undefined;
      default:
        return undefined;
    }
  }

  private static mapOrderType(order: VgaOrdersResponseV2): Types {
    switch (order.orderType) {
      case VgaOrdersResponseV2OrderTypeEnum.MARKET:
        return Types.MARKET;
      case VgaOrdersResponseV2OrderTypeEnum.LIMIT:
        return Types.LIMIT;
      case VgaOrdersResponseV2OrderTypeEnum.STOP:
        return Types.STOP;
      case VgaOrdersResponseV2OrderTypeEnum.STOP_LIMIT:
        return Types.STOP_LIMIT;
      default:
        return undefined;
    }
  }

  private static mapAmountType(order: VgaOrdersResponseV2): AmountTypes {
    switch (order.requestedAmountType) {
      case VgaOrdersResponseV2RequestedAmountTypeEnum.SHARES:
        return AmountTypes.SHARES;
      case VgaOrdersResponseV2RequestedAmountTypeEnum.DOLLARS:
        return AmountTypes.DOLLARS;
      default:
        return undefined;
    }
  }

  private static mapTransactionType(order: VgaOrdersResponseV2): TransactionTypes {
    switch (order.transactionType) {
      case VgaOrdersResponseV2TransactionTypeEnum.BUY:
        return TransactionTypes.BUY;
      case VgaOrdersResponseV2TransactionTypeEnum.SELL:
        return TransactionTypes.SELL;
      case VgaOrdersResponseV2TransactionTypeEnum.BUY_TO_COVER:
        return TransactionTypes.BUY_TO_COVER;
      case VgaOrdersResponseV2TransactionTypeEnum.SELL_SHORT:
        return TransactionTypes.SELL_SHORT;
      default:
        return undefined;
    }
  }

  private static mapParentOrderStatus(order: VgaOrdersResponseV2): ParentOrderStatus {
    switch (order.orderStatus) {
      case VgaOrdersResponseV2OrderStatusEnum.OPEN:
        return ParentOrderStatus.OPEN;
      case VgaOrdersResponseV2OrderStatusEnum.ENTERED:
        return ParentOrderStatus.ENTERED;
      case VgaOrdersResponseV2OrderStatusEnum.EXPIRED:
        return ParentOrderStatus.EXPIRED;
      case VgaOrdersResponseV2OrderStatusEnum.COMPLETE:
        return ParentOrderStatus.COMPLETE;
      case VgaOrdersResponseV2OrderStatusEnum.REJECTED:
        return ParentOrderStatus.REJECTED;
      case VgaOrdersResponseV2OrderStatusEnum.PENDING:
        return ParentOrderStatus.PENDING;
      default:
        return undefined;
    }
  }

  private static mapSecurityAccountType(order: VgaOrdersResponseV2): SecurityAccountTypes {
    switch (order.accountType) {
      case VgaOrdersResponseV2AccountTypeEnum.CASH:
        return SecurityAccountTypes.CASH;
      case VgaOrdersResponseV2AccountTypeEnum.MARGIN:
        return SecurityAccountTypes.MARGIN;
      case VgaOrdersResponseV2AccountTypeEnum.SHORT:
        return SecurityAccountTypes.SHORT;
      default:
        return undefined;
    }
  }

  private static mapVgaOrderStatus(order: VgaOrdersResponseV2): VgaOrderStatusEnum {
    switch (order.orderStatusDetail) {
      case VgaOrdersResponseV2OrderStatusDetailEnum.OPEN:
        return VgaOrderStatusEnum.OPEN;
      case VgaOrdersResponseV2OrderStatusDetailEnum.IN_PROGRESS:
        return VgaOrderStatusEnum.IN_PROGRESS;
      case VgaOrdersResponseV2OrderStatusDetailEnum.ENTERED:
        return VgaOrderStatusEnum.ENTERED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.EXPIRED:
        return VgaOrderStatusEnum.EXPIRED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.EXECUTED:
        return VgaOrderStatusEnum.EXECUTED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.CANCELED:
        return VgaOrderStatusEnum.CANCELLED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.DROPPED:
        return VgaOrderStatusEnum.DROPPED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.REJECTED:
        return VgaOrderStatusEnum.REJECTED;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PARTIAL_CANCEL:
        return VgaOrderStatusEnum.PARTIAL_CANCEL;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PARTIAL_EXECUTION:
        return VgaOrderStatusEnum.PARTIAL_EXECUTION;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PENDING_CANCEL:
        return VgaOrderStatusEnum.PENDING_CANCEL;
      case VgaOrdersResponseV2OrderStatusDetailEnum.PENDING_CHANGE:
        return VgaOrderStatusEnum.PENDING_CHANGE;
      default:
        return undefined;
    }
  }

  private static mapLots(order: VgaOrdersResponseV2): LotId[] {
    return order.costBasisDetails?.lots?.map((lot) => ({
      lotId: lot.lotId.toString(),
      tradeQuantity: lot.tradeQuantity.toString(),
      acquiredDate: lot.acquiredDate,
    }));
  }

  private static mapCancelOrderDuration(order: VgaOrdersResponseV2): CancelOrderDuration {
    switch (order.orderDuration) {
      case VgaOrdersResponseV2OrderDurationEnum.DAY:
        return CancelOrderDuration.DAY;
      case VgaOrdersResponseV2OrderDurationEnum.EVENING:
        return CancelOrderDuration.EVENING;
      case VgaOrdersResponseV2OrderDurationEnum.GOOD_TILL_CANCEL:
        return CancelOrderDuration.SIXTY_DAY_GTC;
      case VgaOrdersResponseV2OrderDurationEnum.GOOD_TILL_DATE:
        return CancelOrderDuration.GOOD_TILL_DAY;
      default:
        return undefined;
    }
  }
}
