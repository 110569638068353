"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpanProcessorFactory = void 0;
/**
 * SpanProcessorFactory - a factory for creating a {@link SpanProcessor}
 */
class SpanProcessorFactory {}
exports.SpanProcessorFactory = SpanProcessorFactory;
