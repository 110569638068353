"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DelayedQuoteVendorEnum = exports.DelayedQuoteProductTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var DelayedQuoteProductTypeEnum;
(function (DelayedQuoteProductTypeEnum) {
  DelayedQuoteProductTypeEnum["EQUITY"] = "EQUITY";
  DelayedQuoteProductTypeEnum["EXCHANGE_TRADED_OPTIONS"] = "EXCHANGE_TRADED_OPTIONS";
  DelayedQuoteProductTypeEnum["INDEX"] = "INDEX";
  DelayedQuoteProductTypeEnum["FUNDS"] = "FUNDS";
  DelayedQuoteProductTypeEnum["OPTIONS_AND_FUTURES"] = "OPTIONS_AND_FUTURES";
  DelayedQuoteProductTypeEnum["SECURITIZED_DERIVATIVES"] = "SECURITIZED_DERIVATIVES";
  DelayedQuoteProductTypeEnum["MONEY_MARKET_FUNDS"] = "MONEY_MARKET_FUNDS";
})(DelayedQuoteProductTypeEnum = exports.DelayedQuoteProductTypeEnum || (exports.DelayedQuoteProductTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var DelayedQuoteVendorEnum;
(function (DelayedQuoteVendorEnum) {
  DelayedQuoteVendorEnum["FACTSET"] = "FACTSET";
  DelayedQuoteVendorEnum["REFINITIV"] = "REFINITIV";
})(DelayedQuoteVendorEnum = exports.DelayedQuoteVendorEnum || (exports.DelayedQuoteVendorEnum = {}));
