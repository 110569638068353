<ng-container
  *ngIf="{
    selectedAccount: (selectedAccount$ | async),
    orderCancel: (orderCancel$ | async),
    formatSharesDisplay: (formatSharesDisplay$ | async)
  } as view"
>
  <div class="cancel-order-detail">
    <div class="twe-card">
      <div class="row no-gutters">
        <div class="col-12">
          <p class="cancel-order-detail__header">{{ content.orderDetail.account }}</p>
        </div>
      </div>
      <div
        class="row no-gutters cancel-order-detail__row cancel-order-detail--border-top cancel-order-detail--border-bottom c11n-space-stack-5x"
      >
        <div class="col-4">
          <p class="cancel-order-detail__label">{{ content.orderDetail.account }}</p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data"
            >{{ view.selectedAccount?.accountName || '&mdash;'
            }}{{
              view.selectedAccount?.marginCode === marginIndicator
                ? (' (' + view.selectedAccount.marginCode + ')' | titlecase)
                : ''
            }}</p
          >
        </div>
      </div>

      <div class="row no-gutters">
        <div class="col-12">
          <p class="cancel-order-detail__header">
            {{ content.labels.orderNumber }}{{ view.orderCancel?.orderId || '&mdash;' }}</p
          >
        </div>
      </div>
      <div
        class="row no-gutters cancel-order-detail__row cancel-order-detail--border-top cancel-order-detail--border-bottom"
      >
        <div class="col-4">
          <p class="cancel-order-detail__label">{{ content.orderDetail.transaction }}</p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data cancel-order-detail--title-case">
            {{
              (view.orderCancel?.transactionType === enumActions.BUY ? content.labels.buy : content.labels.sell) ||
                '&mdash;'
            }}</p
          >
        </div>
      </div>
      <div
        *ngIf="view.selectedAccount?.marginCode === marginIndicator"
        class="row no-gutters cancel-order-detail__row cancel-order-detail--border-bottom"
      >
        <div class="col-4">
          <p class="cancel-order-detail__label">{{ content.labels.marginTradeType }}</p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data">{{
            (view.orderCancel?.securityAccountType | titlecase) || '&mdash;'
          }}</p>
        </div>
      </div>
      <div class="row no-gutters cancel-order-detail__row cancel-order-detail--border-bottom">
        <div class="col-4">
          <p class="cancel-order-detail__label">{{ content.orderDetail.security }}</p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data"
            >{{ view.orderCancel?.description || '&mdash;' }} ({{ view.orderCancel?.ticker || '&mdash;' }})</p
          >
        </div>
      </div>
      <div class="row no-gutters cancel-order-detail__row cancel-order-detail--border-bottom">
        <div class="col-4">
          <p class="cancel-order-detail__label">
            {{
              view.orderCancel?.amountType === enumAmountTypes.DOLLARS
                ? content.labels.estimatedShares
                : content.labels.shares
            }}</p
          >
        </div>
        <div class="col-8">
          <p *ngIf="!view.orderCancel?.amountType" class="cancel-order-detail__data"> &mdash; </p>
          <p *ngIf="view.orderCancel?.amountType === enumAmountTypes.DOLLARS" class="cancel-order-detail__data">
            {{ (view.orderCancel?.estimatedShareQuantity | number : '1.4-4') || '&mdash;' }}
          </p>
          <p
            class="cancel-order-detail__data"
            *ngIf="view.orderCancel?.executedShareQuantity && view.orderCancel?.amountType === enumAmountTypes.SHARES"
          >
            {{ content?.orderStatusLabel?.executed }}: {{ view.orderCancel?.executedShareQuantity }} |
            {{ content?.orderStatusLabel?.open }}: {{ view.orderCancel?.remainingQuantity }}
          </p>

          <p
            class="cancel-order-detail__data"
            *ngIf="!view.orderCancel?.executedShareQuantity && view.orderCancel?.amountType === enumAmountTypes.SHARES"
          >
            {{ view.formatSharesDisplay || '&mdash;' }}
          </p>
        </div>
      </div>
      <div class="row no-gutters cancel-order-detail__row cancel-order-detail--border-bottom">
        <div class="col-4">
          <p class="cancel-order-detail__label">
            {{
              (view.orderCancel?.amountType === enumAmountTypes.DOLLARS
                ? content.labels.amount
                : content.labels.estimatedAmount) || '&mdash;'
            }}
          </p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data">{{
            view.orderCancel?.amountType === enumAmountTypes.DOLLARS
              ? (view.orderCancel?.shares | tweSubDollarCurrency)
              : (view.orderCancel?.estimatedDollarValue | tweSubDollarCurrency) || '&#36;&mdash;'
          }}</p>
        </div>
      </div>
      <div class="row no-gutters cancel-order-detail__row cancel-order-detail--border-bottom">
        <div class="col-4">
          <p class="cancel-order-detail__label">{{ content.orderDetail.orderType }}</p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data"> {{ (view.orderCancel?.orderType | titlecase) || '&mdash;' }}</p>
        </div>
      </div>
      <div
        *ngIf="view.orderCancel?.stopPrice"
        class="row no-gutters cancel-order-detail__row cancel-order-detail--border-bottom"
      >
        <div class="col-4">
          <p class="cancel-order-detail__label">{{ content.orderDetail.stopPrice }}</p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data"> {{ (view.orderCancel?.stopPrice | currency) || '&mdash;' }} </p>
        </div>
      </div>
      <div
        *ngIf="view.orderCancel?.limitPrice"
        class="row no-gutters cancel-order-detail__row cancel-order-detail--border-bottom"
      >
        <div class="col-4">
          <p class="cancel-order-detail__label">{{ content.orderDetail.limitPrice }}</p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data"> {{ (view.orderCancel?.limitPrice | currency) || '&mdash;' }}</p>
        </div>
      </div>
      <div class="row no-gutters cancel-order-detail__row cancel-order-detail--border-bottom">
        <div class="col-4">
          <p class="cancel-order-detail__label">{{ content.orderDetail.duration }}</p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data"> {{ view.orderCancel?.orderDuration || '&mdash;' }} </p>
        </div>
      </div>
      <div
        *ngIf="view.orderCancel?.costBasisMethod"
        class="row no-gutters cancel-order-detail__row cancel-order-detail--border-bottom"
      >
        <div class="col-4">
          <p class="cancel-order-detail__label">{{ content.orderDetail.costBasis }}</p>
        </div>
        <div class="col-8">
          <p class="cancel-order-detail__data"> {{ view.orderCancel?.costBasisMethod | tweCostBasisExpand }}</p>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-12">
          <p class="cancel-order-detail__disclaimer" [innerHtml]="getDynamicContent(view.orderCancel)"> </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
