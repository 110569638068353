"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RetirementContributionInstructionsTaxYear = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var RetirementContributionInstructionsTaxYear;
(function (RetirementContributionInstructionsTaxYear) {
  RetirementContributionInstructionsTaxYear["PRIOR"] = "PRIOR";
  RetirementContributionInstructionsTaxYear["CURRENT"] = "CURRENT";
})(RetirementContributionInstructionsTaxYear = exports.RetirementContributionInstructionsTaxYear || (exports.RetirementContributionInstructionsTaxYear = {}));
