"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VgaOrderStatusEnum = exports.VgaOrderParentOrderStatusEnum = exports.VgaOrderRequestedAmountUnitEnum = exports.VgaOrderInstructionEnum = exports.VgaOrderSecurityAccountTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var VgaOrderSecurityAccountTypeEnum;
(function (VgaOrderSecurityAccountTypeEnum) {
  VgaOrderSecurityAccountTypeEnum["CASH"] = "CASH";
  VgaOrderSecurityAccountTypeEnum["MARGIN"] = "MARGIN";
  VgaOrderSecurityAccountTypeEnum["SHORT"] = "SHORT";
})(VgaOrderSecurityAccountTypeEnum = exports.VgaOrderSecurityAccountTypeEnum || (exports.VgaOrderSecurityAccountTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrderInstructionEnum;
(function (VgaOrderInstructionEnum) {
  VgaOrderInstructionEnum["BUY"] = "BUY";
  VgaOrderInstructionEnum["BUY_MINUS"] = "BUY_MINUS";
  VgaOrderInstructionEnum["BUY_TO_COVER"] = "BUY_TO_COVER";
  VgaOrderInstructionEnum["EXCHANGE"] = "EXCHANGE";
  VgaOrderInstructionEnum["EXCHANGE_ALL"] = "EXCHANGE_ALL";
  VgaOrderInstructionEnum["SELL"] = "SELL";
  VgaOrderInstructionEnum["SELL_TRANSFER"] = "SELL_TRANSFER";
  VgaOrderInstructionEnum["SELL_PLUS"] = "SELL_PLUS";
  VgaOrderInstructionEnum["SELL_SHORT"] = "SELL_SHORT";
  VgaOrderInstructionEnum["SSLOCEX"] = "SSLOCEX";
})(VgaOrderInstructionEnum = exports.VgaOrderInstructionEnum || (exports.VgaOrderInstructionEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrderRequestedAmountUnitEnum;
(function (VgaOrderRequestedAmountUnitEnum) {
  VgaOrderRequestedAmountUnitEnum["SHARES"] = "SHARES";
  VgaOrderRequestedAmountUnitEnum["DOLLARS"] = "DOLLARS";
  VgaOrderRequestedAmountUnitEnum["PERCENTAGE"] = "PERCENTAGE";
  VgaOrderRequestedAmountUnitEnum["OPTION"] = "OPTION";
})(VgaOrderRequestedAmountUnitEnum = exports.VgaOrderRequestedAmountUnitEnum || (exports.VgaOrderRequestedAmountUnitEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrderParentOrderStatusEnum;
(function (VgaOrderParentOrderStatusEnum) {
  VgaOrderParentOrderStatusEnum["OPEN"] = "OPEN";
  VgaOrderParentOrderStatusEnum["ENTERED"] = "ENTERED";
  VgaOrderParentOrderStatusEnum["EXPIRED"] = "EXPIRED";
  VgaOrderParentOrderStatusEnum["COMPLETE"] = "COMPLETE";
  VgaOrderParentOrderStatusEnum["REJECTED"] = "REJECTED";
  VgaOrderParentOrderStatusEnum["PENDING"] = "PENDING";
  VgaOrderParentOrderStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
  VgaOrderParentOrderStatusEnum["UNKNOWN"] = "UNKNOWN";
})(VgaOrderParentOrderStatusEnum = exports.VgaOrderParentOrderStatusEnum || (exports.VgaOrderParentOrderStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var VgaOrderStatusEnum;
(function (VgaOrderStatusEnum) {
  VgaOrderStatusEnum["OPEN"] = "OPEN";
  VgaOrderStatusEnum["IN_PROGRESS"] = "IN_PROGRESS";
  VgaOrderStatusEnum["ENTERED"] = "ENTERED";
  VgaOrderStatusEnum["EXPIRED"] = "EXPIRED";
  VgaOrderStatusEnum["EXECUTED"] = "EXECUTED";
  VgaOrderStatusEnum["CANCELLED"] = "CANCELLED";
  VgaOrderStatusEnum["DROPPED"] = "DROPPED";
  VgaOrderStatusEnum["REJECTED"] = "REJECTED";
  VgaOrderStatusEnum["PARTIAL_CANCEL"] = "PARTIAL_CANCEL";
  VgaOrderStatusEnum["PARTIAL_EXECUTION"] = "PARTIAL_EXECUTION";
  VgaOrderStatusEnum["PENDING_CANCEL"] = "PENDING_CANCEL";
  VgaOrderStatusEnum["PENDING_CHANGE"] = "PENDING_CHANGE";
})(VgaOrderStatusEnum = exports.VgaOrderStatusEnum || (exports.VgaOrderStatusEnum = {}));
