import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingContext } from '@app/core/interceptors/loading-interceptor';
import { UILogError } from '@app/core/models/ui-log/ui-log.model';
import { Order } from '@app/etfs-equities/models';
import { OrderUtil } from '@app/etfs-equities/utils';
import { OrderMappingUtil } from '@app/etfs-equities/utils/order-mapping/order-mapping.util';
import { GatekeeperFeatureIds } from '@core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@core/services';
import { HeadersUtil } from '@etfs-equities/utils/header/header.util';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { parseAxiosToHttpError } from '@shared/utilities/error/error.util';
import { OrdersApi, VgaOrdersResponseV2 } from '@vanguard/invest-api-client-typescript-axios';
import { AxiosError, AxiosResponse } from 'axios';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, from, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  // Inform subscribers that triggered rules should be displayed.
  triggeredRules$ = new Subject<Order.Order>();

  // Inform subscribers that a server error has occurred during validation.
  httpError$ = new Subject<UILogError>();
  hasSubmitTimeoutError = false;

  isOrderLoading = false;
  loadOrderFailed = false;

  constructor(
    private readonly http: HttpClient,
    private readonly cookieService: CookieService,
    private readonly envService: EnvironmentService,
    private readonly gatekeeperService: GatekeeperService,
    private readonly ordersApi: OrdersApi
  ) {}

  validate(order: Order.Order, isChangeOrder: boolean): Observable<Order.Order> {
    this.hasSubmitTimeoutError = false;

    const url = isChangeOrder ? `api/change-order/validate?orderId=${order.orderId}` : 'api/order/validate';

    const requestOpts = {
      headers: new HttpHeaders({
        'TWE-XSRF-TOKEN': this.cookieService.get('tweXsrfToken'),
      }),
      withCredentials: true,
      context: new HttpContext().set(LoadingContext, {
        showLoading: true,
        status: 'preview',
      }),
    };

    return this.http
      .post<Order.Order>(this.envService.getApiUrlBaseOnRoute() + url, { order }, requestOpts)
      .pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error, 'OrderService.validate')));
  }

  submit(order: Order.Order, isChangeOrder = false): Observable<Order.Order> {
    this.hasSubmitTimeoutError = false;

    const orderPath = OrderUtil.getOrderPathFromTransactionType(order.transactionType);

    if (!orderPath) {
      const error = `Invalid transactionType: ${order.transactionType}`;
      return this.handleHttpError(new HttpErrorResponse({ error }));
    }

    const requestUrlPath = isChangeOrder
      ? `api/change-order/submit?orderId=${order.orderId}`
      : `api/order/${orderPath}`;

    const requestOpts = {
      headers: new HttpHeaders({
        'TWE-XSRF-TOKEN': this.cookieService.get('tweXsrfToken'),
      }),
      withCredentials: true,
      context: new HttpContext().set(LoadingContext, {
        showLoading: true,
        status: 'submit',
      }),
    };

    return this.http
      .post<Order.Order>(this.envService.getApiUrlBaseOnRoute() + requestUrlPath, { order }, requestOpts)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleHttpError(error, 'OrderService.submit');
        })
      );
  }

  fetchOrderDetailsV1(accountId: string, orderId: string, showLoading: boolean): Observable<Order.Order> {
    const url = `${this.envService.getApiUrlBaseOnRoute()}api/order-details?accountId=${accountId}&orderId=${orderId}`;

    return this.http
      .get<Order.Order[]>(url, {
        withCredentials: true,
        context: new HttpContext().set(LoadingContext, { showLoading }),
      })
      .pipe(map(([order]) => order));
  }

  fetchOrderDetailsV2(accountId: string, orderId: string, showLoading: boolean): Observable<Order.Order> {
    const isEveningTrading$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TOGGLE_EXTENDED_TRADING
    );
    return combineLatest([
      isEveningTrading$,
      from(
        this.ordersApi.getOrderV2ById(orderId, [], false, true, showLoading ? HeadersUtil.showLoadingHeaders() : {})
      ),
    ]).pipe(
      map(([isEveningTrading, response]: [boolean, AxiosResponse<VgaOrdersResponseV2>]) => {
        return OrderMappingUtil.mapToTweOrder(response.data, accountId, isEveningTrading);
      }),
      catchError((err: AxiosError) => {
        // upstream error handlers expect an HttpErrorResponse object
        return throwError(() => parseAxiosToHttpError(err));
      })
    );
  }

  getOrderDetails(accountId: string, orderId: string, showLoading = false): Observable<Order.Order> {
    const isOrderDetailsV2Enabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_INTEGRATION_VGA_ORDERDETAILS_V2
    );
    return isOrderDetailsV2Enabled$.pipe(
      take(1),
      switchMap((isOrderDetailsV2Enabled) => {
        return isOrderDetailsV2Enabled
          ? this.fetchOrderDetailsV2(accountId, orderId, showLoading)
          : this.fetchOrderDetailsV1(accountId, orderId, showLoading);
      })
    );
  }

  loadOrderDetails(accountId: string, orderId: string, showLoading = true): Observable<Order.Order> {
    this.isOrderLoading = true;

    return this.getOrderDetails(accountId, orderId, showLoading).pipe(
      tap(() => {
        this.isOrderLoading = false;
        this.loadOrderFailed = false;
      }),
      catchError((error: HttpErrorResponse) => {
        this.loadOrderFailed = true;
        this.isOrderLoading = false;
        return throwError(() => error);
      })
    );
  }

  private handleHttpError(error: HttpErrorResponse, serviceName?: string) {
    if (error.error[0]?.code === HttpStatusCode.RequestTimeout) {
      this.hasSubmitTimeoutError = true;
    }
    this.httpError$.next({ error, serviceName });
    return throwError(() => error);
  }
}
