"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBaggageConstantByHeader = exports.isCallChainEntry = exports.findNonCallChainEntries = exports.findCallChainEntry = exports.appendAppToCallChain = exports.addVgCallChainToBaggage = void 0;
const utils_1 = require("@opentelemetry/core/build/src/baggage/utils");
const trade_nil_utils_lib_1 = require("@vanguard/trade-nil-utils-lib");
const baggage_model_1 = require("../baggage/baggage.model");
/**
 *  addVgCallChainToBaggage - adds the app prefix to the call chain
 * @param baggage - the baggage to add the call chain to
 * @param appPrefix - the app prefix to add to the call chain
 * @returns - the baggage with the call chain added
 */
function addVgCallChainToBaggage(baggage, appPrefix) {
  const maybeCallChainPair = (0, utils_1.getKeyPairs)(baggage).map(keyPair => (0, utils_1.parsePairKeyValue)(keyPair)).find(parsedKeyPair => parsedKeyPair && isCallChainEntry(parsedKeyPair.key));
  const callChainKeyPair = maybeCallChainPair ?? {
    value: '',
    metadata: undefined
  };
  const callChain = appendAppToCallChain(callChainKeyPair.value, appPrefix);
  return baggage.setEntry(baggage_model_1.BaggageMapping[baggage_model_1.BaggageConstants.VG_CALL_CHAIN], {
    value: callChain,
    metadata: callChainKeyPair.metadata
  });
}
exports.addVgCallChainToBaggage = addVgCallChainToBaggage;
/**
 *  appendAppToCallChain - appends the app prefix to the call chain
 * @param callChainKeyPair - the call chain to append to
 * @param appPrefix - the app prefix to append to the call chain
 * @returns - the call chain with the app prefix appended
 */
function appendAppToCallChain(callChainKeyPair, appPrefix) {
  const prefixes = callChainKeyPair.split(',').filter(s => !!s);
  prefixes.push(appPrefix);
  return prefixes.join(',');
}
exports.appendAppToCallChain = appendAppToCallChain;
function findCallChainEntry(entries) {
  return entries.find(([key]) => isCallChainEntry(key));
}
exports.findCallChainEntry = findCallChainEntry;
function findNonCallChainEntries(entries) {
  return entries.filter(([key]) => !isCallChainEntry(key));
}
exports.findNonCallChainEntries = findNonCallChainEntries;
function isCallChainEntry(key) {
  return key === baggage_model_1.BaggageMapping[baggage_model_1.BaggageConstants.VG_CALL_CHAIN] || key === baggage_model_1.BaggageConstants.VG_CALL_CHAIN;
}
exports.isCallChainEntry = isCallChainEntry;
function getBaggageConstantByHeader(baggageHeader) {
  const entry = Object.entries(baggage_model_1.BaggageMapping).find(([, header]) => header === baggageHeader);
  return (0, trade_nil_utils_lib_1.applyIfPresent)(entry, ([constant]) => constant);
}
exports.getBaggageConstantByHeader = getBaggageConstantByHeader;
