"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TAMutualFundAccountPositionMatrixLevelCodeEnum = exports.TAMutualFundAccountPositionStatusCodeEnum = exports.TAMutualFundAccountPositionSecurityTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var TAMutualFundAccountPositionSecurityTypeEnum;
(function (TAMutualFundAccountPositionSecurityTypeEnum) {
  TAMutualFundAccountPositionSecurityTypeEnum["MONEY_MARKET_FUND"] = "MONEY_MARKET_FUND";
  TAMutualFundAccountPositionSecurityTypeEnum["COMMON_STOCK"] = "COMMON_STOCK";
  TAMutualFundAccountPositionSecurityTypeEnum["PREFERRED_STOCK"] = "PREFERRED_STOCK";
  TAMutualFundAccountPositionSecurityTypeEnum["CONVERTIBLE_PREFERRED_STOCK"] = "CONVERTIBLE_PREFERRED_STOCK";
  TAMutualFundAccountPositionSecurityTypeEnum["RIGHTS"] = "RIGHTS";
  TAMutualFundAccountPositionSecurityTypeEnum["WARRANT"] = "WARRANT";
  TAMutualFundAccountPositionSecurityTypeEnum["UNITS"] = "UNITS";
  TAMutualFundAccountPositionSecurityTypeEnum["MUTUAL_FUND"] = "MUTUAL_FUND";
  TAMutualFundAccountPositionSecurityTypeEnum["LIMITED_PARTNERSHIP"] = "LIMITED_PARTNERSHIP";
  TAMutualFundAccountPositionSecurityTypeEnum["CALL_OPTION"] = "CALL_OPTION";
  TAMutualFundAccountPositionSecurityTypeEnum["PUT_OPTION"] = "PUT_OPTION";
  TAMutualFundAccountPositionSecurityTypeEnum["BANK_CALL_OPTION"] = "BANK_CALL_OPTION";
  TAMutualFundAccountPositionSecurityTypeEnum["ESCROW"] = "ESCROW";
  TAMutualFundAccountPositionSecurityTypeEnum["CORPORATE_BOND"] = "CORPORATE_BOND";
  TAMutualFundAccountPositionSecurityTypeEnum["CONVERTIBLE_BOND"] = "CONVERTIBLE_BOND";
  TAMutualFundAccountPositionSecurityTypeEnum["MUNICIPAL_BOND"] = "MUNICIPAL_BOND";
  TAMutualFundAccountPositionSecurityTypeEnum["SHORT_TERM_PAPER"] = "SHORT_TERM_PAPER";
  TAMutualFundAccountPositionSecurityTypeEnum["BOND_UNIT"] = "BOND_UNIT";
  TAMutualFundAccountPositionSecurityTypeEnum["MUNI_ASSESSMENT_DIST"] = "MUNI_ASSESSMENT_DIST";
  TAMutualFundAccountPositionSecurityTypeEnum["US_TREASURY_BILL"] = "US_TREASURY_BILL";
  TAMutualFundAccountPositionSecurityTypeEnum["US_TREASURY_NOTE"] = "US_TREASURY_NOTE";
  TAMutualFundAccountPositionSecurityTypeEnum["US_TREASURY_BOND"] = "US_TREASURY_BOND";
  TAMutualFundAccountPositionSecurityTypeEnum["OTHER_GOVERNMENT"] = "OTHER_GOVERNMENT";
  TAMutualFundAccountPositionSecurityTypeEnum["US_TREASURY_ZERO_COUPON"] = "US_TREASURY_ZERO_COUPON";
  TAMutualFundAccountPositionSecurityTypeEnum["GOVERNMENT_MORTGAGE"] = "GOVERNMENT_MORTGAGE";
  TAMutualFundAccountPositionSecurityTypeEnum["COLLATERALIZED_MORTGAGE_OBLIGATION"] = "COLLATERALIZED_MORTGAGE_OBLIGATION";
  TAMutualFundAccountPositionSecurityTypeEnum["WHEN_AS_AND_IF_ISSUED_EQUITY"] = "WHEN_AS_AND_IF_ISSUED_EQUITY";
  TAMutualFundAccountPositionSecurityTypeEnum["WHEN_AS_AND_IF_ISSUED_BOND"] = "WHEN_AS_AND_IF_ISSUED_BOND";
  TAMutualFundAccountPositionSecurityTypeEnum["BANKERS_ACCEPTANCE"] = "BANKERS_ACCEPTANCE";
  TAMutualFundAccountPositionSecurityTypeEnum["CERTIFICATES_OF_DEPOSIT"] = "CERTIFICATES_OF_DEPOSIT";
  TAMutualFundAccountPositionSecurityTypeEnum["COMMERCIAL_PAPER"] = "COMMERCIAL_PAPER";
  TAMutualFundAccountPositionSecurityTypeEnum["FIXED_INCOME_UNITS"] = "FIXED_INCOME_UNITS";
  TAMutualFundAccountPositionSecurityTypeEnum["ETF"] = "ETF";
  TAMutualFundAccountPositionSecurityTypeEnum["VANGUARD_ETF"] = "VANGUARD_ETF";
  TAMutualFundAccountPositionSecurityTypeEnum["MISCELLANEOUS_FIXED_INCOME"] = "MISCELLANEOUS_FIXED_INCOME";
  TAMutualFundAccountPositionSecurityTypeEnum["MISCELLANEOUS_STOCK"] = "MISCELLANEOUS_STOCK";
  TAMutualFundAccountPositionSecurityTypeEnum["TAX_SHELTER"] = "TAX_SHELTER";
  TAMutualFundAccountPositionSecurityTypeEnum["UNKNOWN"] = "UNKNOWN";
})(TAMutualFundAccountPositionSecurityTypeEnum = exports.TAMutualFundAccountPositionSecurityTypeEnum || (exports.TAMutualFundAccountPositionSecurityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TAMutualFundAccountPositionStatusCodeEnum;
(function (TAMutualFundAccountPositionStatusCodeEnum) {
  TAMutualFundAccountPositionStatusCodeEnum["CLOSED_02"] = "CLOSED_02";
  TAMutualFundAccountPositionStatusCodeEnum["ESCHEATED_05"] = "ESCHEATED_05";
  TAMutualFundAccountPositionStatusCodeEnum["MARGINAL_03"] = "MARGINAL_03";
  TAMutualFundAccountPositionStatusCodeEnum["OPEN_01"] = "OPEN_01";
  TAMutualFundAccountPositionStatusCodeEnum["RPO_04"] = "RPO_04";
  TAMutualFundAccountPositionStatusCodeEnum["UNKNOWN"] = "UNKNOWN";
})(TAMutualFundAccountPositionStatusCodeEnum = exports.TAMutualFundAccountPositionStatusCodeEnum || (exports.TAMutualFundAccountPositionStatusCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TAMutualFundAccountPositionMatrixLevelCodeEnum;
(function (TAMutualFundAccountPositionMatrixLevelCodeEnum) {
  TAMutualFundAccountPositionMatrixLevelCodeEnum["BROKER_CONTROLLED_ACCOUNT_3"] = "BROKER_CONTROLLED_ACCOUNT_3";
  TAMutualFundAccountPositionMatrixLevelCodeEnum["NON_NETWORKED_ACCOUNTS_0"] = "NON_NETWORKED_ACCOUNTS_0";
  TAMutualFundAccountPositionMatrixLevelCodeEnum["UNKNOWN"] = "UNKNOWN";
})(TAMutualFundAccountPositionMatrixLevelCodeEnum = exports.TAMutualFundAccountPositionMatrixLevelCodeEnum || (exports.TAMutualFundAccountPositionMatrixLevelCodeEnum = {}));
