"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.environments = exports.environmentToUrl = exports.CollectorLocation = exports.CollectorEnvironment = void 0;
/**
 * The region of the collector to which spans are exported
 */
var CollectorEnvironment;
(function (CollectorEnvironment) {
  CollectorEnvironment["ENGINEERING"] = "ENGINEERING";
  CollectorEnvironment["TEST"] = "TEST";
  CollectorEnvironment["PRODUCTION"] = "PRODUCTION";
})(CollectorEnvironment = exports.CollectorEnvironment || (exports.CollectorEnvironment = {}));
/**
 * The location of the collector to which spans are exported
 */
var CollectorLocation;
(function (CollectorLocation) {
  CollectorLocation["PRIVATE"] = "PRIVATE";
  CollectorLocation["PUBLIC"] = "PUBLIC";
  CollectorLocation["CONSOLE"] = "CONSOLE";
  CollectorLocation["MEMORY"] = "MEMORY";
  CollectorLocation["NOOP"] = "NOOP";
})(CollectorLocation = exports.CollectorLocation || (exports.CollectorLocation = {}));
/**
 *  This file contains the environments for the collector.
 * @param environment - The environment.
 * @param location - The location.
 * @returns - The url.
 */
function environmentToUrl(collectorEnvironment, collectorLocation) {
  return exports.environments[collectorEnvironment][collectorLocation];
}
exports.environmentToUrl = environmentToUrl;
/**
 * This file contains the environments for the collector.
 */
exports.environments = {
  [CollectorEnvironment.ENGINEERING]: {
    [CollectorLocation.PUBLIC]: 'https://gto-ocp.icproxy.sece.c1.vanguard.com/v1/traces',
    [CollectorLocation.PRIVATE]: 'https://otelcollector.gtoe.c1.vanguard.com:55681/v1/traces'
  },
  [CollectorEnvironment.TEST]: {
    [CollectorLocation.PUBLIC]: 'https://gto-ocp.icproxy.sect.c1.vanguard.com/v1/traces',
    [CollectorLocation.PRIVATE]: 'https://otelcollector.gtot.c1.vanguard.com:55681/v1/traces'
  },
  [CollectorEnvironment.PRODUCTION]: {
    [CollectorLocation.PUBLIC]: 'https://gto-ocp.icproxy.secp.c1.vanguard.com/v1/traces',
    [CollectorLocation.PRIVATE]: 'https://otelcollector.gtop.c1.vanguard.com:55681/v1/traces'
  }
};
