"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SimpleSpanProcessorFactory = void 0;
const sdk_trace_base_1 = require("@opentelemetry/sdk-trace-base");
const span_processor_factory_1 = require("./span-processor-factory");
/**
 * SimpleSpanProcessorFactory - a factory for creating a {@link SimpleSpanProcessor}
 */
class SimpleSpanProcessorFactory extends span_processor_factory_1.SpanProcessorFactory {
  constructor() {
    super();
  }
  create(spanExporter) {
    return new sdk_trace_base_1.SimpleSpanProcessor(spanExporter);
  }
}
exports.SimpleSpanProcessorFactory = SimpleSpanProcessorFactory;
