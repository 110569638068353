"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.throwIfPropertyUndefined = exports.throwIfUndefined = void 0;
const coalesce_utility_1 = require("./coalesce.utility");
/**
 * Throws an error if the given value is null or undefined.
 * If the value is not null or undefined, returns the value.
 *
 * @template T - The type of the value.
 * @param {T | undefined} val - The value to check.
 * @param {Error} [err] - The error to throw if the value is null or undefined. If not provided, a default error is thrown.
 * @returns {T} - Returns the value if it is not null and not undefined.
 * @throws {Error} - Throws an error if the value is null or undefined.
 *
 * @example
 * // returns: 5
 * console.log(throwIfUndefined(5));
 *
 * @example
 * // throws: Error('Passed value is undefined')
 * console.log(throwIfUndefined(undefined));
 *
 * @example
 * // throws: Error('Custom error message')
 * console.log(throwIfUndefined(null, new Error('Custom error message')));
 */
function throwIfUndefined(val, err) {
  if ((0, coalesce_utility_1.isNil)(val)) {
    throw err !== null && err !== void 0 ? err : new Error('Passed value is undefined');
  }
  return val;
}
exports.throwIfUndefined = throwIfUndefined;
/**
 * Throws an error if the given object or its specified property is null or undefined.
 * If the object and its property are not null or undefined, returns the object.
 *
 * @template T - The type of the object.
 * @param {T | undefined} val - The object to check.
 * @param {keyof T} prop - The property to check on the object.
 * @param {Error} [err] - The error to throw if the object or its property is null or undefined. If not provided, a default error is thrown.
 * @returns {T} - Returns the object if it and its property are not null and not undefined.
 * @throws {Error} - Throws an error if the object or its property is null or undefined.
 *
 * @example
 * // returns: { name: "John", age: 30 }
 * console.log(throwIfPropertyUndefined({ name: "John", age: 30 }, "age"));
 *
 * @example
 * // throws: Error('Passed value is undefined')
 * console.log(throwIfPropertyUndefined(undefined, "age"));
 *
 * @example
 * // throws: Error('Property on passed value is undefined')
 * console.log(throwIfPropertyUndefined({ name: "John", age: undefined }, "age"));
 *
 * @example
 * // throws: Error('Custom error message')
 * console.log(throwIfPropertyUndefined(null, "age", new Error('Custom error message')));
 */
// eslint-disable-next-line complexity
function throwIfPropertyUndefined(val, prop, err) {
  if ((0, coalesce_utility_1.isNil)(val)) {
    throw err !== null && err !== void 0 ? err : new Error('Passed value is undefined');
  }
  if (val[prop] === undefined) {
    throw err !== null && err !== void 0 ? err : new Error('Property on passed value is undefined');
  }
  return val;
}
exports.throwIfPropertyUndefined = throwIfPropertyUndefined;
