"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpanNotifierSpanProcessorFactory = void 0;
const span_notifier_span_processor_1 = require("../spanprocessor/span-notifier-span-processor");
const span_processor_factory_1 = require("./span-processor-factory");
/**
 * SpanNotifierSpanProcessorFactory - a factory for creating a {@link SpanNotifierSpanProcessor}
 */
class SpanNotifierSpanProcessorFactory extends span_processor_factory_1.SpanProcessorFactory {
  wrappedSpanProcessorFactory;
  spanProcessorListener;
  constructor(wrappedSpanProcessorFactory, spanProcessorListener) {
    super();
    this.wrappedSpanProcessorFactory = wrappedSpanProcessorFactory;
    this.spanProcessorListener = spanProcessorListener;
  }
  create(spanExporter) {
    const spanProcessor = this.wrappedSpanProcessorFactory.create(spanExporter);
    return new span_notifier_span_processor_1.SpanNotifierSpanProcessor(spanProcessor, this.spanProcessorListener);
  }
}
exports.SpanNotifierSpanProcessorFactory = SpanNotifierSpanProcessorFactory;
