import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Route, Router, RouterStateSnapshot } from '@angular/router';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { WindowService } from '@app/etfs-equities/services';
import { environment } from '@env/environment';
import { lastValueFrom } from 'rxjs';

/**
 * A map of route segments to gatekeeper feature IDs.
 */
enum RouteFeatureMap {
  'CANCEL' = 'TWE_Order_Cancel',
  'TRADE' = 'TWE_Equity_ETF_Path_MVP',
  'EDIT_COST_BASIS' = 'TWE_Edit_Cost_Basis',
}

@Injectable({
  providedIn: 'root',
})
class GatekeeperGuard {
  constructor(
    private readonly gatekeeperService: GatekeeperService,
    private readonly router: Router,
    private readonly windowService: WindowService
  ) {}

  public navigateToMainPage(): void {
    this.router.navigate(['/']);
  }

  /**
   * Determines if the given path is part of an active feature in the gatekeeper.
   *
   * @param path The activated route path.
   * @returns A boolean indicating whether or not the given path is
   *          part of a feature that is enabled in the gatekeeper.
   */
  public routeIsEnabled(path: string): Promise<boolean> {
    const featureId = this.getFeatureIdFromPath(path);

    if (!featureId) {
      return Promise.resolve(false);
    }

    return this.featureIsEnabled(featureId);
  }

  public async equityEtfPathEnabled(): Promise<boolean> {
    const equityEtfPathEnabled = await lastValueFrom(
      this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_EQUITY_ETF_PATH)
    );
    const beaconOnlyEnabled = await lastValueFrom(
      this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_BEACON_ONLY_EQUITY_ETF)
    );
    const isBeacon = this.windowService.getIsBeacon();

    if (!equityEtfPathEnabled && (!beaconOnlyEnabled || (beaconOnlyEnabled && !isBeacon))) {
      this.windowService.navigateToExternalLink(environment.legacyTradePath);
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  /**
   * Determine if the given feature ID is enabled.
   *
   * @param featureId The gatekeeper feature ID.
   * @returns A boolean indicating whether or not the given
   *          feature ID is enabled in the gatekeeper.
   */
  private async featureIsEnabled(featureId: string): Promise<boolean> {
    return lastValueFrom(this.gatekeeperService.checkSingleFeatureStatus(featureId));
  }

  /**
   * Determines the gatekeeper ID for a given route path.
   *
   * @param path A route path.
   * @returns The gatekeeper feature ID for the given route path.
   */
  private getFeatureIdFromPath(path: string): string {
    path = path.toUpperCase();

    if (path.indexOf('CANCEL') !== -1) {
      return RouteFeatureMap.CANCEL;
    }

    if (path.includes('EDIT-COST-BASIS')) {
      return RouteFeatureMap.EDIT_COST_BASIS;
    }

    if (path.indexOf('TRADE') !== -1) {
      return RouteFeatureMap.TRADE;
    }

    return undefined;
  }
}

export const gatekeeperGuardCanMatchFn: CanMatchFn = async (route: Route): Promise<boolean> => {
  const gatekeeperGuard = inject(GatekeeperGuard);
  return await gatekeeperGuard.routeIsEnabled(route.path);
};

export const gatekeeperGuardActivateFn: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> => {
  const gatekeeperGuard = inject(GatekeeperGuard);
  const routeIsEnabled = await gatekeeperGuard.routeIsEnabled(state.url);
  const isEquityEtfPathEnabled = await gatekeeperGuard.equityEtfPathEnabled();

  if (!isEquityEtfPathEnabled) return false;

  if (!routeIsEnabled) {
    gatekeeperGuard.navigateToMainPage();
    return false;
  }
  return true;
};
