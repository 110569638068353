"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeOrderAccountInstructionsLegalEntityTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ChangeOrderAccountInstructionsLegalEntityTypeEnum;
(function (ChangeOrderAccountInstructionsLegalEntityTypeEnum) {
  ChangeOrderAccountInstructionsLegalEntityTypeEnum["ADVISORS_INC"] = "VANGUARD_ADVISORS_INC";
  ChangeOrderAccountInstructionsLegalEntityTypeEnum["NATIONAL_TRUST_COMPANY"] = "VANGUARD_NATIONAL_TRUST_COMPANY";
})(ChangeOrderAccountInstructionsLegalEntityTypeEnum = exports.ChangeOrderAccountInstructionsLegalEntityTypeEnum || (exports.ChangeOrderAccountInstructionsLegalEntityTypeEnum = {}));
