"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaggageMapping = exports.BaggageHeaders = exports.BaggageConstants = void 0;
/**
 * Baggage constants - see https://confluence.vanguard.com/display/RETSRECOP/Journey+Tracing+Baggage+Spec
 */
var BaggageConstants;
(function (BaggageConstants) {
  /**
   * Client Poid
   */
  BaggageConstants["VG_CLIENT_POID"] = "vg.client.poid";
  /**
   * the url of the root web app that initiated the baggage propagation sequence
   * e.g. https://funds.web.vanguard.com/
   */
  BaggageConstants["VG_REFERRER"] = "vg.referer";
  /**
   * a session id associated with the root web app that initiated the baggage propagation sequence
   */
  BaggageConstants["VG_REFERRER_SESSION_ID"] = "vg.referer.session.id";
  /**
   * correlation id for request chain
   */
  BaggageConstants["VG_CORRELATION_ID"] = "vg.correlation_id";
  /**
   * the channel
   */
  BaggageConstants["VG_CHANNEL"] = "vg.channel";
  /**
   *
   */
  BaggageConstants["VG_ACID"] = "vg.acid";
  /**
   *
   */
  BaggageConstants["VG_CREW_POID"] = "vg.crew.poid";
  /**
   *
   */
  BaggageConstants["VG_JOURNEY_ID"] = "vg.journey_id";
  /**
   * call chain in the form of APP,APP,APP (e.g. RBH,VGA,TRW,...)
   */
  BaggageConstants["VG_CALL_CHAIN"] = "vg.call_chain";
})(BaggageConstants = exports.BaggageConstants || (exports.BaggageConstants = {}));
var BaggageHeaders;
(function (BaggageHeaders) {
  BaggageHeaders["ch"] = "ch";
  BaggageHeaders["ji"] = "ji";
  BaggageHeaders["cp"] = "cp";
  BaggageHeaders["ci"] = "ci";
  BaggageHeaders["ac"] = "ac";
  BaggageHeaders["cc"] = "cc";
  BaggageHeaders["re"] = "re";
  BaggageHeaders["cl"] = "cl";
  BaggageHeaders["si"] = "si";
})(BaggageHeaders = exports.BaggageHeaders || (exports.BaggageHeaders = {}));
exports.BaggageMapping = {
  [BaggageConstants.VG_CHANNEL]: BaggageHeaders.ch,
  [BaggageConstants.VG_JOURNEY_ID]: BaggageHeaders.ji,
  [BaggageConstants.VG_CREW_POID]: BaggageHeaders.cp,
  [BaggageConstants.VG_CORRELATION_ID]: BaggageHeaders.ci,
  [BaggageConstants.VG_ACID]: BaggageHeaders.ac,
  [BaggageConstants.VG_CALL_CHAIN]: BaggageHeaders.cc,
  [BaggageConstants.VG_REFERRER]: BaggageHeaders.re,
  [BaggageConstants.VG_CLIENT_POID]: BaggageHeaders.cl,
  [BaggageConstants.VG_REFERRER_SESSION_ID]: BaggageHeaders.si
};
