"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TriggeredTradingRuleTransactionLevel = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TriggeredTradingRuleTransactionLevel;
(function (TriggeredTradingRuleTransactionLevel) {
  TriggeredTradingRuleTransactionLevel["ACCOUNT_LEVEL"] = "ACCOUNT_LEVEL";
  TriggeredTradingRuleTransactionLevel["TRANSACTION_DETAIL_LEVEL"] = "TRANSACTION_DETAIL_LEVEL";
  TriggeredTradingRuleTransactionLevel["HOLDING_LEVEL"] = "HOLDING_LEVEL";
  TriggeredTradingRuleTransactionLevel["CLIENT_LEVEL"] = "CLIENT_LEVEL";
})(TriggeredTradingRuleTransactionLevel = exports.TriggeredTradingRuleTransactionLevel || (exports.TriggeredTradingRuleTransactionLevel = {}));
