"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StateWithholdingAmountTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var StateWithholdingAmountTypeEnum;
(function (StateWithholdingAmountTypeEnum) {
  StateWithholdingAmountTypeEnum["ADDITIONAL_DOLLARS"] = "ADDITIONAL_DOLLARS";
  StateWithholdingAmountTypeEnum["ADDITIONAL_PERCENT"] = "ADDITIONAL_PERCENT";
  StateWithholdingAmountTypeEnum["FLAT_DOLLARS"] = "FLAT_DOLLARS";
  StateWithholdingAmountTypeEnum["FLAT_PERCENT"] = "FLAT_PERCENT";
  StateWithholdingAmountTypeEnum["MINIMUM"] = "MINIMUM";
  StateWithholdingAmountTypeEnum["DO_NOT_WITHHOLD"] = "DO_NOT_WITHHOLD";
})(StateWithholdingAmountTypeEnum = exports.StateWithholdingAmountTypeEnum || (exports.StateWithholdingAmountTypeEnum = {}));
