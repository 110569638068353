{
  "name": "@vanguard/invest-api-client-typescript-axios",
  "description": "Client library for the Invest API middlware layer service for retail invest-family labs",
  "version": "2.41.0",
  "main": "dist/index.js",
  "author": {
    "name": "Invest API Devs",
    "email": "InvestAPI@vanguard.com"
  },
  "license": "SEE LICENSE IN LICENSE",
  "homepage": "https://confluence.vanguard.com/display/ITA",
  "bugs": {
    "url": "https://jira.vanguard.com/projects/ITA"
  },
  "repository": {
    "type": "git",
    "url": "https://bitbucket.opst.c1.vanguard.com/scm/o6ojnqaf06epw0s/invest-api.webservice.git"
  },
  "peerDependencies": {
    "axios": ">= 0.26.1 < 2"
  },
  "devDependencies": {
    "@types/axios": "^0.14.0",
    "axios": "1.4.0"
  },
  "dependencies": {
    "node-abort-controller": "3.0.1"
  },
  "publishConfig": {
    "registry": "https://nexus.opst.c1.vanguard.com/repository/npm_internal/",
    "tag": "latest",
    "access": "public",
    "email": "ngsa@vanguard.com",
    "ignore-scripts": "true"
  }
}
