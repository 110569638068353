<ng-container
  *ngIf="{
    sharesHeldForCash: sharesHeldForCash$ | async,
    dollarValueOfSharesHeld: dollarValueOfSharesHeld$ | async,
    account: selectedAccount$ | async,
    accountIsMargin: accountIsMargin$ | async,
    sharesHeldForMargin: sharesHeldForMargin$ | async,
    sharesHeldForShort: sharesHeldForShort$ | async
  } as view"
>
  <ng-container *ngIf="view.sharesHeldForCash > 0 || view.sharesHeldForMargin > 0 || view.sharesHeldForShort < 0">
    <div class="tds-list-group" *ngIf="!view.accountIsMargin">
      <div class="tds-list-group__item flex-wrap">
        <twe-tooltip
          [labelText]="content?.labels.yourShares"
          [tooltipHeader]="content?.guidelet.currentShares.sharesTitle"
        >
          <p>
            {{ content?.guidelet.currentShares.sharesContent }}
          </p>
        </twe-tooltip>
        <div class="tds-list-group__data">{{ view.sharesHeldForCash | number : '1.4-4' }}</div>
      </div>

      <div class="tds-list-group__item flex-wrap">
        <div class="twe-flex-center-items">
          <div class="nowrap d-flex">
            <twe-tooltip
              [labelText]="content?.labels.estimatedValueInDollars"
              [tooltipHeader]="content?.guidelet.estimatedValueInDollars.dollarsTitle"
            >
              <p>
                {{ content?.guidelet.estimatedValueInDollars.dollarsContent }}
              </p>
            </twe-tooltip>
          </div>
        </div>
        <div class="tds-list-group__data"> {{ view.dollarValueOfSharesHeld | tweSubDollarCurrency }}</div>
      </div>
    </div>

    <div class="tds-list-group" *ngIf="view.accountIsMargin">
      <div class="tds-list-group__item flex-wrap" *ngIf="view.sharesHeldForShort < 0">
        <div class="twe-flex-center-items">
          <twe-tooltip
            [labelText]="content?.labels.sharesHeldShort"
            [tooltipHeader]="content?.guidelet.sharesHeldShort.sharesTitle"
          >
            <p>
              {{ content?.guidelet.sharesHeldShort.sharesContent }}
            </p>
          </twe-tooltip>
        </div>

        <div class="tds-list-group__data">{{ view.sharesHeldForShort | number : '1.4-4' }}</div>
      </div>

      <div class="tds-list-group__item flex-wrap" *ngIf="view.sharesHeldForCash > 0">
        <div class="twe-flex-center-items">
          <twe-tooltip
            [labelText]="content?.labels.sharesOwnedInCash"
            [tooltipHeader]="content?.guidelet.sharesOwnedInCash.sharesTitle"
          >
            <p>
              {{ content?.guidelet.sharesOwnedInCash.sharesContent }}
            </p>
          </twe-tooltip>
        </div>

        <div class="tds-list-group__data">{{ view.sharesHeldForCash | number : '1.4-4' }}</div>
      </div>

      <div class="tds-list-group__item flex-wrap" *ngIf="view.sharesHeldForMargin > 0">
        <div class="twe-flex-center-items">
          <twe-tooltip
            [labelText]="content?.labels.sharesOwnedInMargin"
            [tooltipHeader]="content?.guidelet.sharesOwnedInMargin.sharesTitle"
          >
            <p>
              {{ content?.guidelet.sharesOwnedInMargin.sharesContent }}
            </p>
          </twe-tooltip>
        </div>
        <div class="tds-list-group__data">{{ view.sharesHeldForMargin | number : '1.4-4' }}</div>
      </div>
    </div>

    <div class="mt-2" *ngIf="view.account?.asOfDate">
      <tcx-timestamp
        [showTime]="true"
        [prefix]="content?.labels.asOf"
        [size]="timestampSizeEnum.XS"
        [date]="view.account?.asOfDate | tweDate"
      ></tcx-timestamp>
    </div>
  </ng-container>
</ng-container>
