<div class="c11n-space-stack-5x c11n-space--force">
  <ng-container *ngIf="selectedAccount">
    <div *ngIf="!orderRetrievalFailed">
      <div *ngIf="!openOrdersExtended?.length && !isLoading" class="container-fluid open-orders-unavailable">
        <twe-service-unavailable-msg>
          <p class="twe-c11n-color-ink-400 c11n-space-stack-1x" data-testid="noOpenOrdersFoundInfo">{{
            content.openOrders.noOpenOrdersFound
          }}</p>
          <p>
            <a c11n-link [routerLink]="TRADE_PATH">{{ content.backToTrading }}</a>
          </p>
        </twe-service-unavailable-msg>
      </div>
      <ul
        class="twe-unstyled-list"
        [attr.aria-label]="content.openOrders?.openOrders"
        data-testid="list-open-order-cards"
      >
        <li *ngFor="let order of openOrdersExtended" class="c11n-space-stack-4x">
          <div class="visually-hidden">
            {{ order }}
            {{ content.openOrders.orderForSymbol }}
            {{ order.ticker }}
            {{ order.securityDescription }}
            {{ content.openOrders.orderDate + ' ' + (order.enteredTimeStamp | date : 'MM/dd/yyyy') }}.
            {{ content.openOrders.navigationInstructions }}
          </div>

          <div class="twe-card" [attr.data-testid]="'open-order-card-' + order.ticker">
            <twe-card-cap
              isShortCap="true"
              [backgroundColor]="$any(order.orderColor)"
              [leftContent]="order.orderStatusDisplayText"
              [rightContent]="content.openOrders.orderDate + ' ' + (order.enteredTimeStamp | date : 'MM/dd/yyyy')"
            ></twe-card-cap>

            <div class="tds-order-table order-table-with-action-btns">
              <div class="order-table-body">
                <div class="tds-order-table__row">
                  <div
                    class="text-truncate tds-order-table__cell tds-order-table__title tds-order-table__data c11n-text-xl-headline"
                    data-testid="open-order-card-data"
                  >
                    <span *ngIf="order.ticker" class="visually-hidden">{{ content.labels.symbol }}</span>
                    {{ order.ticker }}
                  </div>

                  <div
                    class="text-truncate tds-order-table__cell tds-order-table__title tds-order-table__data twe-text-bold c11n-text-sm"
                    data-testid="open-order-card-data"
                  >
                    <span *ngIf="order.securityDescription" class="visually-hidden">{{
                      content.openOrders.securityName
                    }}</span>
                    {{ order.securityDescription }}
                  </div>
                </div>

                <div class="tds-order-table__end">
                  <div class="tds-order-table__cell">
                    <div
                      class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                      data-testid="open-order-card-label"
                    >
                      {{ content.labels.transaction }}
                    </div>

                    <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
                      {{ orderInstructionCodeLabel[order.orderInstructionCode] }}
                    </div>

                    <div
                      *ngIf="isMarginAccount"
                      class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                    >
                      {{ order.securityAccountType }}
                    </div>
                  </div>

                  <div
                    *ngIf="
                      order.amountIndicator === amountIndicators.DOLLARS &&
                      order.securityType === securityClassTypes.VANGUARD_ETF.toString()
                    "
                    class="tds-order-table__cell"
                  >
                    <div
                      class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                      data-testid="open-order-card-label"
                    >
                      {{ content.labels.amount }}
                    </div>

                    <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
                      {{ order.orderValue | tweSubDollarCurrency }}
                    </div>
                  </div>

                  <div class="tds-order-table__cell">
                    <div
                      class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                      data-testid="open-order-card-label"
                    >
                      {{
                        securityIsEquity.includes(order.securityType) ||
                        (order.orderSecurityType.includes('MUTUAL_FUND') && order.amountIndicator !== 'DOLS')
                          ? content.labels.shares
                          : content.labels.dollars
                      }}
                    </div>

                    <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
                      {{ showNumberOfSharesOrDollars(order) }}
                    </div>

                    <div
                      *ngIf="order.allOrNone == '1'"
                      class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                      data-testid="open-order-card-label"
                    >
                      {{ content.labels.allOrNone }}
                    </div>

                    <div
                      *ngIf="order.statusDetail == statusDetailEnum.PARTIAL_EXECUTION"
                      class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                      data-testid="open-order-card-label"
                    >
                      {{ content.labels.executed }}
                    </div>
                  </div>

                  <!-- Desktop Breakpoint Cell -->
                  <div
                    *ngIf="order.statusDetail == statusDetailEnum.PARTIAL_EXECUTION"
                    class="tds-order-table__cell tds-hidden tds-sd-block"
                  >
                    <ng-container *ngTemplateOutlet="optionalOpenSharesCellTemplate; context: { $implicit: order }">
                    </ng-container>
                  </div>

                  <!-- Mobile Breakpoint Cell-->
                  <div
                    *ngIf="order.amountIndicator !== amountIndicators.DOLLARS"
                    class="tds-order-table__cell tds-sd-none"
                  >
                    <div *ngIf="order.statusDetail == statusDetailEnum.PARTIAL_EXECUTION">
                      <ng-container *ngTemplateOutlet="optionalOpenSharesCellTemplate; context: { $implicit: order }">
                      </ng-container>
                    </div>
                  </div>

                  <ng-container *ngIf="securityIsEquity.includes(order.securityType)">
                    <div class="tds-order-table__cell">
                      <div
                        class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                        data-testid="open-order-card-label"
                      >
                        {{ content.labels.orderType }}
                      </div>

                      <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
                        {{ orderCategoryLabel[order.orderCategory] }}
                      </div>
                    </div>

                    <div class="tds-order-table__cell">
                      <div
                        class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                        data-testid="open-order-card-label"
                      >
                        {{
                          order.orderCategory === orderCategoryEnum.STOP ||
                          order.orderCategory === orderCategoryEnum.STOP_LIMIT
                            ? content.labels.stop
                            : order.orderCategory === orderCategoryEnum.LIMIT
                            ? content.labels.limit
                            : ''
                        }}
                        {{ content.labels.price }}
                      </div>

                      <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
                        {{
                          order.orderCategory === orderCategoryEnum.MARKET &&
                          order.statusDetail !== statusDetailEnum.EXECUTED
                            ? ' - '
                            : order.orderCategory === orderCategoryEnum.MARKET
                            ? (order.executedPrice | tweSubDollarCurrency)
                            : (order.price | tweSubDollarCurrency)
                        }}
                      </div>
                    </div>

                    <!-- Desktop Breakpoint Cell -->
                    <div
                      *ngIf="order.orderCategory === orderCategoryEnum.STOP_LIMIT"
                      class="tds-order-table__cell tds-hidden tds-sd-block"
                    >
                      <ng-container *ngTemplateOutlet="optionalLimitPriceCellTemplate; context: { $implicit: order }">
                      </ng-container>
                    </div>

                    <!-- Mobile Breakpoint Cell -->
                    <div class="tds-order-table__cell tds-sd-none">
                      <div *ngIf="order.orderCategory === orderCategoryEnum.STOP_LIMIT">
                        <ng-container *ngTemplateOutlet="optionalLimitPriceCellTemplate; context: { $implicit: order }">
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>

                  <div class="tds-order-table__cell cost-basis__cell" *ngIf="order.costBasisMethod">
                    <div
                      class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                      data-testid="open-order-card-label"
                    >
                      {{ content.labels.costBasis }}
                    </div>

                    <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
                      {{ order.costBasisMethod || '-' }}
                    </div>
                  </div>

                  <div
                    class="tds-order-table__cell"
                    *ngIf="
                      !order.orderSecurityType.includes('MUTUAL_FUND') || (order.securityType && order.orderDuration)
                    "
                  >
                    <div
                      class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                      data-testid="open-order-card-label"
                    >
                      {{ content.labels.duration }}
                    </div>

                    <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
                      {{ order.orderDuration | tweOpenOrderDuration }}
                    </div>

                    <div
                      *ngIf="order.orderDuration === 'GTC'"
                      class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
                      data-testid="open-order-card-data"
                    >
                      {{ content.labels.expires }} {{ order.settlementDate | date : 'MM/dd/yyyy' }}
                    </div>
                  </div>
                </div>
              </div>

              <ng-container
                *ngIf="{
                  orderCancelEnabled: (orderCancelEnabled$ | async),
                  changeOrderEnabled: (changeOrderEnabled$ | async),
                  isEditCostBasisToggleEnabled: (isEditCostBasisToggleEnabled$ | async),
                  isCostBasisEligible: (isCostBasisEligible$ | async),
                  extendedTradingEnabled: (extendedTradingEnabled$ | async)
                } as view"
              >
                <div
                  class="order-table-action-btns c11n-text-md"
                  *ngIf="
                    showChangerOrderButton(order, view.changeOrderEnabled, view.extendedTradingEnabled) ||
                    showCancelOrderButton(order, view.orderCancelEnabled) ||
                    showEditCostBasisButton(order, view.isEditCostBasisToggleEnabled, view.isCostBasisEligible)
                  "
                  [ngClass]="{
                    'd-none': !view.changeOrderEnabled && order.statusDetail === statusDetailEnum.PENDING_CHANGE,
                    'min-width-230': showEditCostBasisButton(
                      order,
                      view.isEditCostBasisToggleEnabled,
                      view.isCostBasisEligible
                    )
                  }"
                >
                  <button
                    c11n-link
                    *ngIf="
                      showChangerOrderButton(order, view.changeOrderEnabled, view.extendedTradingEnabled) &&
                      securityIsEquity.includes(order.securityType)
                    "
                    class="order-table-action-btns__button"
                    iconName="edit"
                    variant="secondary-independent"
                    data-testid="changeOrderButton"
                    [ngClass]="{ 'mb-0': view.changeOrderEnabled && !view.orderCancelEnabled }"
                    (click)="
                      adobeService.sendAdobeTrackingOnClick('Change order'); handleActionBtnClick(order, 'change')
                    "
                    attr.aria-label="{{
                      content.openOrders.changeOrderFrom +
                        ' ' +
                        orderInstructionCodeLabel[order.orderInstructionCode] +
                        ' ' +
                        showNumberOfSharesOrDollars(order) +
                        content.openOrders.sharesOf +
                        order.ticker +
                        ', ' +
                        order.securityDescription +
                        ', ' +
                        content.openOrders.orderDate +
                        ' ' +
                        (order.enteredTimeStamp | date : 'MM/dd/yyyy')
                    }}"
                  >
                    {{ content.labels.changeOrder }}
                  </button>
                  <button
                    c11n-link
                    class="order-table-action-btns__button twe-text-bold"
                    iconName="delete"
                    variant="secondary-independent"
                    *ngIf="
                      showCancelOrderButton(order, view.orderCancelEnabled) &&
                      securityIsEquity.includes(order.securityType)
                    "
                    (click)="
                      adobeService.sendAdobeTrackingOnClick('Cancel order'); handleActionBtnClick(order, 'cancel')
                    "
                    attr.aria-label="{{
                      content.openOrders.cancelOrderTo +
                        ' ' +
                        orderInstructionCodeLabel[order.orderInstructionCode] +
                        ' ' +
                        showNumberOfSharesOrDollars(order) +
                        (order.amountIndicator === amountIndicators.SHARES
                          ? content.openOrders.sharesOf
                          : content.openOrders.dollarsOf) +
                        order.ticker +
                        ', ' +
                        order.securityDescription +
                        ', ' +
                        content.openOrders.orderDate +
                        ' ' +
                        (order.enteredTimeStamp | date : 'MM/dd/yyyy')
                    }}"
                  >
                    {{ content.labels.cancelOrder }}
                  </button>

                  <button
                    c11n-link
                    class="order-table-action-btns__button"
                    iconName="edit"
                    variant="secondary-independent"
                    *ngIf="showEditCostBasisButton(order, view.isEditCostBasisToggleEnabled, view.isCostBasisEligible)"
                    (click)="
                      adobeService.sendAdobeTrackingOnClick('Edit cost basis method order');
                      handleActionBtnClick(order, 'editCostBasisMethod')
                    "
                    attr.aria-label="{{
                      content.openOrders.editCostBasisMethodOrderTo +
                        ' ' +
                        orderInstructionCodeLabel[order.orderInstructionCode] +
                        ' ' +
                        showNumberOfSharesOrDollars(order) +
                        (order.amountIndicator === amountIndicators.SHARES
                          ? content.openOrders.sharesOf
                          : content.openOrders.dollarsOf) +
                        order.ticker +
                        ', ' +
                        order.securityDescription +
                        ', ' +
                        content.openOrders.orderDate +
                        ' ' +
                        (order.enteredTimeStamp | date : 'MM/dd/yyyy')
                    }}"
                  >
                    {{ content.editCostBasis.title }}
                  </button>

                  <span
                    class="c11n-text-md tds-ml-3"
                    *ngIf="
                      !securityIsEquity.includes(order.securityType) &&
                      (showCancelOrderButton(order, view.orderCancelEnabled) ||
                        showChangerOrderButton(order, view.changeOrderEnabled, view.extendedTradingEnabled))
                    "
                  >
                    {{ content.openOrders.nonEquityText1 }} {{ getNonEquitySecurityTypeText(order) }}
                    {{ content.openOrders.nonEquityText2 }}
                    <a c11n-link variant="primary-reinforced" [href]="orderStatusLink">{{
                      content.openOrders.orderStatusLinkText
                    }}</a>
                  </span>
                </div>
              </ng-container>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </ng-container>
</div>

<ng-template #optionalOpenSharesCellTemplate let-order>
  <div class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"> &nbsp; </div>

  <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
    {{ order.remainingQuantity | number : '1.4-4' }}
  </div>

  <div class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs" data-testid="open-order-card-label">
    {{ content.labels.open }}
  </div>
</ng-template>

<ng-template #optionalLimitPriceCellTemplate let-order>
  <div class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs" data-testid="open-order-card-label">
    {{ content.labels.limit + ' ' + content.labels.price }}
  </div>

  <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
    {{ order.stopLimitPrice | tweSubDollarCurrency }}
  </div>
</ng-template>
