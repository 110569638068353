import { Pipe, PipeTransform } from '@angular/core';
import content from '@content/content.json';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-17';

@Pipe({
  name: 'tweCostBasisExpand',
})
export class CostBasisExpandPipe implements PipeTransform {
  transform(value: any): any {
    switch (value) {
      case CostBasisMethod.FIFO:
        return content.labels.fifoExpanded;
      case CostBasisMethod.HIFO:
        return content.labels.hifoExpanded;
      case CostBasisMethod.SPEC_ID:
        return content.labels.specIdExpanded;
      case CostBasisMethod.MIN_TAX:
        return content.labels.minTaxExpanded;
      case CostBasisMethod.AVG_COST:
        return content.labels.avgCostExpanded;
      default:
        return value;
    }
  }
}
