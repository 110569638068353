"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BrokerageAccountOptionPermissionEnum = exports.BrokerageAccountBrokerageProviderTypeCodeEnum = exports.BrokerageAccountMarginApprovalCodeEnum = exports.BrokerageAccountMarginCodeEnum = exports.BrokerageAccountAccountTypeEnum = exports.BrokerageAccountRetirementPlanTypeCodeEnum = exports.BrokerageAccountPendingManagementTypeEnum = exports.BrokerageAccountProductTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var BrokerageAccountProductTypeEnum;
(function (BrokerageAccountProductTypeEnum) {
  BrokerageAccountProductTypeEnum["MUTUAL_FUND"] = "MUTUAL_FUND";
  BrokerageAccountProductTypeEnum["MUTUAL_FUND_RETIREMENT"] = "MUTUAL_FUND_RETIREMENT";
  BrokerageAccountProductTypeEnum["BROKERAGE"] = "BROKERAGE";
  BrokerageAccountProductTypeEnum["LEGACY_BROKERAGE"] = "LEGACY_BROKERAGE";
  BrokerageAccountProductTypeEnum["VANGUARD_529"] = "VANGUARD_529";
  BrokerageAccountProductTypeEnum["RETIREMENT_403_B"] = "RETIREMENT_403B";
  BrokerageAccountProductTypeEnum["OUTSIDE_MANUAL_INVESTMENTS"] = "OUTSIDE_MANUAL_INVESTMENTS";
  BrokerageAccountProductTypeEnum["OUTSIDE_AUTOMATIC_INVESTMENTS"] = "OUTSIDE_AUTOMATIC_INVESTMENTS";
  BrokerageAccountProductTypeEnum["PARTICIPANT"] = "PARTICIPANT";
  BrokerageAccountProductTypeEnum["SMALL_MARKET_401_K"] = "SMALL_MARKET_401K";
})(BrokerageAccountProductTypeEnum = exports.BrokerageAccountProductTypeEnum || (exports.BrokerageAccountProductTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var BrokerageAccountPendingManagementTypeEnum;
(function (BrokerageAccountPendingManagementTypeEnum) {
  BrokerageAccountPendingManagementTypeEnum["OFFBOARDED"] = "OFFBOARDED";
  BrokerageAccountPendingManagementTypeEnum["SELF_MANAGED"] = "SELF_MANAGED";
  BrokerageAccountPendingManagementTypeEnum["HYPOTHETICALLY_MANAGED"] = "HYPOTHETICALLY_MANAGED";
  BrokerageAccountPendingManagementTypeEnum["PRE_MANAGED"] = "PRE_MANAGED";
})(BrokerageAccountPendingManagementTypeEnum = exports.BrokerageAccountPendingManagementTypeEnum || (exports.BrokerageAccountPendingManagementTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var BrokerageAccountRetirementPlanTypeCodeEnum;
(function (BrokerageAccountRetirementPlanTypeCodeEnum) {
  BrokerageAccountRetirementPlanTypeCodeEnum["TYPE_403_B7_0015"] = "TYPE_403B7_0015";
  BrokerageAccountRetirementPlanTypeCodeEnum["TYPE_529_PLAN_0100"] = "TYPE_529_PLAN_0100";
  BrokerageAccountRetirementPlanTypeCodeEnum["EDUCATION_IRA_0021"] = "EDUCATION_IRA_0021";
  BrokerageAccountRetirementPlanTypeCodeEnum["INDIVIDUAL_0000"] = "INDIVIDUAL_0000";
  BrokerageAccountRetirementPlanTypeCodeEnum["IRA_BENEFICIARY_0016"] = "IRA_BENEFICIARY_0016";
  BrokerageAccountRetirementPlanTypeCodeEnum["IRA_ROLLOVER_0002"] = "IRA_ROLLOVER_0002";
  BrokerageAccountRetirementPlanTypeCodeEnum["KEOGH_0004"] = "KEOGH_0004";
  BrokerageAccountRetirementPlanTypeCodeEnum["MONEY_PURCHASE_0014"] = "MONEY_PURCHASE_0014";
  BrokerageAccountRetirementPlanTypeCodeEnum["MP_CONTRIB_0005"] = "MP_CONTRIB_0005";
  BrokerageAccountRetirementPlanTypeCodeEnum["MP_DEDUCTIBLE_VOL_0007"] = "MP_DEDUCTIBLE_VOL_0007";
  BrokerageAccountRetirementPlanTypeCodeEnum["MP_NON_DEDUCTIBLE_0006"] = "MP_NON_DEDUCTIBLE_0006";
  BrokerageAccountRetirementPlanTypeCodeEnum["MP_ROLLOVER_0008"] = "MP_ROLLOVER_0008";
  BrokerageAccountRetirementPlanTypeCodeEnum["PROFIT_SHARING_0013"] = "PROFIT_SHARING_0013";
  BrokerageAccountRetirementPlanTypeCodeEnum["PS_CONTRIB_0009"] = "PS_CONTRIB_0009";
  BrokerageAccountRetirementPlanTypeCodeEnum["PS_DEDUCTIBLE_VOL_0011"] = "PS_DEDUCTIBLE_VOL_0011";
  BrokerageAccountRetirementPlanTypeCodeEnum["PS_NON_DEDUCTIBLE_0010"] = "PS_NON_DEDUCTIBLE_0010";
  BrokerageAccountRetirementPlanTypeCodeEnum["PS_ROLLOVER_0012"] = "PS_ROLLOVER_0012";
  BrokerageAccountRetirementPlanTypeCodeEnum["RETIREMENT_PLANS_0099"] = "RETIREMENT_PLANS_0099";
  BrokerageAccountRetirementPlanTypeCodeEnum["ROTH_IRA_0017"] = "ROTH_IRA_0017";
  BrokerageAccountRetirementPlanTypeCodeEnum["ROTH_IRA_0020"] = "ROTH_IRA_0020";
  BrokerageAccountRetirementPlanTypeCodeEnum["ROTH_IRA_INHERITED_0019"] = "ROTH_IRA_INHERITED_0019";
  BrokerageAccountRetirementPlanTypeCodeEnum["SEP_IRA_0003"] = "SEP_IRA_0003";
  BrokerageAccountRetirementPlanTypeCodeEnum["SIMPLE_IRA_0018"] = "SIMPLE_IRA_0018";
  BrokerageAccountRetirementPlanTypeCodeEnum["TRADITIONAL_IRA_0001"] = "TRADITIONAL_IRA_0001";
  BrokerageAccountRetirementPlanTypeCodeEnum["VFTC_INDIVIDUAL_401_K_0022"] = "VFTC_INDIVIDUAL_401K_0022";
  BrokerageAccountRetirementPlanTypeCodeEnum["VFTC_INDIVIDUAL_ROTH_401_K_0023"] = "VFTC_INDIVIDUAL_ROTH_401K_0023";
  BrokerageAccountRetirementPlanTypeCodeEnum["UNKNOWN"] = "UNKNOWN";
})(BrokerageAccountRetirementPlanTypeCodeEnum = exports.BrokerageAccountRetirementPlanTypeCodeEnum || (exports.BrokerageAccountRetirementPlanTypeCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var BrokerageAccountAccountTypeEnum;
(function (BrokerageAccountAccountTypeEnum) {
  BrokerageAccountAccountTypeEnum["INDIVIDUAL"] = "INDIVIDUAL";
  BrokerageAccountAccountTypeEnum["JOINT"] = "JOINT";
  BrokerageAccountAccountTypeEnum["JOINT_COMMUNITY_PROPERTY"] = "JOINT_COMMUNITY_PROPERTY";
  BrokerageAccountAccountTypeEnum["JOINT_COMMUNITY_PROPERTY_WROS"] = "JOINT_COMMUNITY_PROPERTY_WROS";
  BrokerageAccountAccountTypeEnum["JOINT_MARITAL_PROPERTY"] = "JOINT_MARITAL_PROPERTY";
  BrokerageAccountAccountTypeEnum["JOINT_MARITAL_PROPERTY_WROS"] = "JOINT_MARITAL_PROPERTY_WROS";
  BrokerageAccountAccountTypeEnum["JOINT_TENANTS_BY_ENTIRETY_WROS"] = "JOINT_TENANTS_BY_ENTIRETY_WROS";
  BrokerageAccountAccountTypeEnum["JOINT_TENANTS_IN_COMMON"] = "JOINT_TENANTS_IN_COMMON";
  BrokerageAccountAccountTypeEnum["JOINT_TENANTS_WROS"] = "JOINT_TENANTS_WROS";
  BrokerageAccountAccountTypeEnum["JOINT_TENANTS_ENTIRETY_WROS"] = "JOINT_TENANTS_ENTIRETY_WROS";
  BrokerageAccountAccountTypeEnum["TRUST"] = "TRUST";
  BrokerageAccountAccountTypeEnum["UNDER_WILL_TRUST"] = "UNDER_WILL_TRUST";
  BrokerageAccountAccountTypeEnum["IIG_TRUST"] = "IIG_TRUST";
  BrokerageAccountAccountTypeEnum["BANKRUPTCY_TRUST"] = "BANKRUPTCY_TRUST";
  BrokerageAccountAccountTypeEnum["LIVING_TRUST"] = "LIVING_TRUST";
  BrokerageAccountAccountTypeEnum["GRANTOR_TRUST"] = "GRANTOR_TRUST";
  BrokerageAccountAccountTypeEnum["ROLLOVER_IRA"] = "ROLLOVER_IRA";
  BrokerageAccountAccountTypeEnum["TRADITIONAL_IRA"] = "TRADITIONAL_IRA";
  BrokerageAccountAccountTypeEnum["SIMPLE_IRA"] = "SIMPLE_IRA";
  BrokerageAccountAccountTypeEnum["SEP_IRA"] = "SEP_IRA";
  BrokerageAccountAccountTypeEnum["SEP_IRA_SINGLE_PARTICIPANT"] = "SEP_IRA_SINGLE_PARTICIPANT";
  BrokerageAccountAccountTypeEnum["SEP_IRA_MULTIPLE_PARTICIPANT"] = "SEP_IRA_MULTIPLE_PARTICIPANT";
  BrokerageAccountAccountTypeEnum["ROTH_IRA"] = "ROTH_IRA";
  BrokerageAccountAccountTypeEnum["INHERITED_IRA"] = "INHERITED_IRA";
  BrokerageAccountAccountTypeEnum["INHERITED_ROTH_IRA"] = "INHERITED_ROTH_IRA";
  BrokerageAccountAccountTypeEnum["CONVERSION_ROTH_IRA"] = "CONVERSION_ROTH_IRA";
  BrokerageAccountAccountTypeEnum["UGMA"] = "UGMA";
  BrokerageAccountAccountTypeEnum["UTMA"] = "UTMA";
  BrokerageAccountAccountTypeEnum["KEOGH"] = "KEOGH";
  BrokerageAccountAccountTypeEnum["ROLLOVER_MPPP"] = "ROLLOVER_MPPP";
  BrokerageAccountAccountTypeEnum["AMENDED_MPPP"] = "AMENDED_MPPP";
  BrokerageAccountAccountTypeEnum["CONTRIB_PSP"] = "CONTRIB_PSP";
  BrokerageAccountAccountTypeEnum["NON_DEDUCTIBLE_PSP"] = "NON_DEDUCTIBLE_PSP";
  BrokerageAccountAccountTypeEnum["DEDUCTIBLE_MPPP"] = "DEDUCTIBLE_MPPP";
  BrokerageAccountAccountTypeEnum["CONTRIB_MPPP"] = "CONTRIB_MPPP";
  BrokerageAccountAccountTypeEnum["NON_DEDUCTIBLE_MPPP"] = "NON_DEDUCTIBLE_MPPP";
  BrokerageAccountAccountTypeEnum["DEDUCTIBLE_PSP"] = "DEDUCTIBLE_PSP";
  BrokerageAccountAccountTypeEnum["ROLLOVER_PSP"] = "ROLLOVER_PSP";
  BrokerageAccountAccountTypeEnum["AMENDED_PSP"] = "AMENDED_PSP";
  BrokerageAccountAccountTypeEnum["NON_ERISA_403_B7"] = "NON_ERISA_403B7";
  BrokerageAccountAccountTypeEnum["ERISA_403_B7"] = "ERISA_403B7";
  BrokerageAccountAccountTypeEnum["EDUCATION_SAVINGS"] = "EDUCATION_SAVINGS";
  BrokerageAccountAccountTypeEnum["INDIVIDUAL_401_K"] = "INDIVIDUAL_401K";
  BrokerageAccountAccountTypeEnum["INDIVIDUAL_401_K_SUSPENSE"] = "INDIVIDUAL_401K_SUSPENSE";
  BrokerageAccountAccountTypeEnum["ROTH_INDIVIDUAL_401_K"] = "ROTH_INDIVIDUAL_401K";
  BrokerageAccountAccountTypeEnum["SMALL_MARKET_401_K"] = "SMALL_MARKET_401K";
  BrokerageAccountAccountTypeEnum["AMENDED_VRP"] = "AMENDED_VRP";
  BrokerageAccountAccountTypeEnum["GUST_AMENDED_VRP"] = "GUST_AMENDED_VRP";
  BrokerageAccountAccountTypeEnum["POOLED_VRIP"] = "POOLED_VRIP";
  BrokerageAccountAccountTypeEnum["VRIP"] = "VRIP";
  BrokerageAccountAccountTypeEnum["VANGUARD_BUSINESS_ACCOUNT_SERVICE"] = "VANGUARD_BUSINESS_ACCOUNT_SERVICE";
  BrokerageAccountAccountTypeEnum["FIDUCIARY"] = "FIDUCIARY";
  BrokerageAccountAccountTypeEnum["CUSTODIAN"] = "CUSTODIAN";
  BrokerageAccountAccountTypeEnum["CUSTODIAN_MINOR"] = "CUSTODIAN_MINOR";
  BrokerageAccountAccountTypeEnum["ATTORNEY_IN_FACT"] = "ATTORNEY_IN_FACT";
  BrokerageAccountAccountTypeEnum["USUFRUCT"] = "USUFRUCT";
  BrokerageAccountAccountTypeEnum["GUARDIAN"] = "GUARDIAN";
  BrokerageAccountAccountTypeEnum["ESCROW_AGENT"] = "ESCROW_AGENT";
  BrokerageAccountAccountTypeEnum["HOSPITAL"] = "HOSPITAL";
  BrokerageAccountAccountTypeEnum["SCHOOL_DISTRICT"] = "SCHOOL_DISTRICT";
  BrokerageAccountAccountTypeEnum["ENDOWMENT"] = "ENDOWMENT";
  BrokerageAccountAccountTypeEnum["DEFINED_BENEFIT_PENSION_PLAN"] = "DEFINED_BENEFIT_PENSION_PLAN";
  BrokerageAccountAccountTypeEnum["ESTATE"] = "ESTATE";
  BrokerageAccountAccountTypeEnum["EXECUTOR_ESTATE"] = "EXECUTOR_ESTATE";
  BrokerageAccountAccountTypeEnum["LIFE_TENANT_ESTATE"] = "LIFE_TENANT_ESTATE";
  BrokerageAccountAccountTypeEnum["BANK_NOMINEE"] = "BANK_NOMINEE";
  BrokerageAccountAccountTypeEnum["PARTNERSHIP"] = "PARTNERSHIP";
  BrokerageAccountAccountTypeEnum["IIG_CORPORATION"] = "IIG_CORPORATION";
  BrokerageAccountAccountTypeEnum["CORPORATION"] = "CORPORATION";
  BrokerageAccountAccountTypeEnum["CORPORATION_BANKRUPTCY"] = "CORPORATION_BANKRUPTCY";
  BrokerageAccountAccountTypeEnum["EXEMPT_UNINCORPORATED_ORGANIZATION"] = "EXEMPT_UNINCORPORATED_ORGANIZATION";
  BrokerageAccountAccountTypeEnum["NONEXEMPT_UNINCORPORATED_ORGANIZATION"] = "NONEXEMPT_UNINCORPORATED_ORGANIZATION";
  BrokerageAccountAccountTypeEnum["FOUNDATION"] = "FOUNDATION";
  BrokerageAccountAccountTypeEnum["NONEXEMPT_UNINCOPORATED_ORGANIZATOIN"] = "NONEXEMPT_UNINCOPORATED_ORGANIZATOIN";
  BrokerageAccountAccountTypeEnum["BROKER_DEALER"] = "BROKER_DEALER";
  BrokerageAccountAccountTypeEnum["PROFESSIONAL_CORPORATION_ASSOCIATION"] = "PROFESSIONAL_CORPORATION_ASSOCIATION";
  BrokerageAccountAccountTypeEnum["PROFESSIONAL_CORPORATION"] = "PROFESSIONAL_CORPORATION";
  BrokerageAccountAccountTypeEnum["PROFESSIONAL_ASSOCIATION"] = "PROFESSIONAL_ASSOCIATION";
  BrokerageAccountAccountTypeEnum["SOLE_PROPRIETORSHIP"] = "SOLE_PROPRIETORSHIP";
  BrokerageAccountAccountTypeEnum["RETIREMENT_TRUST_PSP"] = "RETIREMENT_TRUST_PSP";
  BrokerageAccountAccountTypeEnum["RETIREMENT_TRUST_401_K"] = "RETIREMENT_TRUST_401K";
  BrokerageAccountAccountTypeEnum["RETIREMENT_TRUST_403_B7"] = "RETIREMENT_TRUST_403B7";
  BrokerageAccountAccountTypeEnum["RETIREMENT_TRUST_457"] = "RETIREMENT_TRUST_457";
  BrokerageAccountAccountTypeEnum["RETIREMENT_TRUST_PENSION_PLAN"] = "RETIREMENT_TRUST_PENSION_PLAN";
  BrokerageAccountAccountTypeEnum["RETIREMENT_TRUST_MPPP"] = "RETIREMENT_TRUST_MPPP";
  BrokerageAccountAccountTypeEnum["RETIREMENT_TRUST_401_K_PSP"] = "RETIREMENT_TRUST_401K_PSP";
  BrokerageAccountAccountTypeEnum["RETIREMENT_TRUST_DEFERRED_COMPENSATION"] = "RETIREMENT_TRUST_DEFERRED_COMPENSATION";
  BrokerageAccountAccountTypeEnum["AUDIT_97"] = "AUDIT_97";
  BrokerageAccountAccountTypeEnum["AUDIT_98"] = "AUDIT_98";
  BrokerageAccountAccountTypeEnum["PARTICIPANT"] = "PARTICIPANT";
  BrokerageAccountAccountTypeEnum["OTHER"] = "OTHER";
  BrokerageAccountAccountTypeEnum["SUNGARD"] = "SUNGARD";
  BrokerageAccountAccountTypeEnum["OUTSIDE_HOLDINGS"] = "OUTSIDE_HOLDINGS";
  BrokerageAccountAccountTypeEnum["OUTSIDE_INVESTMENT"] = "OUTSIDE_INVESTMENT";
  BrokerageAccountAccountTypeEnum["RETIREMENT_403_B_PLAN"] = "RETIREMENT_403B_PLAN";
  BrokerageAccountAccountTypeEnum["VANGUARD_529_PLAN"] = "VANGUARD_529_PLAN";
  BrokerageAccountAccountTypeEnum["LIMITED_PARTNER"] = "LIMITED_PARTNER";
  BrokerageAccountAccountTypeEnum["GENERAL_PARTNER"] = "GENERAL_PARTNER";
  BrokerageAccountAccountTypeEnum["UNKNOWN"] = "UNKNOWN";
})(BrokerageAccountAccountTypeEnum = exports.BrokerageAccountAccountTypeEnum || (exports.BrokerageAccountAccountTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var BrokerageAccountMarginCodeEnum;
(function (BrokerageAccountMarginCodeEnum) {
  BrokerageAccountMarginCodeEnum["CASH"] = "CASH";
  BrokerageAccountMarginCodeEnum["MARGIN"] = "MARGIN";
})(BrokerageAccountMarginCodeEnum = exports.BrokerageAccountMarginCodeEnum || (exports.BrokerageAccountMarginCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var BrokerageAccountMarginApprovalCodeEnum;
(function (BrokerageAccountMarginApprovalCodeEnum) {
  BrokerageAccountMarginApprovalCodeEnum["REQUESTED_PAPERS"] = "REQUESTED_PAPERS";
  BrokerageAccountMarginApprovalCodeEnum["NO_PAPER"] = "NO_PAPER";
  BrokerageAccountMarginApprovalCodeEnum["MARGIN_AND_LOAN"] = "MARGIN_AND_LOAN";
  BrokerageAccountMarginApprovalCodeEnum["MARGIN_ONLY"] = "MARGIN_ONLY";
  BrokerageAccountMarginApprovalCodeEnum["LOAN_ONLY"] = "LOAN_ONLY";
})(BrokerageAccountMarginApprovalCodeEnum = exports.BrokerageAccountMarginApprovalCodeEnum || (exports.BrokerageAccountMarginApprovalCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var BrokerageAccountBrokerageProviderTypeCodeEnum;
(function (BrokerageAccountBrokerageProviderTypeCodeEnum) {
  BrokerageAccountBrokerageProviderTypeCodeEnum["TDA"] = "TDA";
  BrokerageAccountBrokerageProviderTypeCodeEnum["VBS"] = "VBS";
})(BrokerageAccountBrokerageProviderTypeCodeEnum = exports.BrokerageAccountBrokerageProviderTypeCodeEnum || (exports.BrokerageAccountBrokerageProviderTypeCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var BrokerageAccountOptionPermissionEnum;
(function (BrokerageAccountOptionPermissionEnum) {
  BrokerageAccountOptionPermissionEnum["NO_PAPERS"] = "NO_PAPERS";
  BrokerageAccountOptionPermissionEnum["LEVEL_1"] = "LEVEL_1";
  BrokerageAccountOptionPermissionEnum["LEVEL_2"] = "LEVEL_2";
  BrokerageAccountOptionPermissionEnum["LEVEL_3"] = "LEVEL_3";
  BrokerageAccountOptionPermissionEnum["LEVEL_4"] = "LEVEL_4";
  BrokerageAccountOptionPermissionEnum["LEVEL_7"] = "LEVEL_7";
  BrokerageAccountOptionPermissionEnum["LEVEL_9"] = "LEVEL_9";
  BrokerageAccountOptionPermissionEnum["LEGACY_OPTION_PERMISSION"] = "LEGACY_OPTION_PERMISSION";
  BrokerageAccountOptionPermissionEnum["UNDEFINED"] = "UNDEFINED";
})(BrokerageAccountOptionPermissionEnum = exports.BrokerageAccountOptionPermissionEnum || (exports.BrokerageAccountOptionPermissionEnum = {}));
