"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultTextMapPropagator = void 0;
const core_1 = require("@opentelemetry/core");
const baggage_provider_wc3_baggage_propagator_1 = require("./baggage-provider-wc3-baggage-propagator");
const trade_nil_utils_lib_1 = require("@vanguard/trade-nil-utils-lib");
/**
 * A default text map propagator that uses the W3C trace context and propagates baggage.
 */
class DefaultTextMapPropagator extends core_1.CompositePropagator {
  constructor(baggageProvider, appPrefix) {
    const propagators = [new core_1.W3CTraceContextPropagator()];
    (0, trade_nil_utils_lib_1.applyIfPresent)(baggageProvider, baggageProvider => propagators.push(new baggage_provider_wc3_baggage_propagator_1.BaggageProviderW3CBaggagePropagator(baggageProvider, appPrefix)));
    super({
      propagators
    });
  }
}
exports.DefaultTextMapPropagator = DefaultTextMapPropagator;
