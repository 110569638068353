"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoOpSpanExporter = void 0;
const core_1 = require("@opentelemetry/core");
/**
 * A span exporter that does nothing.
 */
class NoOpSpanExporter {
  export(_, resultCallback) {
    resultCallback({
      code: core_1.ExportResultCode.SUCCESS
    });
  }
  shutdown() {
    return Promise.resolve();
  }
}
exports.NoOpSpanExporter = NoOpSpanExporter;
