import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderEnums } from '@app/etfs-equities/enums';
import { JsonContent, Quote } from '@app/etfs-equities/models';
import { TradeTicketService } from '@app/etfs-equities/services';
import { selectQuote, TayneState } from '@app/etfs-equities/store';
import { FocusOnElementUtility } from '@app/shared/utilities/focus-element/focus-element.utility';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { TimestampSize } from '@vanguard/trade-ui-components-lib-ng-17';
import { InputMask } from '@vg-constellation/angular-17/input';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AdobeAnalyticsService } from '../../../core/services';

@Component({
  selector: 'twe-dollars-to-shares-calculator',
  templateUrl: './dollars-to-shares-calculator.component.html',
  styleUrls: ['./dollars-to-shares-calculator.component.scss'],
})
export class DollarsToSharesCalculatorComponent implements OnInit, OnDestroy {
  //  Decorators...
  @ViewChild('dollarsToSharesModal')
  modal: ModalDialogComponent;

  //  Public variables...
  form: FormGroup;
  quote$: Observable<Quote>;
  unsubscribe$: Subject<void> = new Subject();
  content: JsonContent = content;
  lastTradePrice = '0';
  inputMaskInteger = InputMask.INTEGER;
  inputMaskDollars = InputMask.CURRENCY;

  protected readonly TimestampSize = TimestampSize;

  constructor(
    private readonly tradeTicketService: TradeTicketService,
    private readonly store: Store<TayneState>,
    public adobeService: AdobeAnalyticsService
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      dollars: new FormControl(null, { validators: [Validators.pattern('[0-9]*.?[0-9]*')] }),
      shares: new FormControl(),
    });

    this.quote$ = this.store.pipe(
      select(selectQuote),
      tap((quote: Quote) => {
        this.lastTradePrice = this.setLastTradePrice(quote);
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setLastTradePrice(quote: Quote): string {
    if (!quote || (quote.lastTradePrice == null && quote.previousClosePrice == null)) {
      return '0';
    }

    if (quote.lastTradePrice == null) {
      return quote.previousClosePrice;
    }

    if (quote.previousClosePrice == null) {
      return quote.lastTradePrice;
    }

    if (+quote.lastTradePrice === 0 && +quote.previousClosePrice > 0) {
      return quote.previousClosePrice;
    }

    return quote.lastTradePrice;
  }

  convertDollarsToShares() {
    const dollars = Number.parseFloat(this.form.controls.dollars.value);

    if (isNaN(dollars) || dollars <= 0 || +this.lastTradePrice <= 0) {
      this.form.controls.shares.setValue(null);
      return;
    }

    const dollarsToInt = Math.round(dollars * 100);
    const tradePriceToInt = Math.round(+this.lastTradePrice * 100);
    this.form.controls.shares.setValue(String(Math.floor(dollarsToInt / tradePriceToInt)));
  }

  convertSharesToDollars() {
    const shares = this.form.controls.shares.value;

    if (!shares) {
      this.form.controls.dollars.setValue(null);
      return;
    }

    const price = Math.round(+this.lastTradePrice * 100) / 100;
    const dollarAmount = shares * price;

    this.form.controls.dollars.setValue(isNaN(dollarAmount) ? null : dollarAmount.toFixed(2));
  }

  open() {
    this.modal.openModalDialog();
  }

  close() {
    this.form.reset();
    this.modal.closeDialogModal();
    FocusOnElementUtility.focus('amountTypeIsShares');
  }

  useCalculatedShares() {
    if (this.tradeTicketService.isSellAllChecked()) {
      this.tradeTicketService.uncheckSellAll();
    }

    if (this.tradeTicketService.amountTypeIsDollars()) {
      this.tradeTicketService.setAmountType(OrderEnums.AmountTypes.SHARES);
    }

    this.tradeTicketService.setAmount(this.form.controls.shares.value);
    this.tradeTicketService.tradeTicket.controls.amount.markAsTouched();
    this.tradeTicketService.tradeTicket.controls.amount.markAsDirty();
    this.close();
  }
}
