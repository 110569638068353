"use strict";

//https://confluence.vanguard.com/display/RETSRECOP/Journey+Tracing+Baggage+Spec
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.JourneyIdProviderBuilder = exports.stringOfLength = void 0;
const isStringOfLength = (str, min, max) => {
  return str.length >= min && str.length <= max;
};
const stringOfLength = (input, min, max) => {
  if (typeof input !== 'string') {
    throw new Error('invalid input');
  }
  if (!isStringOfLength(input, min, max)) {
    throw new Error('input is not between specified min and max');
  }
  return input;
};
exports.stringOfLength = stringOfLength;
class JourneyIdProviderBuilder {
  baggageSpecVersion = (0, exports.stringOfLength)('A', 1, 1);
  businessArea;
  flow;
  flowVariation;
  stage;
  stageVariation;
  static create(journeyId) {
    return new JourneyIdProviderBuilder(journeyId);
  }
  constructor({
    businessArea,
    flow,
    stage,
    flowVariation,
    stageVariation
  }) {
    this.businessArea = businessArea;
    this.flow = flow;
    this.stage = stage;
    this.flowVariation = flowVariation;
    this.stageVariation = stageVariation;
  }
  setBusinessArea(businessArea) {
    this.businessArea = businessArea;
    return this;
  }
  setFlow(flow) {
    this.flow = flow;
    return this;
  }
  setFlowVariation(flowVariation) {
    this.flowVariation = flowVariation;
    return this;
  }
  setStage(stage) {
    this.stage = stage;
    return this;
  }
  setStageVariation(stageVariation) {
    this.stageVariation = stageVariation;
    return this;
  }
  getJourneyId() {
    const str = [this.baggageSpecVersion, this.businessArea.trim(), this.flow.trim(), this.flowVariation?.trim(), this.stage.trim(), this.stageVariation?.trim()].join(':');
    return (0, exports.stringOfLength)(str, 1, 60);
  }
}
exports.JourneyIdProviderBuilder = JourneyIdProviderBuilder;
