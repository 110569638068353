import { OrderEnums } from '@app/etfs-equities/enums';
import { Order, Quote } from '@app/etfs-equities/models';

export class SharesUtil {
  static isSellAllNumberOfShares(shares: number): boolean {
    return shares > 0 && shares < 2;
  }

  static isSellAllMarketNumberOfShares(shares: number): boolean {
    return shares > 0 && shares < 1;
  }

  static calculateShares(order: Order.Order, quote: Quote): number {
    if (!SharesUtil.canCalculateShares(order, quote)) {
      return null;
    }

    let estimatedShares: number;
    let price: number;

    switch (order.transactionType) {
      case OrderEnums.TransactionTypes.BUY:
        if (Number(quote.askPrice)) {
          price = parseFloat(quote.askPrice);
        } else if (Number(quote.lastTradePrice)) {
          price = parseFloat(quote.lastTradePrice);
        } else {
          price = parseFloat(quote.previousClosePrice);
        }
        estimatedShares = order.shares / price;
        break;
      case OrderEnums.TransactionTypes.SELL:
        if (Number(quote.bidPrice)) {
          price = parseFloat(quote.bidPrice);
        } else if (Number(quote.lastTradePrice)) {
          price = parseFloat(quote.lastTradePrice);
        } else {
          price = parseFloat(quote.previousClosePrice);
        }
        estimatedShares = order.shares / price;
        break;
    }
    return estimatedShares;
  }

  private static canCalculateShares(order: Order.Order, quote: Quote): boolean {
    if (
      !order ||
      !quote ||
      !SharesUtil.orderTypeIsValid(order) ||
      !SharesUtil.transactionTypeIsValid(order) ||
      !SharesUtil.amountTypeIsValid(order) ||
      !order.shares ||
      order.shares <= 0 ||
      (order.transactionType === OrderEnums.TransactionTypes.BUY &&
        !quote.askPrice &&
        !quote.lastTradePrice &&
        !quote.previousClosePrice) ||
      (order.transactionType === OrderEnums.TransactionTypes.SELL &&
        !quote.bidPrice &&
        !quote.lastTradePrice &&
        !quote.previousClosePrice)
    ) {
      return false;
    }
    return true;
  }

  private static orderTypeIsValid(order: Order.Order) {
    if (!order.orderType || order.orderType !== OrderEnums.Types.MARKET) {
      return false;
    }

    return true;
  }

  private static amountTypeIsValid(order: Order.Order) {
    if (!order.amountType || order.amountType !== OrderEnums.AmountTypes.DOLLARS) {
      return false;
    }

    return true;
  }

  private static transactionTypeIsValid(order: Order.Order) {
    // Transaction type is falsy.
    if (!order.transactionType) {
      return false;
    }

    // Transaction type is of an unknown value.
    if (
      order.transactionType !== OrderEnums.TransactionTypes.BUY &&
      order.transactionType !== OrderEnums.TransactionTypes.SELL
    ) {
      return false;
    }

    return true;
  }
}
