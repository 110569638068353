import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { FeatureStatus } from '@app/core/models/feature-status.model';
import { ApiResponse } from '@app/etfs-equities/models';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const supportedFeatureIds: string = [
  GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES,
  GatekeeperFeatureIds.TWE_CHANGE_ORDER,
  GatekeeperFeatureIds.TWE_EQUITY_ETF_PATH_MVP,
  GatekeeperFeatureIds.TWE_VOLATILITY_BANNER,
  GatekeeperFeatureIds.TWE_ONETRUST,
  GatekeeperFeatureIds.TWE_CANCEL_ORDER,
  GatekeeperFeatureIds.TWE_EDIT_COST_BASIS,
  GatekeeperFeatureIds.TWE_TPLUS1_CONTENT,
  GatekeeperFeatureIds.TWE_ANNOUNCEMENTS_BANNER,
  GatekeeperFeatureIds.TOGGLE_EXTENDED_TRADING,
  GatekeeperFeatureIds.TWE_EXTENDED_TRADE_TAB_ALWAYS_ON,
  GatekeeperFeatureIds.TWE_AEM,
  GatekeeperFeatureIds.TWE_SERVERLESS,
  GatekeeperFeatureIds.TWE_INTEGRATION_VGA_OPENORDERS_V2,
  GatekeeperFeatureIds.TWE_INTEGRATION_VGA_ORDERDETAILS_V2,
  GatekeeperFeatureIds.TWE_EQUITY_ETF_PATH,
  GatekeeperFeatureIds.TWE_BEACON_ONLY_EQUITY_ETF,
].join(',');

export const defaultFeatureStatus: FeatureStatus[] = [
  { featureId: GatekeeperFeatureIds.TWE_CHANGE_ORDER, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_DOLLAR_BASED_TRADES, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_EQUITY_ETF_PATH_MVP, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_VOLATILITY_BANNER, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_ONETRUST, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_CANCEL_ORDER, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_EDIT_COST_BASIS, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_TPLUS1_CONTENT, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_ANNOUNCEMENTS_BANNER, enabled: false },
  { featureId: GatekeeperFeatureIds.TOGGLE_EXTENDED_TRADING, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_EXTENDED_TRADE_TAB_ALWAYS_ON, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_AEM, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_SERVERLESS, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_INTEGRATION_VGA_OPENORDERS_V2, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_INTEGRATION_VGA_ORDERDETAILS_V2, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_EQUITY_ETF_PATH, enabled: false },
  { featureId: GatekeeperFeatureIds.TWE_BEACON_ONLY_EQUITY_ETF, enabled: false },
];

@Injectable({
  providedIn: 'root',
})
export class GatekeeperService {
  private featureStatus: FeatureStatus[];

  constructor(private readonly http: HttpClient) {}

  initializeFeatureStatus(data: FeatureStatus[]): void {
    if (data) {
      this.featureStatus = data;
    }
  }

  /**
   * Checks if the given feature ID toggle is both supported and enabled in the set of supplied feature statuses
   *
   * @param featureId the given feature ID
   * @param features the set of feature statuses
   * @return true if supported and enabled, false otherwise.
   *
   */
  public static isFeatureIdEnabled(featureId: string, features: FeatureStatus[]): boolean {
    let featureIdEnabled = false;
    if (supportedFeatureIds.includes(featureId)) {
      featureIdEnabled = !!features.find((e) => e.featureId === featureId)?.enabled;
    }
    return featureIdEnabled;
  }

  /**
   * Checks the feature toggle status of the given feature ID by querying the midtier.
   *
   * @param featureId the given feature ID
   * @return an Observable of boolean which contains toggle status for the given feature ID.
   *
   */
  public checkSingleFeatureStatus(featureId: string): Observable<boolean> {
    if (this.featureStatus?.length) {
      const toggledStatus = this.featureStatus.find((f) => f.featureId === featureId);
      if (toggledStatus) {
        return of(toggledStatus.enabled);
      }
    }
    return this.http
      .get<boolean>(environment.apiUrl + 'gatekeeper', {
        params: { featureId },
        withCredentials: true,
      })
      .pipe(catchError(() => of(false)));
  }

  /**
   * Fetches the toggle status of each of the supported feature IDs from the midtier.
   *
   * @param featureIds a comma delimited string of feature IDs. eg: 'feature1-id, feature2-id'.
   * @param shouldRefresh optional boolean flag indicating whether to to refresh all gatekeeper values.
   * @return an Observable of an array of FeatureStatus objects containing the toggle status
   *         of each feature ID.
   *         Return an Observable of the default toggle settings if an error occurs.
   *
   */
  public checkMultiFeatureStatus(
    featureIds: string,
    shouldRefresh?: boolean
  ): Observable<ApiResponse<FeatureStatus[]>> {
    const gatekeeperResponse: ApiResponse<FeatureStatus[]> = {
      errors: [],
      data: this.featureStatus?.length ? [...this.featureStatus] : [...defaultFeatureStatus],
    };
    if (this.featureStatus?.length && !shouldRefresh) {
      return of(gatekeeperResponse);
    }

    return this.http
      .get<FeatureStatus[]>(environment.apiUrl + 'gatekeeper/multi', {
        params: { featureIds },
        withCredentials: true,
      })
      .pipe(
        map((data: FeatureStatus[]) => {
          this.featureStatus = data;
          gatekeeperResponse.data = data;

          return gatekeeperResponse;
        }),
        catchError((error) => {
          gatekeeperResponse.errors.push({
            code: error.status,
            message: error.message,
          });
          return of(gatekeeperResponse);
        })
      );
  }
}
