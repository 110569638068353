import { SearchResultTypeEnum } from '@vanguard/invest-api-client-typescript-axios';
import { SpawningSpan } from '@vanguard/invest-otel-lib/angular';
import { Observable } from 'rxjs';

export type FilterTypeToDisplay = 'vgFund' | 'vgEtf' | 'security';
export type NonVanguardProductType = 'nonVgFund' | 'nonVgEtf';

export type FilterType = FilterTypeToDisplay | NonVanguardProductType;

export interface ProductSearchConfig {
  productTypes?: Set<FilterType>;
  displayResultsPerPageCount?: number;
  maxSearchCount?: number;
  maxPagesToDisplayInPagination?: number;
  showChipsInAllSearchResults?: boolean;
  minimumCharactersToSearch?: number;
  searchResultsSelectionOption?: SearchResultsSelectionOptionEnum;
  actionLabelInSearchResults?: string;
  searchDebounceTime?: number;
  searchModalTitle?: string;
  hideSearchIcon?: boolean;
  displaySearchColumns?: ProductSearchDisplayFields;
  emptyStateText?: string;
  rememberSelectedProduct?: boolean;
  reEmitSearchResultsOnLoad?: boolean;
  clearSelectedProductOnEmit?: boolean;
}

export interface ProductSearchSearchTermInput {
  searchTermValue: string;
}

export enum SearchResultsSelectionOptionEnum {
  ACTION_BUTTON = 'ACTION_BUTTON',
  LINE_SELECT = 'LINE_SELECT',
}

export interface ProductSearchConfigProvider {
  getConfig: () => ProductSearchConfig | Observable<ProductSearchConfig>;
}

export interface ProductSearchSearchTermProvider {
  getSearchTerm: () => ProductSearchSearchTermInput | Observable<ProductSearchSearchTermInput>;
}

export interface ProductSearchSpanProvider {
  getActiveSpan: () => SpawningSpan;
}

export interface ProductSearchResult {
  ticker: string;
  longName: string;
  minInitialInvestment?: string;
  cusip?: string;
  category: FilterType;
  searchTerm: string;
  fundId?: string;
  type: SearchResultTypeEnum;
  fundType: string;
}

export interface ProductResultSelection {
  ticker: string;
  longName: string;
  cusip?: string;
}

export interface ProductSearchDisplayFields {
  tickerAndLongName: boolean;
  minInitialInvestment: boolean;
  cusip: boolean;
  fundId: boolean;
}
