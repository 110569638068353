"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpawningSpan = void 0;
/**
 * A spawning span.
 */
class SpawningSpan {
  span;
  service;
  constructor(span, service) {
    this.span = span;
    this.service = service;
  }
  /**
   *  Creates a spawning span base on the passed in span.
   * @param span The current span and any spawned span's parent.
   * @param service The service.
   * @returns The spawning span.
   */
  static create(span, service) {
    return new SpawningSpan(span, service);
  }
  /**
   *  Gets the wrapped span.
   * @returns The wrapped span.
   */
  getWrappedSpan() {
    return this.span;
  }
  /**
   * Creates a spawning span with the current span as the parent.
   * @param name The name of the span.
   * @param options The options for the span.
   * @returns The spawning span.
   */
  spawn(name, options) {
    return this.service.create(name, options, this.span);
  }
  /**
   * Creates an active spawning span.
   * @param name - The name of the span.
   * @param receiver - The receiver of the span.
   * @param options - The options for the span.
   */
  spawnActive(name, receiver, options) {
    this.service.createActive(name, receiver, options, this.span);
    return this;
  }
  /**
   * Gets the current span's context
   * @returns The spawning span.
   */
  spanContext() {
    return this.span.spanContext();
  }
  /**
   *  Sets an attribute on the spawning span.
   * @param key The key of the attribute.
   * @param value The value of the attribute.
   * @returns The spawning span.
   */
  setAttribute(key, value) {
    if (!this.isRecording()) {
      console.error(`Error setting attribute on span: ${this.spanContext().spanId}.  Span is ended`);
      return this;
    }
    this.span.setAttribute(key, value);
    return this;
  }
  /**
   *  Sets attributes on the spawning span.
   * @param attributes
   * @returns The spawning span.
   */
  setAttributes(attributes) {
    if (!this.isRecording()) {
      console.error(`Error setting attributes on span: ${this.spanContext().spanId}.  Span is ended`);
      return this;
    }
    this.span.setAttributes(attributes);
    return this;
  }
  /**
   *  Adds an event to the spawning span.
   * @param name The name of the event.
   * @param attributesOrStartTime An object describing the attributes to be associated with the event or the start time of the event.
   * @param startTime The start time of the event.
   * @returns The spawning span.
   */
  addEvent(name, attributesOrStartTime, startTime) {
    if (!this.isRecording()) {
      console.error(`Error adding event on span: ${this.spanContext().spanId}.  Span is ended`);
      return this;
    }
    this.span.addEvent(name, attributesOrStartTime, startTime);
    return this;
  }
  /**
   *  Sets the status on the spawning span.
   * @param status
   * @returns
   */
  setStatus(status) {
    if (!this.isRecording()) {
      console.error(`Error setting status on span: ${this.spanContext().spanId}.  Span is ended`);
      return this;
    }
    this.span.setStatus(status);
    return this;
  }
  /**
   *  Updates the name of the spawning span.
   * @param name The new name of the span.
   * @returns The spawning span.
   */
  updateName(name) {
    if (!this.isRecording()) {
      console.error(`Error updating name on span: ${this.spanContext().spanId}.  Span is ended`);
      return this;
    }
    this.span.updateName(name);
    return this;
  }
  /**
   *  Ends the spawning span.
   * @param endTime The time to end the span.
   */
  end(endTime) {
    this.span.end(endTime);
  }
  /**
   *  Returns true if this span is recording information like events, attributes, status.
   * @returns True if this span is recording.
   */
  isRecording() {
    return this.span.isRecording();
  }
  /**
   *  Records an exception on the spawning span.
   * @param exception The exception to record.
   * @param time The time to record the exception.
   */
  recordException(exception, time) {
    if (!this.isRecording()) {
      console.error(`Error recording exception on span: ${this.spanContext().spanId}.  Span is ended`);
      return this;
    }
    this.span.recordException(exception, time);
    return this;
  }
}
exports.SpawningSpan = SpawningSpan;
