"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoOpTracerService = void 0;
/**
 * A no op tracer service.
 */
class NoOpTracerService {
  withActive() {
    //not implemented
  }
  createActive() {
    //not implemented
  }
  create() {
    //not implemented
  }
  getActive() {
    //not implemented
  }
  getCurrentTraceId() {
    return '';
  }
  createAsync() {
    //not implemented
    return Promise.resolve();
  }
  getName() {
    return 'NoOp Tracer Service';
  }
}
exports.NoOpTracerService = NoOpTracerService;
