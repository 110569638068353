import { Component, OnDestroy, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { JsonContent } from '@app/etfs-equities/models';
import { AccountService, TradeTicketService, WindowService } from '@app/etfs-equities/services';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { OrderCancel, OrderCancelResponse } from '@etfs-equities/models/order-cancel';
import {
  createClearCancelOrderAction,
  createClearKeywordSearchAction,
  createClearOrderAction,
  createClearQuoteAction,
  createClearTradeTicketAction,
  createSetIsChangeOrderAction,
  createSetIsEditCostBasisAction,
  createUpdateLotFormAction,
  TayneState,
} from '@etfs-equities/store';
import {
  selectOrderCancel,
  selectOrderCancelResponse,
} from '@etfs-equities/store/selectors/order-cancel/order-cancel.selector';
import { select, Store } from '@ngrx/store';
import { TimestampSize } from '@vanguard/trade-ui-components-lib-ng-17';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'twe-cancel-success',
  templateUrl: './cancel-success-page.component.html',
  styleUrls: ['./cancel-success-page.component.scss'],
})
export class CancelSuccessPageComponent implements OnInit, OnDestroy {
  // Public observables/subjects...

  cancelResponse$: Observable<OrderCancelResponse>;
  orderCancel$: Observable<OrderCancel>;

  // Public variables...

  content: JsonContent = content;
  secureSiteUrls = environment.secureSiteUrls;
  readonly TRADE_PATH = CONSTANTS.TRADE_PATH;
  readonly OPEN_ORDERS_PATH = CONSTANTS.OPEN_ORDERS_PATH;
  protected readonly timestampSizeEnum = TimestampSize;

  constructor(
    private readonly store: Store<TayneState>,
    private readonly tradeTicketService: TradeTicketService,
    private readonly accountService: AccountService,
    public readonly windowService: WindowService
  ) {}

  ngOnInit() {
    this.cancelResponse$ = this.store.pipe(select(selectOrderCancelResponse));
    this.orderCancel$ = this.store.pipe(select(selectOrderCancel));
    this.orderCancel$.pipe(take(1)).subscribe((orderCancel) => {
      this.accountService.refreshOpenOrdersForAccount(orderCancel.accountId);
    });
  }

  printComponent() {
    window.print();
  }

  ngOnDestroy() {
    this.tradeTicketService.costBasisIsVisible = false;
    this.tradeTicketService.tradeTicket.controls.amount.clearAsyncValidators();
    this.tradeTicketService.resetForm();
    this.tradeTicketService.setSharesValidators();
    this.store.dispatch(createClearQuoteAction());
    this.store.dispatch(createClearCancelOrderAction());
    this.store.dispatch(createClearOrderAction());
    this.store.dispatch(createClearTradeTicketAction());
    this.store.dispatch(createUpdateLotFormAction(null));
    this.store.dispatch(createClearKeywordSearchAction());
    this.store.dispatch(createSetIsEditCostBasisAction(false));
    this.store.dispatch(createSetIsChangeOrderAction(false));
  }
}
