"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MailingAddressStandardizedAddressStatusEnum = exports.MailingAddressDefectReasonEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var MailingAddressDefectReasonEnum;
(function (MailingAddressDefectReasonEnum) {
  MailingAddressDefectReasonEnum["ADDRESS_IS_NOT_EVALUATED"] = "ADDRESS_IS_NOT_EVALUATED";
  MailingAddressDefectReasonEnum["ADDRESS_LINE_MORE_THAN_40_CHARACTERS"] = "ADDRESS_LINE_MORE_THAN_40_CHARACTERS";
  MailingAddressDefectReasonEnum["APARTMENT_INVALID"] = "APARTMENT_INVALID";
  MailingAddressDefectReasonEnum["APARTMENT_MISSING"] = "APARTMENT_MISSING";
  MailingAddressDefectReasonEnum["APARTMENT_RANGE_NOT_FOUND"] = "APARTMENT_RANGE_NOT_FOUND";
  MailingAddressDefectReasonEnum["CANNOT_PARSE_ADDRESS"] = "CANNOT_PARSE_ADDRESS";
  MailingAddressDefectReasonEnum["CLIENT_PREFERENCE"] = "CLIENT_PREFERENCE";
  MailingAddressDefectReasonEnum["CODE1_NOT_AVAILABLE"] = "CODE1_NOT_AVAILABLE";
  MailingAddressDefectReasonEnum["DPV_TURNED_OFF"] = "DPV_TURNED_OFF";
  MailingAddressDefectReasonEnum["EXTRANEOUS_INFO_ON_ADDRESS_LINE"] = "EXTRANEOUS_INFO_ON_ADDRESS_LINE";
  MailingAddressDefectReasonEnum["HOUSE_NUMBER_INVALID"] = "HOUSE_NUMBER_INVALID";
  MailingAddressDefectReasonEnum["HOUSE_OR_RANGE_NOT_FOUND"] = "HOUSE_OR_RANGE_NOT_FOUND";
  MailingAddressDefectReasonEnum["INSUFFICIENT_ADDRESS"] = "INSUFFICIENT_ADDRESS";
  MailingAddressDefectReasonEnum["MORE_THAN_FOUR_ADDRESS_LINES"] = "MORE_THAN_FOUR_ADDRESS_LINES";
  MailingAddressDefectReasonEnum["MORE_THAN_TWO_ADDRESS_LINES"] = "MORE_THAN_TWO_ADDRESS_LINES";
  MailingAddressDefectReasonEnum["MULTIPLE_MATCHES_FOUND"] = "MULTIPLE_MATCHES_FOUND";
  MailingAddressDefectReasonEnum["NON_CONSECUTIVE_ADDRESS_LINES"] = "NON_CONSECUTIVE_ADDRESS_LINES";
  MailingAddressDefectReasonEnum["NO_DEFECT"] = "NO_DEFECT";
  MailingAddressDefectReasonEnum["POST_OFFICE_INVALID"] = "POST_OFFICE_INVALID";
  MailingAddressDefectReasonEnum["POST_OFFICE_MISSING"] = "POST_OFFICE_MISSING";
  MailingAddressDefectReasonEnum["PRIMARY_NUMBER_IS_MISSING"] = "PRIMARY_NUMBER_IS_MISSING";
  MailingAddressDefectReasonEnum["STREET_NOT_FOUND"] = "STREET_NOT_FOUND";
  MailingAddressDefectReasonEnum["ZIPCODE_NOT_FOUND"] = "ZIPCODE_NOT_FOUND";
})(MailingAddressDefectReasonEnum = exports.MailingAddressDefectReasonEnum || (exports.MailingAddressDefectReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
var MailingAddressStandardizedAddressStatusEnum;
(function (MailingAddressStandardizedAddressStatusEnum) {
  MailingAddressStandardizedAddressStatusEnum["ADDRESS_NOT_EVALUATED"] = "ADDRESS_NOT_EVALUATED";
  MailingAddressStandardizedAddressStatusEnum["NON_STANDARDIZED_ADDRESS"] = "NON_STANDARDIZED_ADDRESS";
  MailingAddressStandardizedAddressStatusEnum["STANDARDIZED_ADDRESS"] = "STANDARDIZED_ADDRESS";
})(MailingAddressStandardizedAddressStatusEnum = exports.MailingAddressStandardizedAddressStatusEnum || (exports.MailingAddressStandardizedAddressStatusEnum = {}));
