"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TriggeredRuleRuleSeverityEnum = exports.TriggeredRuleRuleChannelEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var TriggeredRuleRuleChannelEnum;
(function (TriggeredRuleRuleChannelEnum) {
  TriggeredRuleRuleChannelEnum["WEB"] = "WEB";
  TriggeredRuleRuleChannelEnum["PH"] = "PH";
  TriggeredRuleRuleChannelEnum["MAIL"] = "MAIL";
  TriggeredRuleRuleChannelEnum["VRU"] = "VRU";
  TriggeredRuleRuleChannelEnum["SYS"] = "SYS";
  TriggeredRuleRuleChannelEnum["BTCH"] = "BTCH";
  TriggeredRuleRuleChannelEnum["EDI"] = "EDI";
  TriggeredRuleRuleChannelEnum["TEXT"] = "TEXT";
})(TriggeredRuleRuleChannelEnum = exports.TriggeredRuleRuleChannelEnum || (exports.TriggeredRuleRuleChannelEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TriggeredRuleRuleSeverityEnum;
(function (TriggeredRuleRuleSeverityEnum) {
  TriggeredRuleRuleSeverityEnum["ERROR"] = "ERROR";
  TriggeredRuleRuleSeverityEnum["WARNING"] = "WARNING";
  TriggeredRuleRuleSeverityEnum["OVERRIDE"] = "OVERRIDE";
  TriggeredRuleRuleSeverityEnum["REVIEW_RELEASE"] = "REVIEW_RELEASE";
})(TriggeredRuleRuleSeverityEnum = exports.TriggeredRuleRuleSeverityEnum || (exports.TriggeredRuleRuleSeverityEnum = {}));
