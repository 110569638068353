"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OpenTelemetryService = void 0;
const tslib_1 = require("tslib");
/* istanbul ignore file */
const api_1 = require("@opentelemetry/api");
const otelApi = tslib_1.__importStar(require("@opentelemetry/api"));
const spawning_span_1 = require("./span/spawning-span");
const trade_nil_utils_lib_1 = require("@vanguard/trade-nil-utils-lib");
/**
 * The required resource attributes
 */
class OpenTelemetryService {
  contextManager;
  constructor(contextManager) {
    this.contextManager = contextManager;
  }
  /**
   * Passes the active span if it exists to the receiver
   * @param receiver
   */
  withActive(receiver) {
    (0, trade_nil_utils_lib_1.applyIfPresent)(this.getActive(), activeSpan => receiver(activeSpan));
  }
  /**
   *  Gets the service name
   * @returns The service name
   */
  getActive() {
    const activeContext = this.contextManager.active();
    const activeSpan = otelApi.trace.getSpan(activeContext);
    return activeSpan ? spawning_span_1.SpawningSpan.create(activeSpan, this) : undefined;
  }
  /**
   *  Gets the service name
   * @returns The service name
   */
  getCurrentTraceId() {
    const span = this.getActive();
    return span?.spanContext().traceId;
  }
  async doCreateAsync(tracer, spanName, options, parent) {
    const activeContext = this.contextManager.active();
    const contextWithSpan = parent ? otelApi.trace.setSpan(activeContext, parent) : activeContext;
    const span = tracer.startSpan(spanName, options, contextWithSpan);
    const anySpan = span;
    return Promise.resolve(anySpan.spanProcessorListenerStartup).then(() => {
      return spawning_span_1.SpawningSpan.create(span, this);
    });
  }
  doCreate(tracer, spanName, options, parent) {
    const activeContext = api_1.context.active();
    const contextWithSpan = parent ? otelApi.trace.setSpan(activeContext, parent) : activeContext;
    const span = tracer.startSpan(spanName, options, contextWithSpan);
    return spawning_span_1.SpawningSpan.create(span, this);
  }
  // eslint-disable-next-line max-params
  doCreateActive(tracer, spanName, receiver, options, parent) {
    const activeContext = api_1.context.active();
    const contextWithSpan = parent ? otelApi.trace.setSpan(activeContext, parent) : activeContext;
    tracer.startActiveSpan(spanName, options ?? {}, contextWithSpan, childSpan => {
      const anySpan = childSpan;
      Promise.resolve(anySpan.spanProcessorListenerStartup).then(async () => {
        try {
          if (receiver) {
            const childSpawningSpan = spawning_span_1.SpawningSpan.create(childSpan, this);
            await receiver(childSpawningSpan);
          }
        } catch (err) {
          childSpan.recordException(err);
        } finally {
          childSpan.end();
        }
      });
    });
  }
}
exports.OpenTelemetryService = OpenTelemetryService;
