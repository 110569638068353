"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BuyTransactionDestinationDestinationType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BuyTransactionDestinationDestinationType;
(function (BuyTransactionDestinationDestinationType) {
  BuyTransactionDestinationDestinationType["BROKERAGE_HOLDING"] = "BROKERAGE_HOLDING";
  BuyTransactionDestinationDestinationType["FIVE_TWENTY_NINE_ACCOUNT"] = "FIVE_TWENTY_NINE_ACCOUNT";
})(BuyTransactionDestinationDestinationType = exports.BuyTransactionDestinationDestinationType || (exports.BuyTransactionDestinationDestinationType = {}));
