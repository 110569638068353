"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VgaCostBasisOrderCostBasisMethodEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var VgaCostBasisOrderCostBasisMethodEnum;
(function (VgaCostBasisOrderCostBasisMethodEnum) {
  VgaCostBasisOrderCostBasisMethodEnum["FIFO"] = "FIFO";
  VgaCostBasisOrderCostBasisMethodEnum["MINT"] = "MINT";
  VgaCostBasisOrderCostBasisMethodEnum["AVGCOST"] = "AVGCOST";
  VgaCostBasisOrderCostBasisMethodEnum["SPECID"] = "SPECID";
  VgaCostBasisOrderCostBasisMethodEnum["HIFO"] = "HIFO";
  VgaCostBasisOrderCostBasisMethodEnum["DFLT"] = "DFLT";
  VgaCostBasisOrderCostBasisMethodEnum["SPEC"] = "SPEC";
})(VgaCostBasisOrderCostBasisMethodEnum = exports.VgaCostBasisOrderCostBasisMethodEnum || (exports.VgaCostBasisOrderCostBasisMethodEnum = {}));
