"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BuyRequestTradeTransactionDetailsAmountTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var BuyRequestTradeTransactionDetailsAmountTypeEnum;
(function (BuyRequestTradeTransactionDetailsAmountTypeEnum) {
  BuyRequestTradeTransactionDetailsAmountTypeEnum["DOLLARS"] = "DOLLARS";
  BuyRequestTradeTransactionDetailsAmountTypeEnum["SHARES"] = "SHARES";
  BuyRequestTradeTransactionDetailsAmountTypeEnum["PERCENT"] = "PERCENT";
  BuyRequestTradeTransactionDetailsAmountTypeEnum["REMAINING_PERCENT"] = "REMAINING_PERCENT";
})(BuyRequestTradeTransactionDetailsAmountTypeEnum = exports.BuyRequestTradeTransactionDetailsAmountTypeEnum || (exports.BuyRequestTradeTransactionDetailsAmountTypeEnum = {}));
