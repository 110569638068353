import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrderEnums, SecurityAccountTypes } from '@app/etfs-equities/enums';
import { JsonContent } from '@app/etfs-equities/models';
import { TradeTicketService } from '@app/etfs-equities/services';
import {
  selectAccountIsMargin,
  selectAction,
  selectHasEnoughCashMarginShares,
  selectHasEnoughCashShares,
  selectHasEnoughMarginShares,
  selectIsCashAndMarginOrShortHoldings,
  selectIsCashOnlyHoldings,
  selectIsChangeOrderState,
  selectIsSellingAllHeldShares,
  selectQuoteIsVgETF,
  selectSharesHeld,
  TayneState,
} from '@app/etfs-equities/store';
import { SharesUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import {
  delay,
  distinctUntilChanged,
  filter,
  merge,
  Observable,
  skip,
  Subject,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

@Component({
  selector: 'twe-security-account-type-control',
  templateUrl: './security-account-type-control.component.html',
  styleUrls: ['./security-account-type-control.component.scss'],
})
export class SecurityAccountTypeControlComponent implements OnInit, OnDestroy {
  selectedAction$: Observable<OrderEnums.TransactionTypes>;
  isCashAndMarginOrShortHoldings$: Observable<boolean>;
  isCashOnlyHoldings$: Observable<boolean>;
  quoteIsVgEtf$: Observable<boolean>;
  accountIsMargin$: Observable<boolean>;
  isChangeOrder$: Observable<boolean>;
  hasEnoughCashMarginShares$: Observable<boolean>;
  hasEnoughCashShares$: Observable<boolean>;
  hasEnoughMarginShares$: Observable<boolean>;
  sharesHeld$: Observable<number>;

  content: JsonContent = content;
  transactionTypes = OrderEnums.TransactionTypes;
  securityAccountTypes = SecurityAccountTypes;
  isReadOnlyPreselect: boolean;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(public tradeTicketService: TradeTicketService, private readonly store: Store<TayneState>) {}

  ngOnInit() {
    this.selectedAction$ = this.store.pipe(select(selectAction));
    this.isCashAndMarginOrShortHoldings$ = this.store.pipe(
      select(selectIsCashAndMarginOrShortHoldings),
      tap((val) => {
        if (val) {
          this.tradeTicketService.setSecurityAccountTypeValidators();
        } else {
          this.tradeTicketService.clearSecurityAccountTypeValidators();
        }
      })
    );
    this.isCashOnlyHoldings$ = this.store.pipe(select(selectIsCashOnlyHoldings));
    this.quoteIsVgEtf$ = this.store.pipe(select(selectQuoteIsVgETF));
    this.accountIsMargin$ = this.store.pipe(select(selectAccountIsMargin));
    this.isChangeOrder$ = this.store.pipe(select(selectIsChangeOrderState));
    this.hasEnoughCashMarginShares$ = this.store.pipe(select(selectHasEnoughCashMarginShares));
    this.hasEnoughCashShares$ = this.store.pipe(select(selectHasEnoughCashShares));
    this.hasEnoughMarginShares$ = this.store.pipe(select(selectHasEnoughMarginShares));
    this.sharesHeld$ = this.store.pipe(select(selectSharesHeld));

    this.watchForSecurityAccountType();
    this.watchForHoldingsForCurrentQuoteChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private watchForSecurityAccountType() {
    this.tradeTicketService.tradeTicket.controls.securityAccountType.valueChanges
      .pipe(
        distinctUntilChanged(),
        skip(1),
        delay(0),
        withLatestFrom(
          this.store.pipe(select(selectIsSellingAllHeldShares)),
          this.accountIsMargin$,
          this.sharesHeld$,
          this.quoteIsVgEtf$
        ),
        tap(([, isSellingAllShares, accountIsMargin, sharesHeld, isVgEtf]) => {
          if (
            (this.tradeTicketService.isSellAllChecked() && !isSellingAllShares) ||
            (SharesUtil.isSellAllNumberOfShares(sharesHeld) && isVgEtf)
          ) {
            if (this.tradeTicketService.orderActionIsSell()) {
              this.tradeTicketService.tradeTicket.controls.sellAll.patchValue(true);
              if (!SharesUtil.isSellAllMarketNumberOfShares(sharesHeld)) {
                this.tradeTicketService.resetOnlyTheFollowingControls(['orderType', 'duration']);
              }
              return;
            } else {
              this.tradeTicketService.uncheckSellAll();
            }
          }
          if (
            this.tradeTicketService.tradeTicket.controls.amount.value &&
            accountIsMargin &&
            (!this.tradeTicketService.isSellAllChecked() ||
              !this.tradeTicketService.orderActionIsSell() ||
              !SharesUtil.isSellAllNumberOfShares(sharesHeld))
          ) {
            this.tradeTicketService.resetAmount(false);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private watchForHoldingsForCurrentQuoteChanges() {
    merge(
      this.tradeTicketService.tradeTicket.controls.action.valueChanges,
      this.tradeTicketService.tradeTicket.controls.hasQuote.valueChanges,
      this.tradeTicketService.tradeTicket.controls.brokerageAccountNumber.valueChanges,
      this.tradeTicketService.tradeTicket.controls.amount.valueChanges.pipe(
        filter(() => this.tradeTicketService.orderActionIsSell())
      )
    )
      .pipe(
        withLatestFrom(
          this.accountIsMargin$,
          this.isCashOnlyHoldings$,
          this.isCashAndMarginOrShortHoldings$,
          this.quoteIsVgEtf$,
          this.isChangeOrder$,
          this.hasEnoughCashMarginShares$,
          this.hasEnoughCashShares$,
          this.hasEnoughMarginShares$
        ),
        filter(([_triggers, isMarginAccount]) => isMarginAccount !== null),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        ([
          _triggerEvent,
          isMarginAccount,
          isCashOnlyHoldings,
          isCashAndMarginOrShortHoldings,
          isVgETF,
          isChangeOrder,
          hasEnoughCashMarginShares,
          hasEnoughCashShares,
          hasEnoughMarginShares,
        ]) => {
          const action = this.tradeTicketService.tradeTicket.controls.action.value;
          if (!isMarginAccount) {
            this.tradeTicketService.setSecurityAccountType(null);
            return;
          }

          const hasQuote = this.tradeTicketService.tradeTicket.controls.hasQuote.value;
          if (!isChangeOrder && hasQuote) {
            switch (action) {
              case OrderEnums.TransactionTypes.BUY: {
                if (isVgETF) {
                  this.tradeTicketService.setSecurityAccountType(SecurityAccountTypes.CASH);
                } else {
                  this.tradeTicketService.setSecurityAccountType(SecurityAccountTypes.MARGIN);
                }
                break;
              }
              case OrderEnums.TransactionTypes.SELL: {
                if (isCashOnlyHoldings) {
                  this.tradeTicketService.setSecurityAccountType(SecurityAccountTypes.CASH);
                } else if (isCashAndMarginOrShortHoldings) {
                  this.isReadOnlyPreselect =
                    hasEnoughCashMarginShares &&
                    (!hasEnoughCashShares || !hasEnoughMarginShares) &&
                    !this.tradeTicketService.isSellAllChecked();
                  if (this.isReadOnlyPreselect) {
                    const readOnlyCash = !hasEnoughMarginShares ? SecurityAccountTypes.CASH : null;
                    const readOnlyMargin = !hasEnoughCashShares ? SecurityAccountTypes.MARGIN : null;

                    this.tradeTicketService.setSecurityAccountType(readOnlyCash || readOnlyMargin, {
                      emitEvent: false,
                      onlySelf: true,
                    });
                  }
                } else {
                  this.tradeTicketService.setSecurityAccountType(SecurityAccountTypes.MARGIN);
                }
                break;
              }
              case OrderEnums.TransactionTypes.BUY_TO_COVER:
              case OrderEnums.TransactionTypes.SELL_SHORT: {
                this.tradeTicketService.setSecurityAccountType(SecurityAccountTypes.SHORT);
              }
            }
          }
        }
      );
  }
}
