<ng-container
  *ngIf="{
    shortTermLots: (shortTermLots$ | async),
    longTermLots: (longTermLots$ | async),
    account: (selectedAccount$ | async),
    quote: (quote$ | async),
    tradeTicket: (tradeTicket$ | async),
    isScreenXSmall: (isScreenXSmall$ | async),
    isScreenSmall: (isScreenSmall$ | async),
    lotsDate: (lotsDate$ | async),
  } as view"
>
  <twe-triggered-rules-modal
    [acceptedRulesNextStep]="AcceptedRulesNextStep.PREVIEW"
    (openOrderTypesModalEmitter)="orderTypesModal.open()"
  ></twe-triggered-rules-modal>

  <twe-generic-error-modal [isChangeOrder]="!!view.order?.orderId"></twe-generic-error-modal>

  <twe-order-types-modal #orderTypesModal></twe-order-types-modal>

  <div class="container-fluid c11n-space-stack-3x" data-testid="select-shares-container">
    <twe-selected-account [account]="view.account" class="d-block c11n-space-stack-3x"></twe-selected-account>

    <section class="c11n-space-stack-3x">
      <h2 class="c11n-text-lg-headline c11n-space-stack-1x">
        {{ content.selectSharesPage.select }} {{ view.tradeTicket?.amount }} {{ content.labels.share
        }}{{ view.tradeTicket?.amount > 1 ? 's' : '' }}
        {{ content.selectSharesPage.sharesToTrade }}
      </h2>

      <p class="c11n-text-md twe-c11n-color-ink-400 text-uppercase c11n-space-stack-1x">
        {{ view.quote | tweQuoteTickerLongName }}
      </p>

      <div class="row">
        <div class="col-lg-7">
          <p class="c11n-text-sm">
            {{ content.selectSharesPage.selectedMoreSharesThanRequiredPart1 }}
            {{ view.tradeTicket?.amount }}
            {{ content.labels.share }}{{ view.tradeTicket?.amount > 1 ? 's' : '' }}.
            {{ content.selectSharesPage.selectedMoreSharesThanRequiredPart2 }}
          </p>
        </div>

        <div class="col-lg-5 refresh-button">
          <twe-refresh-button
            [isWrap]="false"
            [isLoading]="costBasisService.isLoadingLots"
            [hasFailed]="costBasisService.isLoadingLotsFailed"
            (refresh)="refresh()"
            [title]="'Refresh'"
            [alwaysShowContent]="true"
            [ariaLabel]="view.lotsDate ? content.selectSharesPage.refreshBtnAriaLabel + (view.lotsDate | tweDate) : ''"
            class="tds-my-6"
          >
            <span class="tds-mr-2 d-inline-block" *ngIf="view.lotsDate">
              <tcx-timestamp
                [showTime]="true"
                [prefix]="content.labels.asOf"
                [size]="timestampSizeEnum.XS"
                [date]="view.lotsDate | tweDate"
              ></tcx-timestamp>
            </span>
          </twe-refresh-button>
        </div>
      </div>

      <p class="c11n-text-sm" [innerHTML]="content.selectSharesPage.partialExecutionNotice"></p>
    </section>

    <section class="c11n-space-stack-3x">
      <div class="responsive-table" data-testid="scroll-bar-select-shares">
        <tcx-lot-selection
          [lotSelectionContent]="content.selectSharesPage.lotsSelectionContent"
          [longTermSelectionData]="view.longTermLots"
          [shortTermSelectionData]="view.shortTermLots"
          [tooltipColorMode]="'on-light'"
          (openTaxLossHarvestingLink)="handleOpenTaxLossHarvestingLink()"
          (onCapitalGainsLinkClick)="handleLearnMoreAboutCapitalGainsLinkClick()"
          (onViewDefinitionClick)="specIdInfoModal.modal.openModalDialog()"
        ></tcx-lot-selection>
      </div>
    </section>

    <section class="row justify-content-between">
      <div class="col-lg-7">
        <c11n-banner
          *ngIf="hasInvalidSelectedQtyShares"
          size="small"
          variant="error"
          [hasCloseControl]="false"
          data-testid="totals-must-match-error-banner"
        >
          <div class="c11n-text-md--crop">
            {{ content.selectSharesPage.totalsMustMatch }}
            <button
              c11n-link
              [attr.aria-label]="content.selectSharesPage.moreAboutCostBasis"
              (click)="specIdInfoModal.modal.openModalDialog()"
            >
              {{ content.selectSharesPage.moreAboutCostBasis }}
            </button>
          </div>
        </c11n-banner>
      </div>

      <div class="col-lg-4 action-buttons">
        <c11n-button
          class="action-buttons__edit"
          buttonType="button"
          size="medium"
          variant="secondary"
          [fullWidth]="view.isScreenXSmall || view.isScreenSmall"
          [labelText]="content.labels?.editOrder"
          (clickEvent)="edit()"
          data-testid="btn-sell-shares-edit-order"
        ></c11n-button>

        <c11n-button
          buttonType="button"
          size="medium"
          variant="primary"
          [fullWidth]="view.isScreenXSmall || view.isScreenSmall"
          [labelText]="content.labels?.previewOrder"
          [isInvalid]="hasInvalidSelectedQtyShares"
          (clickEvent)="previewButtonClick(view.tradeTicket.amount)"
          data-testid="btn-sell-shares-preview-order"
        ></c11n-button>
      </div>
    </section>
  </div>

  <!-- No changes Error Modal -->
  <twe-no-changes-error-modal></twe-no-changes-error-modal>

  <!-- Cost Basis Lot Error Modal -->
  <twe-cost-basis-lot-error-modal></twe-cost-basis-lot-error-modal>

  <!-- SpecIdInfoModal -->
  <twe-spec-id-info-modal #specIdInfoModal></twe-spec-id-info-modal>

  <!-- Mixed Method Warning Modal -->
  <twe-mixed-method-warning-modal
    #mixedMethodWarningModal
    (invokePrimaryClick)="preview()"
  ></twe-mixed-method-warning-modal>
</ng-container>
