import { AccountRestrictions, CashMarginIndicatorTypes } from '@app/etfs-equities/enums';
import { Account, BrokerageNumberToAccountIdMap } from '@app/etfs-equities/models';
import { HoldingsUtil } from '@app/etfs-equities/utils';
import { createSelector } from '@ngrx/store';

import { AccountState, TayneState } from '../../states';
import { selectTayneState } from '../tayne/tayne.selectors';

export const selectAccountState = createSelector(selectTayneState, (state: TayneState) => state.account);

export const selectAccounts = createSelector(selectAccountState, (state: AccountState) =>
  state.accounts ? state.accounts : []
);

export const selectSelectedAccount = createSelector(selectAccountState, (state: AccountState) => state.selectedAccount);

export const selectHoldings = createSelector(selectSelectedAccount, (state: Account.Account) =>
  state && state.holdings ? state.holdings : []
);

export const selectEtfHoldings = createSelector(selectHoldings, (state: Account.Holding[]) =>
  HoldingsUtil.getEtfs(state)
);

export const selectStockHoldings = createSelector(selectHoldings, (state: Account.Holding[]) =>
  HoldingsUtil.getStocks(state)
);

export const selectOptionHoldings = createSelector(selectHoldings, (state: Account.Holding[]) =>
  HoldingsUtil.getOptions(state)
);

export const selectMutualFundHoldings = createSelector(selectHoldings, (state: Account.Holding[]) =>
  HoldingsUtil.getMutualFunds(state)
);

export const selectCDHoldings = createSelector(selectHoldings, (state: Account.Holding[]) =>
  HoldingsUtil.getCDs(state)
);

export const selectBondHoldings = createSelector(selectHoldings, (state: Account.Holding[]) =>
  HoldingsUtil.getBonds(state)
);

export const selectOtherHoldings = createSelector(selectHoldings, (state: Account.Holding[]) =>
  HoldingsUtil.getOtherSecurities(state)
);

export const selectCostBasisFailureLoad = createSelector(selectSelectedAccount, (state: Account.Account): boolean =>
  state && state.errors ? state.errors.some((error) => error.costBasisLoadFailure) : false
);

export const selectAccountIsMargin = createSelector(
  selectSelectedAccount,
  (state: Account.Account): boolean => state && state.marginCode === CashMarginIndicatorTypes.MARGIN
);

export const selectAccountHasPatternDayTraderRestriction = createSelector(
  selectSelectedAccount,
  (state: Account.Account) =>
    state && !!state.restrictions && state.restrictions.indexOf(AccountRestrictions.PATTERN_DAY_TRADER) !== -1
);

export const selectExtendedTradeAgreement = createSelector(selectAccountState, (state: AccountState) =>
  state.extendedTradeAgreement ? state.extendedTradeAgreement : null
);

export const selectIsOnlyManagedAccounts = createSelector(
  selectAccountState,
  (state: AccountState) => !state.accounts?.length && state.isManagedAccInList
);

export const selectBrokerageNumberToAccountIdMap = createSelector(
  selectAccounts,
  (accounts: Account.Account[]): BrokerageNumberToAccountIdMap => {
    return accounts.reduce((acc, account) => {
      acc[account.brokerageAccountNumber] = account.accountId;
      return acc;
    }, {});
  }
);
