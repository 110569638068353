"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PositionDetailsPositionTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var PositionDetailsPositionTypeEnum;
(function (PositionDetailsPositionTypeEnum) {
  PositionDetailsPositionTypeEnum["CASH_ON_DELIVERY_0"] = "CASH_ON_DELIVERY_0";
  PositionDetailsPositionTypeEnum["CASH_1"] = "CASH_1";
  PositionDetailsPositionTypeEnum["MARGIN_2"] = "MARGIN_2";
  PositionDetailsPositionTypeEnum["SHORT_3"] = "SHORT_3";
  PositionDetailsPositionTypeEnum["WHEN_ISSUED_4"] = "WHEN_ISSUED_4";
  PositionDetailsPositionTypeEnum["LEGACY_5"] = "LEGACY_5";
  PositionDetailsPositionTypeEnum["LEGACY_6"] = "LEGACY_6";
  PositionDetailsPositionTypeEnum["LEGACY_7"] = "LEGACY_7";
  PositionDetailsPositionTypeEnum["PRECIOUS_METALS_8"] = "PRECIOUS_METALS_8";
  PositionDetailsPositionTypeEnum["TEFRA_9"] = "TEFRA_9";
})(PositionDetailsPositionTypeEnum = exports.PositionDetailsPositionTypeEnum || (exports.PositionDetailsPositionTypeEnum = {}));
