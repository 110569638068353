import { formatNumber } from '@angular/common';
import { OrderEnums, TriggeredRuleEnums } from '@app/etfs-equities/enums';
import { QueryParamsForTransactionType } from '@app/etfs-equities/enums/order.enums';
import {
  CostBasis,
  OpenOrder,
  OpenOrderExtended,
  Order,
  TradeTicketForm,
  TradeTicketFormFields,
} from '@app/etfs-equities/models';
import { OrderCancel } from '@app/etfs-equities/models/order-cancel';
import {
  CardCapComponentBackgroundColors,
  TweOpenOrdersOrderStatusTypesEnum,
  TweOpenOrderStatusDisplayText,
} from '@etfs-equities/enums/open-orders/open-orders.enum';
import { capitalize } from '@etfs-equities/utils';
import { VgaOrderStatusEnum } from '@vanguard/invest-api-client-typescript-axios';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-17';

export class OrderUtil {
  // Maybe we can talk about naming here. The form control names don't
  // match what the server expects, so this helper does some mapping.
  // Also not sure if we need all of these fields. They kind of
  // dirty up the trade ticket form model a bit.
  static makeFromTradeTicket(
    ticketForm: TradeTicketForm,
    selectedLots?: CostBasis.SelectedLot[],
    isEtf = false
  ): Order.Order {
    if (!ticketForm) {
      return undefined;
    }

    return {
      allOrNone: ticketForm.allOrNone,
      accountNumber: ticketForm.brokerageAccountNumber,
      accountId: ticketForm.accountId,
      cusip: ticketForm.cusip,
      orderDuration: ticketForm.duration,
      limitPrice: ticketForm.limitPrice,
      orderId: ticketForm.orderId,
      orderType: ticketForm.orderType,
      shares: +ticketForm.amount,
      amountType: ticketForm.amountType,
      stopPrice: ticketForm.stopPrice,
      ticker: ticketForm.symbol ? ticketForm.symbol.toUpperCase() : null,
      costBasisMethod: ticketForm.costBasisMethod,
      transactionType: ticketForm.action,
      validation: ticketForm.validation,
      validated: ticketForm.validated,
      submitted: ticketForm.submitted,
      costBasisLots: ticketForm.costBasisMethod === CostBasisMethod.SPEC_ID ? selectedLots : null,
      securityAccountType: ticketForm.securityAccountType || null,
      isMarginAccount: ticketForm.isMarginAccount === undefined ? null : ticketForm.isMarginAccount,
      isCostBasisEligible: ticketForm.isCostBasisEligible === undefined ? null : ticketForm.isCostBasisEligible,
      isEtf,
    };
  }

  static makeChangeOrderForm(data: Order.Order): TradeTicketForm | undefined {
    if (!data) {
      return undefined;
    }

    const amount = data.vgaOrderStatus === VgaOrderStatusEnum.PARTIAL_EXECUTION ? data.remainingQuantity : data.shares;
    return {
      brokerageAccountNumber: data.brokerageAccountNumber,
      allOrNone: data.allOrNone,
      action: data.transactionType,
      duration: data.orderDuration,
      amountType: data.amountType,
      limitPrice: data.limitPrice ? String(data.limitPrice) : null,
      orderId: data.orderId,
      orderType: data.orderType,
      amount: amount ? String(amount) : null,
      stopPrice: data.stopPrice ? String(data.stopPrice) : null,
      symbol: data.ticker,
      costBasisMethod: data.costBasisMethod,
      securityAccountType: data.securityAccountType || null,
    } as TradeTicketFormFields;
  }

  static getUnacceptedTriggeredRules(order: Order.Order): Order.TriggeredRule[] {
    const triggeredRules = order.triggeredRules || [];
    const acceptedRules = order.acceptedWarningRules || [];

    return triggeredRules.filter((tr) => acceptedRules.findIndex((ar) => ar.ruleId === tr.ruleId) === -1);
  }

  static keyTriggeredRulesByType(order: Order.Order): Order.TriggeredRulesDictionary {
    const alerts =
      order.triggeredRules?.filter((rule) => rule.ruleAction === TriggeredRuleEnums.RuleActions.WARNING) || [];
    const reviewReleases =
      order.triggeredRules?.filter((rule) => rule.ruleAction === TriggeredRuleEnums.RuleActions.REVIEW_RELEASE) || [];
    const errors =
      order.triggeredRules?.filter((rule) => rule.ruleAction === TriggeredRuleEnums.RuleActions.ERROR) || [];
    return { alerts, errors, reviewReleases, count: order.triggeredRules?.length || 0 };
  }

  static validatedNotSubmitted(order: Order.Order) {
    return !!order && order.validated && !order.submitted;
  }

  // An order is ready for preview when it has been validated
  // and all triggered rules have been accepted by the client.
  static readyForPreview(order: Order.Order) {
    return OrderUtil.validatedNotSubmitted(order) && !OrderUtil.getUnacceptedTriggeredRules(order).length;
  }

  // Indicate whether or not the given order has exceeded the funds
  // available to trade based on the order's triggered rules.
  static orderHasExceededFundsAvailable(order: Order.Order) {
    if (!order || !order.triggeredRules) {
      return false;
    }

    const ruleIds = ['20145', '21145'];

    const triggeredRules = order.triggeredRules.filter((rule) => ruleIds.indexOf(rule.ruleId) !== -1);

    return triggeredRules.length > 0;
  }

  static formatSharesDisplay(shares: number): string | null {
    if (!shares) {
      return null;
    }
    return formatNumber(shares, 'en-US', shares % 1 === 0 ? '1.0-0' : '1.4-4');
  }

  static getOrderPathFromTransactionType(action: OrderEnums.TransactionTypes): OrderEnums.SubmitOrderPath {
    switch (action) {
      case OrderEnums.TransactionTypes.BUY:
      case OrderEnums.TransactionTypes.BUY_TO_COVER:
        return OrderEnums.SubmitOrderPath.BUY;
      case OrderEnums.TransactionTypes.SELL:
      case OrderEnums.TransactionTypes.SELL_SHORT:
        return OrderEnums.SubmitOrderPath.SELL;
      default:
        return null;
    }
  }

  static mapTransactionTypeFromUrlParams(param: string) {
    switch (param?.toLowerCase()) {
      case QueryParamsForTransactionType.BUY:
        return OrderEnums.TransactionTypes.BUY;
      case QueryParamsForTransactionType.SELL:
        return OrderEnums.TransactionTypes.SELL;
      case QueryParamsForTransactionType.BUY_TO_COVER:
        return OrderEnums.TransactionTypes.BUY_TO_COVER;
      case QueryParamsForTransactionType.SELL_SHORT:
        return OrderEnums.TransactionTypes.SELL_SHORT;
      default:
        return null;
    }
  }

  static orderStatusDisplayText = ({ statusDetailText, orderStatusCode }: OpenOrder): TweOpenOrderStatusDisplayText => {
    if (
      orderStatusCode === TweOpenOrdersOrderStatusTypesEnum.ENTERED ||
      statusDetailText === TweOpenOrderStatusDisplayText.ENTERED
    ) {
      return TweOpenOrderStatusDisplayText.PENDING;
    }
    return (
      (capitalize(statusDetailText?.toLowerCase()) as TweOpenOrderStatusDisplayText) ||
      TweOpenOrderStatusDisplayText.IN_PROGRESS
    );
  };

  static orderColor = (order: OpenOrderExtended): CardCapComponentBackgroundColors | null => {
    switch (String(order.orderStatusDisplayText)) {
      case TweOpenOrderStatusDisplayText.PARTIAL_EXECUTION:
        return OrderUtil.isCompletedPartial(order)
          ? CardCapComponentBackgroundColors.INK
          : CardCapComponentBackgroundColors.YELLOW;
      case TweOpenOrderStatusDisplayText.OPEN:
      case TweOpenOrderStatusDisplayText.ENTERED:
      case TweOpenOrderStatusDisplayText.PENDING:
      case TweOpenOrderStatusDisplayText.PENDING_CHANGE:
      case TweOpenOrderStatusDisplayText.PENDING_CANCEL:
      case TweOpenOrderStatusDisplayText.IN_PROGRESS:
        return CardCapComponentBackgroundColors.YELLOW;
      case TweOpenOrderStatusDisplayText.COMPLETE:
      case TweOpenOrderStatusDisplayText.EXECUTED:
        return CardCapComponentBackgroundColors.TURQUOISE;
      case TweOpenOrderStatusDisplayText.CANCELED:
      case TweOpenOrderStatusDisplayText.PARTIAL_CANCEL:
      case TweOpenOrderStatusDisplayText.EXPIRED:
      case TweOpenOrderStatusDisplayText.UNKNOWN:
        return CardCapComponentBackgroundColors.INK;
      case TweOpenOrderStatusDisplayText.REJECTED:
      case TweOpenOrderStatusDisplayText.DROPPED:
        return CardCapComponentBackgroundColors.RED;
      default:
        return null;
    }
  };

  static isStatusChangeOrCancelEligible(status: string) {
    switch (status) {
      case TweOpenOrdersOrderStatusTypesEnum.OPEN:
      case TweOpenOrdersOrderStatusTypesEnum.ENTERED:
      case TweOpenOrdersOrderStatusTypesEnum.IN_PROGRESS:
      case TweOpenOrdersOrderStatusTypesEnum.PENDING:
      case TweOpenOrdersOrderStatusTypesEnum.PARTIAL_EXECUTION:
        return true;
      default:
        return false;
    }
  }

  static isCompletedPartial = ({ statusDetail, remainingQuantity, orderStatusCode }: OpenOrder) => {
    return (
      statusDetail === TweOpenOrdersOrderStatusTypesEnum.PARTIAL_EXECUTION &&
      remainingQuantity === '0' &&
      orderStatusCode === TweOpenOrdersOrderStatusTypesEnum.COMPLETE
    );
  };

  static isPaperTradeOrTTSOrder = (order: OpenOrder | Order.Order | OrderCancel) => {
    return order?.postingCode === '1' || order?.orderSource === 'TTS';
  };

  static mask = (id: string): string => {
    if (!id) return '';

    return `****${String(id).slice(-4)}`;
  };

  static getOrderStatusDisplayText = (status: VgaOrderStatusEnum): string => {
    if (status === VgaOrderStatusEnum.CANCELLED) {
      return TweOpenOrderStatusDisplayText.CANCELED;
    }
    return TweOpenOrderStatusDisplayText[status] || 'Unknown Status';
  };
}
