"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountPositionStatusCodeEnum = exports.AccountPositionPositionTypeEnum = exports.AccountPositionSecurityTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var AccountPositionSecurityTypeEnum;
(function (AccountPositionSecurityTypeEnum) {
  AccountPositionSecurityTypeEnum["MONEY_MARKET_FUND"] = "MONEY_MARKET_FUND";
  AccountPositionSecurityTypeEnum["COMMON_STOCK"] = "COMMON_STOCK";
  AccountPositionSecurityTypeEnum["PREFERRED_STOCK"] = "PREFERRED_STOCK";
  AccountPositionSecurityTypeEnum["CONVERTIBLE_PREFERRED_STOCK"] = "CONVERTIBLE_PREFERRED_STOCK";
  AccountPositionSecurityTypeEnum["RIGHTS"] = "RIGHTS";
  AccountPositionSecurityTypeEnum["WARRANT"] = "WARRANT";
  AccountPositionSecurityTypeEnum["UNITS"] = "UNITS";
  AccountPositionSecurityTypeEnum["MUTUAL_FUND"] = "MUTUAL_FUND";
  AccountPositionSecurityTypeEnum["LIMITED_PARTNERSHIP"] = "LIMITED_PARTNERSHIP";
  AccountPositionSecurityTypeEnum["CALL_OPTION"] = "CALL_OPTION";
  AccountPositionSecurityTypeEnum["PUT_OPTION"] = "PUT_OPTION";
  AccountPositionSecurityTypeEnum["BANK_CALL_OPTION"] = "BANK_CALL_OPTION";
  AccountPositionSecurityTypeEnum["ESCROW"] = "ESCROW";
  AccountPositionSecurityTypeEnum["CORPORATE_BOND"] = "CORPORATE_BOND";
  AccountPositionSecurityTypeEnum["CONVERTIBLE_BOND"] = "CONVERTIBLE_BOND";
  AccountPositionSecurityTypeEnum["MUNICIPAL_BOND"] = "MUNICIPAL_BOND";
  AccountPositionSecurityTypeEnum["SHORT_TERM_PAPER"] = "SHORT_TERM_PAPER";
  AccountPositionSecurityTypeEnum["BOND_UNIT"] = "BOND_UNIT";
  AccountPositionSecurityTypeEnum["MUNI_ASSESSMENT_DIST"] = "MUNI_ASSESSMENT_DIST";
  AccountPositionSecurityTypeEnum["US_TREASURY_BILL"] = "US_TREASURY_BILL";
  AccountPositionSecurityTypeEnum["US_TREASURY_NOTE"] = "US_TREASURY_NOTE";
  AccountPositionSecurityTypeEnum["US_TREASURY_BOND"] = "US_TREASURY_BOND";
  AccountPositionSecurityTypeEnum["OTHER_GOVERNMENT"] = "OTHER_GOVERNMENT";
  AccountPositionSecurityTypeEnum["US_TREASURY_ZERO_COUPON"] = "US_TREASURY_ZERO_COUPON";
  AccountPositionSecurityTypeEnum["GOVERNMENT_MORTGAGE"] = "GOVERNMENT_MORTGAGE";
  AccountPositionSecurityTypeEnum["COLLATERALIZED_MORTGAGE_OBLIGATION"] = "COLLATERALIZED_MORTGAGE_OBLIGATION";
  AccountPositionSecurityTypeEnum["WHEN_AS_AND_IF_ISSUED_EQUITY"] = "WHEN_AS_AND_IF_ISSUED_EQUITY";
  AccountPositionSecurityTypeEnum["WHEN_AS_AND_IF_ISSUED_BOND"] = "WHEN_AS_AND_IF_ISSUED_BOND";
  AccountPositionSecurityTypeEnum["BANKERS_ACCEPTANCE"] = "BANKERS_ACCEPTANCE";
  AccountPositionSecurityTypeEnum["CERTIFICATES_OF_DEPOSIT"] = "CERTIFICATES_OF_DEPOSIT";
  AccountPositionSecurityTypeEnum["COMMERCIAL_PAPER"] = "COMMERCIAL_PAPER";
  AccountPositionSecurityTypeEnum["FIXED_INCOME_UNITS"] = "FIXED_INCOME_UNITS";
  AccountPositionSecurityTypeEnum["ETF"] = "ETF";
  AccountPositionSecurityTypeEnum["VANGUARD_ETF"] = "VANGUARD_ETF";
  AccountPositionSecurityTypeEnum["MISCELLANEOUS_FIXED_INCOME"] = "MISCELLANEOUS_FIXED_INCOME";
  AccountPositionSecurityTypeEnum["MISCELLANEOUS_STOCK"] = "MISCELLANEOUS_STOCK";
  AccountPositionSecurityTypeEnum["TAX_SHELTER"] = "TAX_SHELTER";
  AccountPositionSecurityTypeEnum["UNKNOWN"] = "UNKNOWN";
})(AccountPositionSecurityTypeEnum = exports.AccountPositionSecurityTypeEnum || (exports.AccountPositionSecurityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionPositionTypeEnum;
(function (AccountPositionPositionTypeEnum) {
  AccountPositionPositionTypeEnum["UNKNOWN"] = "UNKNOWN";
  AccountPositionPositionTypeEnum["CASH_ON_DELIVERY"] = "CASH_ON_DELIVERY";
  AccountPositionPositionTypeEnum["CASH"] = "CASH";
  AccountPositionPositionTypeEnum["MARGIN"] = "MARGIN";
  AccountPositionPositionTypeEnum["SHORT"] = "SHORT";
  AccountPositionPositionTypeEnum["WHEN_ISSUED_CASH_ACCOUNT"] = "WHEN_ISSUED_CASH_ACCOUNT";
  AccountPositionPositionTypeEnum["FIRM_SPECIFIED"] = "FIRM_SPECIFIED";
  AccountPositionPositionTypeEnum["SHORT_MARGIN_ACCOUNT"] = "SHORT_MARGIN_ACCOUNT";
  AccountPositionPositionTypeEnum["FIRM_DEFINED_1"] = "FIRM_DEFINED_1";
  AccountPositionPositionTypeEnum["PRECIOUS_METALS"] = "PRECIOUS_METALS";
  AccountPositionPositionTypeEnum["FIRM_DEFINED_2"] = "FIRM_DEFINED_2";
})(AccountPositionPositionTypeEnum = exports.AccountPositionPositionTypeEnum || (exports.AccountPositionPositionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionStatusCodeEnum;
(function (AccountPositionStatusCodeEnum) {
  AccountPositionStatusCodeEnum["CLOSED_02"] = "CLOSED_02";
  AccountPositionStatusCodeEnum["ESCHEATED_05"] = "ESCHEATED_05";
  AccountPositionStatusCodeEnum["MARGINAL_03"] = "MARGINAL_03";
  AccountPositionStatusCodeEnum["OPEN_01"] = "OPEN_01";
  AccountPositionStatusCodeEnum["RPO_04"] = "RPO_04";
  AccountPositionStatusCodeEnum["UNKNOWN"] = "UNKNOWN";
})(AccountPositionStatusCodeEnum = exports.AccountPositionStatusCodeEnum || (exports.AccountPositionStatusCodeEnum = {}));
