"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.41.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AgreementDetailAgreementTypeEnum = exports.AgreementDetailAgreementStatusEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var AgreementDetailAgreementStatusEnum;
(function (AgreementDetailAgreementStatusEnum) {
  AgreementDetailAgreementStatusEnum["AGREED"] = "AGREED";
  AgreementDetailAgreementStatusEnum["NOT_AGREED"] = "NOT_AGREED";
  AgreementDetailAgreementStatusEnum["AWAITING_SIGNATURE_FORM"] = "AWAITING_SIGNATURE_FORM";
  AgreementDetailAgreementStatusEnum["NOTARY_FORM"] = "NOTARY_FORM";
  AgreementDetailAgreementStatusEnum["SIGNATURE_FORM"] = "SIGNATURE_FORM";
  AgreementDetailAgreementStatusEnum["EXCLUDED_FROM_CAMPAIGN"] = "EXCLUDED_FROM_CAMPAIGN";
})(AgreementDetailAgreementStatusEnum = exports.AgreementDetailAgreementStatusEnum || (exports.AgreementDetailAgreementStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AgreementDetailAgreementTypeEnum;
(function (AgreementDetailAgreementTypeEnum) {
  AgreementDetailAgreementTypeEnum["NEGATIVE_CAMPAIGN"] = "NEGATIVE_CAMPAIGN";
  AgreementDetailAgreementTypeEnum["NOTARY_FORM"] = "NOTARY_FORM";
  AgreementDetailAgreementTypeEnum["SIGNATURE_FORM"] = "SIGNATURE_FORM";
  AgreementDetailAgreementTypeEnum["EXCLUDED_FROM_CAMPAIGN"] = "EXCLUDED_FROM_CAMPAIGN";
  AgreementDetailAgreementTypeEnum["AMENDED_AGREEMENT"] = "AMENDED_AGREEMENT";
})(AgreementDetailAgreementTypeEnum = exports.AgreementDetailAgreementTypeEnum || (exports.AgreementDetailAgreementTypeEnum = {}));
