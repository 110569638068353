"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.coalesceArray = exports.applyIfPresentElse = exports.applyIfPresent = exports.applyIfPropertyPresent = exports.coalescePropertyDefault = exports.coalesceProperty = exports.coalesce = exports.isNil = exports.isNotNil = void 0;
/**
 * Checks if the given value is not null and not undefined.
 *
 * @template T - The type of the value.
 * @param {T | null | undefined} value - The value to check.
 * @returns {boolean} - Returns `true` if the value is not null and not undefined, otherwise `false`.
 *
 * @example
 * // returns: true
 * console.log(isNotNil(5));
 *
 * @example
 * // returns: false
 * console.log(isNotNil(null));
 *
 * @example
 * // returns: false
 * console.log(isNotNil(undefined));
 */
function isNotNil(value) {
  return value !== null && value !== undefined;
}
exports.isNotNil = isNotNil;
/**
 * Checks if the given value is null or undefined.
 *
 * @template T - The type of the value.
 * @param {T | null | undefined} value - The value to check.
 * @returns {boolean} - Returns `true` if the value is null or undefined, otherwise `false`.
 *
 * @example
 * // returns: false
 * console.log(isNil(5));
 *
 * @example
 * // returns: true
 * console.log(isNil(null));
 *
 * @example
 * // returns: true
 * console.log(isNil(undefined));
 */
function isNil(value) {
  return !isNotNil(value);
}
exports.isNil = isNil;
/**
 * Returns the first non-null and non-undefined value from the given value and default value.
 *
 * @template T - The type of the value.
 * @param {T | null | undefined} value - The value to check.
 * @param {T} def - The default value to return if the value is null or undefined.
 * @returns {T} - Returns the value if it is not null and not undefined, otherwise returns the default value.
 *
 * @example
 * // returns: 5
 * console.log(coalesce(5, 10));
 *
 * @example
 * // returns: 10
 * console.log(coalesce(null, 10));
 *
 * @example
 * // returns: 10
 * console.log(coalesce(undefined, 10));
 */
const coalesce = (value, def) => {
  return isNil(value) ? def : value;
};
exports.coalesce = coalesce;
/**
 * Returns the value of the specified property from the given object if the object is not null or undefined.
 * If the object is null or undefined, returns undefined.
 *
 * @template T - The type of the object.
 * @template P - The type of the property key.
 * @param {T | null | undefined} obj - The object to check.
 * @param {P} property - The property to return from the object.
 * @returns {T[P] | undefined} - Returns the property value if the object is not null and not undefined, otherwise returns undefined.
 *
 * @example
 * // returns: "John"
 * console.log(coalesceProperty({ name: "John", age: 30 }, "name"));
 *
 * @example
 * // returns: undefined
 * console.log(coalesceProperty(null, "name"));
 *
 * @example
 * // returns: undefined
 * console.log(coalesceProperty(undefined, "name"));
 */
const coalesceProperty = (obj, property) => {
  return isNil(obj) ? undefined : obj[property];
};
exports.coalesceProperty = coalesceProperty;
/**
 * Returns the value of the specified property from the given object if the object and the property are not null or undefined.
 * If the object or the property is null or undefined, returns the default value.
 *
 * @template T - The type of the object.
 * @template P - The type of the property key.
 * @param {T | null | undefined} obj - The object to check.
 * @param {P} property - The property to return from the object.
 * @param {T[P]} def - The default value to return if the object or the property is null or undefined.
 * @returns {T[P]} - Returns the property value if the object and the property are not null and not undefined, otherwise returns the default value.
 *
 * @example
 * // returns: "John"
 * console.log(coalescePropertyDefault({ name: "John", age: 30 }, "name", "Default Name"));
 *
 * @example
 * // returns: "Default Name"
 * console.log(coalescePropertyDefault(null, "name", "Default Name"));
 *
 * @example
 * // returns: "Default Name"
 * console.log(coalescePropertyDefault({ name: null, age: 30 }, "name", "Default Name"));
 */
const coalescePropertyDefault = (obj, property, def) => {
  return isNil(obj) ? def : !isNil(obj[property]) ? obj[property] : def;
};
exports.coalescePropertyDefault = coalescePropertyDefault;
/**
 * Applies the given function to the value of the specified property from the given object if the object and the property are not null or undefined.
 * If the object or the property is null or undefined, returns undefined.
 *
 * @template T - The type of the object.
 * @template P - The type of the property key.
 * @template R - The type of the return value.
 * @param {T | undefined | null} obj - The object to check.
 * @param {P} property - The property to apply the function to.
 * @param {(v: any) => R} func - The function to apply to the property value.
 * @returns {R | undefined} - Returns the result of the function if the object and the property are not null and not undefined, otherwise returns undefined.
 *
 * @example
 * // returns: 5
 * console.log(applyIfPropertyPresent({ name: "John", age: 30 }, "age", v => v + 5));
 *
 * @example
 * // returns: undefined
 * console.log(applyIfPropertyPresent(null, "age", v => v + 5));
 *
 * @example
 * // returns: undefined
 * console.log(applyIfPropertyPresent({ name: "John", age: null }, "age", v => v + 5));
 */
const applyIfPropertyPresent = (obj, property, func) => {
  if (isNil(obj)) {
    return undefined;
  }
  const p = obj[property];
  if (isNil(p)) {
    return undefined;
  }
  return func(p);
};
exports.applyIfPropertyPresent = applyIfPropertyPresent;
/**
 * Applies the given function to the given object if the object is not null or undefined.
 * If the object is null or undefined, returns undefined.
 *
 * @template T - The type of the object.
 * @template R - The type of the return value.
 * @param {T | undefined | null} obj - The object to apply the function to.
 * @param {(v: T) => R} func - The function to apply to the object.
 * @returns {R | undefined} - Returns the result of the function if the object is not null and not undefined, otherwise returns undefined.
 *
 * @example
 * // returns: 35
 * console.log(applyIfPresent({ name: "John", age: 30 }, obj => obj.age + 5));
 *
 * @example
 * // returns: undefined
 * console.log(applyIfPresent(null, obj => obj.age + 5));
 */
const applyIfPresent = (obj, func) => {
  return isNil(obj) ? undefined : func(obj);
};
exports.applyIfPresent = applyIfPresent;
/**
 * Applies the given function to the given object if the object is not null or undefined.
 * If the object is null or undefined, applies the else function.
 *
 * @template T - The type of the object.
 * @template R - The type of the return value.
 * @param {T | undefined | null} obj - The object to apply the function to.
 * @param {(v: T) => R} func - The function to apply to the object.
 * @param {() => R} elseFunc - The function to apply if the object is null or undefined.
 * @returns {R} - Returns the result of the function if the object is not null and not undefined, otherwise returns the result of the else function.
 *
 * @example
 * // returns: 35
 * console.log(applyIfPresentElse({ name: "John", age: 30 }, obj => obj.age + 5, () => 0));
 *
 * @example
 * // returns: 0
 * console.log(applyIfPresentElse(null, obj => obj.age + 5, () => 0));
 */
const applyIfPresentElse = (obj, func, elseFunc) => {
  return isNil(obj) ? elseFunc() : func(obj);
};
exports.applyIfPresentElse = applyIfPresentElse;
/**
 * Returns the given array if it is not null or undefined.
 * If the array is null or undefined, returns an empty array.
 *
 * @template T - The type of the array elements.
 * @param {T[] | undefined | null} value - The array to check.
 * @returns {T[]} - Returns the array if it is not null and not undefined, otherwise returns an empty array.
 *
 * @example
 * // returns: [1, 2, 3]
 * console.log(coalesceArray([1, 2, 3]));
 *
 * @example
 * // returns: []
 * console.log(coalesceArray(null));
 *
 * @example
 * // returns: []
 * console.log(coalesceArray(undefined));
 */
const coalesceArray = value => {
  return (0, exports.coalesce)(value, []);
};
exports.coalesceArray = coalesceArray;
