import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { HeadersUtil } from '@app/etfs-equities/utils/header/header.util';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { environment } from '@env/environment';
import {
  AccountApi,
  axiosFactory,
  Configuration,
  ConfigurationParameters,
  OrdersApi,
  ProductsApi,
} from '@vanguard/invest-api-client-typescript-axios';
import { AXIOS_ERROR_INTERCEPTOR, AxiosErrorInterceptor } from '@vanguard/trade-error-handler-lib';
import { Axios } from 'axios';

import { CONSTANTS } from '../constants';

export const AXIOS_INJECTION_TOKEN = new InjectionToken<Axios>('AXIOS');
export const BASEPATH_INJECTION_TOKEN = new InjectionToken<string>('BASEPATH');
export const CONFIG_PARAMS_INJECTION_TOKEN = new InjectionToken<ConfigurationParameters>('CONFIG_PARAMS');

@NgModule({
  declarations: [],
  imports: [HttpClientModule, HttpClientXsrfModule],
  providers: [
    {
      provide: AXIOS_INJECTION_TOKEN,
      useFactory: (basePath: string, axiosErrorInterceptor: AxiosErrorInterceptor, loadingService: LoadingService) => {
        const axios = axiosFactory({
          timeoutDefaultMs: CONSTANTS.TIMEOUT_DEFAULT,
          retryDefault: CONSTANTS.RETRY_DEFAULT,
          basePath,
          allowAnnoyances: CONSTANTS.ALLOW_ANNOYANCES,
        });
        axios.interceptors.response.use(undefined, axiosErrorInterceptor);
        axios.interceptors.request.use((config: any) => {
          const loadingContext = HeadersUtil.parseLoadingContext(config.headers[CONSTANTS.LOADING_CONTEXT_HEADER_NAME]);

          if (loadingContext?.showLoading) {
            loadingService.loadingOn(loadingContext.status);
          }

          return config;
        });

        axios.interceptors.response.use(
          (response) => {
            const loadingContext = HeadersUtil.parseLoadingContext(
              response.config.headers[CONSTANTS.LOADING_CONTEXT_HEADER_NAME]
            );

            if (loadingContext?.showLoading) {
              loadingService.loadingOff();
            }
            return response;
          },
          (error) => {
            // this is an axios error
            if (error.config) {
              const loadingContext = HeadersUtil.parseLoadingContext(
                error.config.headers[CONSTANTS.LOADING_CONTEXT_HEADER_NAME]
              );

              if (loadingContext?.showLoading) {
                loadingService.loadingOff();
              }
            } else {
              // if config is not present means that the error is not an axios error
              // and could be network, timeout or request was cancelled in which case we
              // should turn it off as well in case it was on
              loadingService.loadingOff();
            }

            return Promise.reject(error);
          }
        );

        return axios;
      },
      deps: [BASEPATH_INJECTION_TOKEN, AXIOS_ERROR_INTERCEPTOR, LoadingService],
    },
    {
      provide: BASEPATH_INJECTION_TOKEN,
      useValue: environment.vgaInvestApiUrl,
    },
    {
      provide: CONFIG_PARAMS_INJECTION_TOKEN,
      useValue: {
        apiKey: CONSTANTS.APP_PREFIX,
        baseOptions: {
          withCredentials: true,
        },
      } as ConfigurationParameters,
    },
    {
      provide: Configuration,
      useFactory: (configParams) => new Configuration(configParams),
      deps: [CONFIG_PARAMS_INJECTION_TOKEN],
    },
    {
      provide: AccountApi,
      useFactory: (configuration, basepath, axios) => new AccountApi(configuration, basepath, axios),
      deps: [Configuration, BASEPATH_INJECTION_TOKEN, AXIOS_INJECTION_TOKEN],
    },
    {
      provide: OrdersApi,
      useFactory: (configuration, basepath, axios) => new OrdersApi(configuration, basepath, axios),
      deps: [Configuration, BASEPATH_INJECTION_TOKEN, AXIOS_INJECTION_TOKEN],
    },
    {
      provide: ProductsApi,
      useFactory: (configuration, basepath, axios) => new ProductsApi(configuration, basepath, axios),
      deps: [Configuration, BASEPATH_INJECTION_TOKEN, AXIOS_INJECTION_TOKEN],
    },
  ],
})
export class ServicesModule {}
