import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'tweDate',
})
export class DatePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value) {
      return null;
    }

    // Parse the input value as UTC
    const parsed = moment.tz(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'UTC');

    if (!parsed.isValid()) {
      return value;
    }

    // Convert the parsed time to EST
    const estTime = parsed.tz('America/New_York');

    if (args.indexOf('detailedDateTime') !== -1) {
      return estTime.format('h:mm A, [ET] MMMM D, YYYY').replace('AM', 'a.m.').replace('PM', 'p.m.');
    }

    return estTime.toDate();
  }
}
